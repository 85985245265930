import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi';
import { Card, Divider, Input, Select, DatePicker, Button } from 'antd'
import styles from './index.less'
import moment from 'moment'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { isLegitimateDate, getFormatDate } from 'utils/utils'

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD';
const dateOption = ['openingTime']

const inputAttrs = ['userNickName', 'courseName']

const UserClassAnalysis = ({ dispatch, userClassAnalysis }) => {
    const intl = useIntl();

    useEffect(() => {
        dispatch({ type: 'userClassAnalysis/initPage' })
    }, [])

    const { userClassAnalysisTablePagination, searchOption, courseAnalysisData } = userClassAnalysis

    const { addedCourseMonth, totalCourseCount, totalCancelCount, userCourse } = courseAnalysisData || {}

    const userClassAnalysisTableListSupport = {
        creationTime: {
            showText: intl.formatMessage({ id: 'openingTime' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY HH:mm',
            },
        },
        title: {
            showText: intl.formatMessage({ id: 'courseName' }),
            showType: 'Text',
        },
        picture: {
            showText: intl.formatMessage({ id: 'photo' }),
            showType: 'Image',
        },
        userName: {
            showText: intl.formatMessage({ id: 'name' }),
            showType: 'Text',
        },
        length: {
            showText: intl.formatMessage({ id: 'classHour' }),
            showType: 'Text',
        }
    }

    const onChange = async (type, value) => {
        const cloneSearchOption = JSON.parse(JSON.stringify(searchOption))
        if (dateOption.includes(type)) {
            cloneSearchOption[type].startTime = value && value[0] ? getFormatDate(value[0], true) : ''
            cloneSearchOption[type].endTime = value && value[1] ? getFormatDate(value[1], false) : ''
        } else {
            cloneSearchOption[type] = value
        }
        await dispatch({
            type: 'userClassAnalysis/updateState',
            payload: {
                searchOption: cloneSearchOption,
                userClassAnalysisTablePagination: { ...userClassAnalysisTablePagination, ...{ current: 1 } }
            }
        })

        if (!inputAttrs.includes(type)) onSearch();
    }

    const onSearch = () => {
        dispatch({ type: 'userClassAnalysis/onSearch' })
    }

    const userClassAnalysisTableProps = {
        listSupport: userClassAnalysisTableListSupport,
        pagination: userClassAnalysisTablePagination,
        listData: userCourse ? userCourse.items.map(item => {
            return {
                ...item,
                length: item.course.length,
                title: item.course.title
            }
        }) : [],
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { userClassAnalysisTablePagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'userClassAnalysis/changeTable', payload: data })
        },
    }

    const { userNickName, courseName, openingTime } = searchOption

    return (
        <Card title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'courseAnalysis' })}</b>} bordered={false}>
            <div className={styles.userClassAnalysisTop}>
                <span>{intl.formatMessage({ id: 'monthlyNewClassNum' })}：{addedCourseMonth || 0}</span>
                <Divider type='vertical' />
                <span>{intl.formatMessage({ id: 'totalClass' })}：{totalCourseCount || 0}</span>
                <Divider type='vertical' />
                <span>{intl.formatMessage({ id: 'totalSuspendedSessions' })}：{totalCancelCount || 0}</span>
            </div>
            <div className={styles.userClassAnalysisSearch}>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>{intl.formatMessage({ id: 'keyword' })}</span>
                    <Search
                        placeholder={intl.formatMessage({ id: 'name' })}
                        value={userNickName}
                        onChange={e => onChange('userNickName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, width: 220 }}
                    />
                </div>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>{intl.formatMessage({ id: 'courseName' })}</span>
                    <Search
                        placeholder={intl.formatMessage({ id: 'courseName' })}
                        value={courseName}
                        onChange={e => onChange('courseName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, width: 220 }}
                    />
                </div>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>{intl.formatMessage({ id: 'openingTime' })}</span>
                    <RangePicker
                        onChange={date => onChange('openingTime', date)}
                        placeholder={[intl.formatMessage({ id: 'startDate' }), intl.formatMessage({ id: 'endDate' })]}
                        disabledDate={(current) => {
                            return current && current > moment()
                        }}
                        value={[
                            openingTime.startTime ? moment(openingTime.startTime, dateFormat) : null,
                            openingTime.endTime ? moment(openingTime.endTime, dateFormat) : null
                        ]}
                    />
                </div>
                <Button className={styles.clear} onClick={() => { dispatch({ type: 'userClassAnalysis/onClear' }) }}>{intl.formatMessage({ id: 'clear' })}</Button>
            </div>
            <Button className={styles.toExcelButton} onClick={() => { dispatch({ type: 'userClassAnalysis/downloadExcel' }) }}>{intl.formatMessage({ id: 'exportList' })}</Button>
            <MagicTable {...userClassAnalysisTableProps} />
        </Card>
    )
}

export default connect(({ userClassAnalysis }) => ({ userClassAnalysis }))(UserClassAnalysis)