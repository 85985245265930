import React, { useState, useEffect } from 'react';
import { Button, Space, Spin, Drawer, Modal, message } from 'antd';
import { connect, useIntl } from 'umi';

import {
  PutQuestionnaire,
  GetQuestionnaire,
  GetStrengthBasedInterview,
  PostStrengthBasedInterview,
} from 'services/trainingSystem';
import { GetBaseLineSurvey } from 'services/trialPlan';

import Question from 'components/Question';
import AssessmentQrCode from 'components/AssessmentQrCode';

import styles from '../detail.less';

const StepFour = ({
  activePlan,
  prePage,
  nextPage,
  subjectDetail,
  subjectAssessment,
  dispatch,
  currentUser,
}) => {
  const intl = useIntl();

  const { assessmentStatus = [] } = activePlan || {};

  const { assessmentQrData, assessmentQrUrlModalVisible, assessmentQrUrl } =
    subjectAssessment;

  const { subjectInfo } = subjectDetail;
  const { id: subjectId } = subjectInfo || {};

  const [loading, setLoading] = useState({
    pageLoading: false, // 页面加载loading
    drawerLoading: false, // 抽屉加载loading
    surveyLoading: false,
  });
  const [visitData, setVisitData] = useState({});
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [questionnaireData, setQuestionnaireData] = useState({});

  const { baseLineData = {}, strengthBasedData = {} } = visitData || {};
  const { assessment = {}, userAssessment = {} } = baseLineData || {};
  const { finishTime: strengthBasedFinishTime, id: strengthBasedId } =
    strengthBasedData || {};

  const { id: assessmentId } = assessment || {};
  const { id: userAssessmentId, finishTime: baseLineFinishTime } =
    userAssessment || {};

  const hasCreatedSurvey = Object.keys(userAssessment || {}).length > 0;

  const hasBaseLineFinished =
    baseLineFinishTime != null || baseLineFinishTime != undefined;
  const hasStrengthBasedFinished =
    strengthBasedFinishTime != null || strengthBasedFinishTime != undefined;

  const initData = async () => {
    setLoading({
      ...loading,
      pageLoading: true,
    });

    const params = {
      userId: subjectId,
    };

    const resList = await Promise.all([
      GetBaseLineSurvey(params),
      GetStrengthBasedInterview(params),
    ]);

    if (resList.every((item) => item.response.ok)) {
      const [baseLineRes, strengthBasedRes] = resList;

      visitData.baseLineData = baseLineRes?.data || {};
      visitData.strengthBasedData = strengthBasedRes?.data || {};

      setVisitData(visitData);
    }

    setLoading({
      ...loading,
      pageLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const addQuestion = async () => {
    setLoading({
      ...loading,
      pageLoading: true,
    });
    const params = {
      userId: subjectId,
    };
    const res = await PostStrengthBasedInterview(params);

    if (res.response.ok) {
      setQuestionnaireData(res.data);
      setDrawerVisible(true);
    }

    setLoading({
      ...loading,
      pageLoading: false,
    });
  };

  const { questionnaireAndAnswer, id: userQuestionnaireId } =
    questionnaireData || {};

  const { questionnaire, answer } = questionnaireAndAnswer || {};
  const { jsonString } = questionnaire || {};

  const submitQuestion = async (payload) => {
    const { answerContent, isComplete, finishedPage } = payload || {};
    const params = {
      userQuestionnaireId,
      answerContent,
      isComplete,
      finishedPage,
    };
    const submitQuestionRes = await PutQuestionnaire(params);

    if (submitQuestionRes.response?.ok) {
      if (isComplete) {
        message.success(intl.formatMessage({ id: 'success' }));
        initData();
        setSubmitData({});
        setDrawerVisible(false);
      }
    }
  };

  const onValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    setSubmitData({ answerContent, isComplete, finishedPage });
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage });
    }
  };

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          submitQuestion({ answerContent, isComplete, finishedPage });
        },
      });
    } else {
      submitQuestion({ answerContent, isComplete, finishedPage });
    }
  };

  const questionProps = {
    content: jsonString || '{}',
    answerContent: (submitData && submitData.answerContent) ?? '',
    answer,
    onComplete,
    onValueChange,
    time: 5,
  };

  const showDrawerList = () => {
    setDrawerVisible(true);
    getDataByIndex();
  };

  const getDataByIndex = async () => {
    setLoading({
      ...loading,
      surveyLoading: true,
    });

    const res = await GetQuestionnaire({ id: strengthBasedId });

    if (res.response.ok) {
      setQuestionnaireData(res.data);
    }

    setLoading({
      ...loading,
      surveyLoading: false,
    });
  };

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      initData();
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false,
        },
      });
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl },
      });
    },
  };

  const showQrCode = () => {
    let tempRecord = {
      userId: subjectId,
      assessmentId,
      id: userAssessmentId,
    };
    if (!hasCreatedSurvey) {
      delete tempRecord.id;
    }
    dispatch({
      type: 'subjectAssessment/getAssessmentQrCode',
      payload: {
        record: tempRecord,
        intl,
        from: 'Retest',
      },
    });
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.stepFour}>
        <div className={styles.stepFourTitle}>
          {intl.formatMessage({ id: 'Before starting the plan' })}【
          <a
            onClick={() =>
              window.open(`/system/archivesV2?subjectId=${subjectId}`)
            }
          >
            {intl.formatMessage({ id: 'Child Profile' })}
          </a>
          】{intl.formatMessage({ id: 'Completion of the following' })}
        </div>
        <Spin spinning={loading.pageLoading}>
          <div className={styles.stepFourText}>
            <Space>
              <div className={styles.stepFourTextTitle}>
                1. 【{intl.formatMessage({ id: 'Child Development History' })}】
              </div>
              {!hasBaseLineFinished ? (
                <a onClick={showQrCode}>
                  {intl.formatMessage({ id: 'evaluationQrCode' })}
                </a>
              ) : (
                <span>
                  {intl.formatMessage(
                    { id: 'optionView' },
                    {
                      option: (
                        <a
                          onClick={() =>
                            window.open(
                              `/system/archivesV2?subjectId=${subjectId}`,
                            )
                          }
                        >
                          【{intl.formatMessage({ id: 'Child Profile' })}】
                        </a>
                      ),
                    },
                  )}
                </span>
              )}
            </Space>
            <Space>
              <div className={styles.stepFourTextTitle}>
                2. 【{intl.formatMessage({ id: 'StrengthBased Interview' })}】
              </div>
              {!hasStrengthBasedFinished ? (
                <a onClick={() => addQuestion()}>
                  {intl.formatMessage({ id: 'fillOut' })}
                </a>
              ) : (
                <a onClick={() => showDrawerList()}>
                  {intl.formatMessage({ id: 'filled in' })}
                </a>
              )}
            </Space>
          </div>
        </Spin>
        <Space size={'large'}>
          {Boolean(assessmentStatus.length) && (
            <Button onClick={prePage}>
              {intl.formatMessage({ id: 'previous' })}
            </Button>
          )}
          <Button
            disabled={!hasStrengthBasedFinished || !hasBaseLineFinished}
            type="primary"
            onClick={nextPage}
          >
            {intl.formatMessage({ id: 'next' })}
          </Button>
        </Space>
      </div>
      <Drawer
        title={intl.formatMessage({
          id: 'StrengthBased Interview',
        })}
        open={drawerVisible}
        width={700}
        onClose={() => {
          onComplete(submitData);
          setDrawerVisible(false);
          initData();
        }}
      >
        <Spin spinning={loading.surveyLoading}>
          <Question {...questionProps} />
        </Spin>
      </Drawer>
      <AssessmentQrCode {...qrCodeProps} />
    </div>
  );
};

export default connect(({ subjectDetail, subjectAssessment, user }) => ({
  subjectDetail,
  subjectAssessment,
  currentUser: user.currentUser,
}))(StepFour);
