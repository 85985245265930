import React from 'react'
import { connect, useIntl } from 'umi'
import { Spin, Form, Row, Col, Input, Button } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import config from 'utils/config'
import { getToken, getTenantId } from 'cognitiveleap-core-us/utils/auth'
const { mobileUrl } = config

const SubjectAssessment = ({ adminSubjectAssessment, loading, dispatch }) => {
    const intl = useIntl();
    const { list, pagination } = adminSubjectAssessment

  const assessmentColumns = {
    creationTime: {
      showText:  intl.formatMessage({ id: 'creationTime'}),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    title: {
      showText:  intl.formatMessage({ id: 'assessmentName'}),
      showType: 'Text',
    },
    status: {
      showText:  intl.formatMessage({ id: 'status'}),
      showType: 'Text',
    },
  }

  const assessmentActions = [
    // {
    //   showText: '下载测评详情',
    //   itemRender(record) {
    //     const { status } = record
    //     return status === 'ReportedReady' ? (
    //       <span key="downloadPDF">
    //         <a
    //           onClick={() => {
    //             dispatch({ type: 'adminSubjectDetail/downloadAssessmentPDF', payload: record.id })
    //           }}
    //         >
    //           下载测评详情
    //         </a>
    //       </span>
    //     ) : null
    //   },
    // },
    {
      showText: '查看详情',
      itemRender(record) {
        const { status } = record
        return status === 'ReportedReady' ? (
          <span key="viewDetail">
            <a
              onClick={() => {
                const url = '/questionnaireView?id=' + record.id
                window.open(url, '_blank')
              }}
            >
               {intl.formatMessage({ id: 'details'})}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: '查看报告',
      itemRender(record) {
        const { status } = record
        return status === 'ReportedReady' ? (
          <span key="view">
            <a
              onClick={() => {
                const reportUrl = `${mobileUrl}/m/rocketReport?to=${record.id}&t=${getToken()}&h=${getTenantId()}`
                window.open(reportUrl, '_blank')
              }}
            >
               {intl.formatMessage({ id: 'viewReport'})}
            </a>
          </span>
        ) : null
      },
    },
  ]

  const listProps = {
    loading: loading.models.adminSubjectAssessment,
    listData: list.map(item => {
        const { assessment } = item
        return {
          ...item,
          title: assessment.title,
        }
      }),
    listSupport: assessmentColumns,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'adminSubjectAssessment/changeTable', payload: data })
    },
    Actions: assessmentActions,
  }

  return(
      <div>
    <Button> {intl.formatMessage({ id: 'Add New Assessment'})}</Button>
    <MagicTable {...listProps} /></div>
  )
}


export default connect(({ adminSubjectAssessment, loading }) => ({ adminSubjectAssessment, loading }))(SubjectAssessment)