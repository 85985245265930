import React from 'react'
import { connect, useIntl } from 'umi'
import { history } from 'umi';
import moment from 'moment'
import { Spin, Form, Row, Col, Input, Button, message } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { encryptAES } from 'utils/utils';

const SubjectVrat = ({ adminSubjectVrat, currentUser, loading, dispatch }) => {
    const {adminRoles, current } = currentUser
    const isAdmin = adminRoles.length > 0 && current === null
    const { list, pagination } = adminSubjectVrat
    const intl = useIntl()

    const showList = list.map(item => {
        const {
            case: { testDate, id: caseId, testType, overView },
            testingCenter: { displayName: centerDisplayName },
            // testLocation: { name: locationName },
        } = item
        return {
            testDate,
            centerName: centerDisplayName ? centerDisplayName : '',
            // locationName,
            caseId,
            testType,
            attentionIndex: overView ? (overView.attentionPerformanceIndex * 100).toFixed(0) : '',
            motionIndex: overView ? (overView.motionPerformanceIndex * 100).toFixed(0) : '',
            ...item,
        }
    })

    const vratColumns = {
        testDate: {
            showText: intl.formatMessage({ id: 'testDate' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY',
            },
        },
        centerName: {
            showText: intl.formatMessage({ id: 'centerName' }),
            showType: 'Text',
        },
        status: {
            showText: intl.formatMessage({ id: 'status' }),
            showType: 'Text',
        },
        attentionIndex: {
            showText: intl.formatMessage({ id: 'attentionIndex' }),
            showType: 'Text',
        },
        motionIndex: {
            showText: intl.formatMessage({ id: 'motionIndex' }),
            showType: 'Text',
        },
    }

    const vratActions = [
        {
            showText: intl.formatMessage({ id: 'details' }),
            onClick(record) {
                history.push({
                    pathname: '/system/manage/vratCaseDetail',
                    query: {
                        id: record.id,
                    },
                })
            },
        },
        {
            showText: intl.formatMessage({ id: 'viewReport' }),
            itemRender(record) {
                const { case: vratCase, status } = record
                return status === 'ReportReady' ? (
                    <span key="view">
                        <a
                            onClick={() => {
                                const url = '/report?caseId=' + encodeURIComponent(encryptAES(vratCase.id)) +  '&suspension=true' + '&isAdmin='+isAdmin
                                window.open(url, '_blank')
                            }}
                        >
                           {intl.formatMessage({ id: 'viewReport' })}
                        </a>
                    </span>
                ) : null
            },
        },
        // {
        //     showText: intl.formatMessage({ id: 'Clinician Edition' }),
        //     itemRender(record) {
        //         const { case: vratCase, status, testingCenter } = record
        //         const { reportKinds = [] } = testingCenter;
        //         return status === 'ReportReady' && reportKinds.includes('ClinicianEdition') ? (
        //             <span key="view">
        //                 <a
        //                     onClick={() => {
        //                         message.info('暂未完成')
        //                         return
        //                     }}
        //                 >
        //                     {intl.formatMessage({ id: 'Clinician Edition' })}
        //                 </a>
        //             </span>
        //         ) : null
        //     },
        // },
        // {
        //     showText: intl.formatMessage({ id: 'Educator Edition' }),
        //     itemRender(record) {
        //         const { case: vratCase, status, testingCenter } = record
        //         const { reportKinds = [] } = testingCenter;
        //         return status === 'ReportReady' && reportKinds.includes('EducationEdition') ? (
        //             <span key="view">
        //                 <a
        //                     onClick={() => {
        //                         message.info('暂未完成')
        //                         return
        //                     }}
        //                 >
        //                     {intl.formatMessage({ id: 'Educator Edition' })}
        //                 </a>
        //             </span>
        //         ) : null
        //     },
        // },
        {
            showText: intl.formatMessage({ id: 'Parent Edition' }),
            itemRender(record) {
                const { case: vratCase, status, testingCenter } = record
                const { reportKinds = [] } = testingCenter;
                return status === 'ReportReady' && reportKinds.includes('ParentEdition') ? (
                    <span key="view">
                        <a
                            onClick={() => {
                                const url = '/parentreport?caseId=' + vratCase.id + '&reId=' + vratCase.id + '&suspension=true'
                                window.open(url, '_blank')
                            }}
                        >
                            {intl.formatMessage({ id: 'Parent Edition' })}
                        </a>
                    </span>
                ) : null
            },
        },
        {
            showText: intl.formatMessage({ id: 'Parent Screen Edition' }),
            itemRender(record) {
              const { case: vratCase, status, testingCenter } = record
              const { reportKinds = [] } = testingCenter;
              return status === 'ReportReady' && reportKinds.includes('ParentScreenEdition') ? (
                <span key="view">
                  <a
                    onClick={() => {
                      const url = '/parentreport?caseId=' + vratCase.id + '&reId=' + vratCase.id + '&suspension=true&model=screen'
                      window.open(url, '_blank')
                    }}
                  >
                    {intl.formatMessage({ id: 'Parent Screen Edition' })}
                  </a>
                </span>
              ) : null
            },
        },
    ]

    const listProps = {
        loading: loading.models.adminSubjectVrat,
        listData: showList,
        listSupport: vratColumns,
        pagination,
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'adminSubjectVrat/changeTable', payload: data })
        },
        Actions: vratActions,
    }

    return (
        <div>
            <Button>{intl.formatMessage({ id: 'Create vCAT test' })}</Button>
            <MagicTable {...listProps} /></div>
    )
}


export default connect(({ adminSubjectVrat, user, loading }) => ({ adminSubjectVrat, currentUser: user.currentUser, loading }))(SubjectVrat)