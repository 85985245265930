import React from 'react'
import { connect } from 'umi'
import { Button, Row, Col, Divider } from 'antd'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import RocketProjectTip from 'components/RocketProjectTip';
import AddAssessmentModal from 'components/UserConfigurationModal/AddAssessmentModal';
import SendToGuardian from '../../QuestionnaireList/SendToGuardian'
import AssessmentSelectModal from './AssessmentSelectModal'
import AssessmentQrCode from 'components/AssessmentQrCode'
import styles from '../detail.less'
import { useIntl } from 'umi'
import config from 'utils/config'
import { getToken, getTenantId, getUserTenantId } from 'cognitiveleap-core-us/utils/auth'
import { getAssessmentStatus } from 'utils/utils'
const { mobileUrl } = config

const AssessmentInfo = ({ subjectAssessment, currentUser, loading, dispatch, subjectDetail }) => {

  const intl = useIntl()
  const { list, pagination, modalVisible, transferList, sendToGuardianModalVisible, assessmentTimesSettingRecords, assessmentModalVisible, currentAssessmentInfo, assessmentQrUrlModalVisible, assessmentQrUrl, assessmentQrData } = subjectAssessment
  const { subjectInfo } = subjectDetail;
  const { isBind, isLogout } = subjectInfo
  const { auth, changedTenant, current } = currentUser || {}
  const { institutionType, timesSetting } = changedTenant || {};
  const { grantedPolicies } = auth
  const roles = currentUser.current ? currentUser.current.roles : []
  const hasAssessmentWritePermission = grantedPolicies['RocketSystem.UserAssessment.Create'] === true

  const rolesName = roles.length > 0 && roles[0].name;

  const showOptionRoles =
    rolesName === 'CenterAdmin' || rolesName === 'SeniorCoach' || rolesName === 'Senior Coach';

  const assessmentColumns = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creatTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    assessmentName: {
      showText: intl.formatMessage({ id: 'testName' }),
      showType: 'Text',
    },
    showStatus: {
      showText: intl.formatMessage({ id: 'assessmentStatus' }),
      showType: 'Text',
    },
  }

  const assessmentActions = [
    // {
    //   showText: '下载测评详情',
    //   itemRender(record) {
    //     const { status } = record
    //     return status === 'ReportedReady' ? (
    //       <span key="downloadPDF">
    //         <a
    //           onClick={() => {
    //             dispatch({ type: 'subjectAssessment/downloadAssessmentPDF', payload: record.id })

    //             // const url = '/questionnaireView?id=' + record.id
    //             // window.open(url, '_blank')
    //           }}
    //         >
    //           下载测评详情
    //         </a>
    //       </span>
    //     ) : null
    //   },
    // },
    {
      showText: intl.formatMessage({ id: 'details' }),
      itemRender(record) {
        const { status, needPay } = record
        return status === 'ReportedReady' && !needPay ? (
          <span key="viewDetail">
            <a
              onClick={() => {
                const url = '/questionnaireView?id=' + record.id
                window.open(url, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'details' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'lookReport' }),
      itemRender(record) {
        const { status, needPay, assessment } = record
        const { name } = assessment || {}
        const isDevelopmentAssessment = name == 'developmentQuestionnaire'
        return status === 'ReportedReady' && !needPay && !isDevelopmentAssessment ? (
          <span key="view">
            <a
              onClick={() => {
                const reportUrl = `${mobileUrl}/m/rocketReport?to=${record.id}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`
                window.open(reportUrl, '_blank')
              }}
            >
              {intl.formatMessage({ id: 'lookReport' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'sendToParents' }),
      itemRender(record) {
        const { status, confirmType, needPay, assessment } = record
        const { name } = assessment || {}
        const isDevelopmentAssessment = name == 'developmentQuestionnaire'
        return !isDevelopmentAssessment && status === 'ReportedReady' && (confirmType == 'NotConfirm' || confirmType == 'Public') && !needPay && !isEnterAdmin() ? (
          <span key="view">
            {
              confirmType == 'NotConfirm' &&
              <a
                onClick={() => {
                  dispatch({
                    type: 'subjectAssessment/updateState',
                    payload: {
                      sendToGuardianModalVisible: true,
                      currentSendToGuardianRecord: record
                    }
                  })
                }}
              >
                {intl.formatMessage({ id: 'sendToParents' })}
              </a>
            }
            {
              confirmType == 'Public' && <span style={{ color: 'grey' }}>{intl.formatMessage({ id: 'hasSend' })}</span>
            }
          </span>
        ) : null
      }
    },
    {
      showText: intl.formatMessage({ id: 'getReport' }),
      itemRender(record) {
        const { status, needPay } = record || {}

        return status === 'ReportedReady' && needPay ? (
          <span key="view">
            <a
              onClick={() => { dispatch({ type: 'subjectAssessment/getAssessmentReport', payload: { record, intl } }) }}
            >
              {intl.formatMessage({ id: 'getReport' })}
            </a>
          </span>
        ) : null
      },
    },
    {
      showText: intl.formatMessage({ id: 'getAssessmentQrCode' }),
      itemRender(record) {
        const { status } = record || {}
        return status !== 'ReportedReady' ? (
          <span key="view">
            <a
              onClick={() => {
                dispatch({ type: 'subjectAssessment/getAssessmentQrCode', payload: { record, intl } })
              }}
            >
              {intl.formatMessage({ id: 'getAssessmentQrCode' })}
            </a>
          </span>
        ) : null
      },
    }
  ]

  const showList = list.map(item => {
    const { assessment } = item
    return {
      ...item,
      assessmentName: assessment.title,
      showStatus: getAssessmentStatus(item.status, intl),
    }
  })

  const isEnterAdmin = () => {
    return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
  }

  const listProps = {
    loading: loading.models.subjectAssessment,
    listData: showList,
    listSupport: assessmentColumns,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'subjectAssessment/changeTable', payload: data })
    },
    Actions: assessmentActions,
  }

  const selectModalProps = {
    title: intl.formatMessage({ id: 'addAssessment' }),
    allList: transferList,
    visible: modalVisible,
    assessmentTimesSettingRecords,
    loading: loading.effects['subjectAssessment/createAssessment'],
    onOk(keys) {
      dispatch({ type: 'subjectAssessment/createAssessment', payload: { keys, intl } })
    },
    onCancel() {
      dispatch({ type: 'subjectAssessment/updateState', payload: { modalVisible: false } })
    },
  }

  const sendToGuardianProps = {
    visible: sendToGuardianModalVisible,
    loading: loading.effects['subjectAssessment/sendToGuardian'],
    onCancel: () => {
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          sendToGuardianModalVisible: false
        }
      })
    },
    onOk: () => {
      dispatch({
        type: 'subjectAssessment/sendToGuardian',
        payload: {
          intl
        }
      })
    }
  }

  const newAssessment = () => dispatch({ type: 'subjectAssessment/loadTransferList' })

  const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;

  const addAssessmentModalProps = {
    visible: assessmentModalVisible,
    onCancel: () => {
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentModalVisible: false
        }
      })
    },
    currentItem: {
      subject: subjectInfo,
      name: subjectInfo?.name,
      tag: currentAssessmentInfo?.tag,
      remainingTimes: currentAssessmentInfo?.remainingTimes,
      tagName: currentAssessmentInfo?.tag?.displayName
    },
    reloadTable: () => {
      dispatch({ type: 'subjectAssessment/loadList' })
    }
  }

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false
        }
      })
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl }
      })
    }
  }

  return (
    <div className={styles.tabContent}>
      {
        !rocketProjectStatus &&
        <div style={{ marginBottom: '20px' }}>
          <RocketProjectTip />
        </div>
      }
      {
        hasAssessmentWritePermission && rocketProjectStatus && isBind && !isLogout &&
        <Row type="flex" justify="space-between">
          {
            institutionType !== 'Medical_Public' ? (
              <Col>
                {
                  (assessmentTimesSettingRecords.length > 0 && timesSetting)&&
                    assessmentTimesSettingRecords.map((record) => {
                      const { tag, remainingTimes, totalTimes } = record || {};
                      const { id, displayName } = tag || {};
                      return <div key={id}>
                        {displayName}:&nbsp;{intl.formatMessage({ id: 'Remaining Times' })}:&nbsp;
                        <span
                          style={{ fontWeight: 'bold', fontSize: '20px' }}
                        >
                          {remainingTimes}
                        </span>
                        <Divider type="vertical" />
                        {intl.formatMessage({ id: 'Total Times' })}:&nbsp;{totalTimes}
                        {showOptionRoles && <Divider type="vertical" />} 
                        {showOptionRoles && <a onClick={(e) => dispatch({ 
                          type: 'subjectAssessment/goSettings', 
                          payload: {
                            record
                          }})}>
                          {intl.formatMessage({ id: 'Settings' })}
                        </a>}
                      </div>
                    })
                }
              </Col>
            ) : (
              <Col />
            )
          }
          <Col>
            <Button onClick={newAssessment} type="primary" style={{ marginBottom: 10 }}>
              {intl.formatMessage({ id: 'addTest' })}
            </Button>
          </Col>
        </Row>
      }

      <MagicTable {...listProps} />

      <AssessmentSelectModal {...selectModalProps} />
      <SendToGuardian {...sendToGuardianProps} />
      <AssessmentQrCode {...qrCodeProps} />
      {assessmentModalVisible && <AddAssessmentModal {...addAssessmentModalProps} />}
    </div>
  )
}

export default connect(({ subjectAssessment, user, loading, subjectDetail }) => ({ subjectAssessment, currentUser: user.currentUser, loading, subjectDetail }))(
  AssessmentInfo
)
