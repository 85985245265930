import { connect, history, useIntl } from 'umi';
import { EditOutlined } from '@ant-design/icons';
import { Fragment, useEffect } from 'react';
import {
  Button,
  Divider,
  Card,
  Spin,
  message,
  Tabs,
  Modal,
  Drawer,
  Collapse,
  Popconfirm,
  Space,
} from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import {
  showGender,
  getCourseStatus,
  showCourseValidity,
  getUserImg,
} from 'utils/utils';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey';
import {
  getCorrentRateAndResult,
  formatterGradeName,
  getLanguageList,
} from 'cognitiveleap-core-us/utils/utils';

import moment from 'moment';

import style from './index.less';

const { Panel } = Collapse;

const addWayOption = (intl) => {
  return {
    Tenant: intl.formatMessage({ id: 'addedByCenter' }),
    User: intl.formatMessage({ id: 'QRCode' }),
  };
};

const { TabPane } = Tabs;

const UserDetail = ({
  userDetail,
  currentUser,
  dispatch,
  loading,
  location,
}) => {
  const intl = useIntl();
  useEffect(() => {
    dispatch({ type: 'userDetail/initPage', payload: location.query });
  }, []);

  const {
    userInfo,
    from,
    tabActiveKey,
    userClassTablePagination,
    selectModalVisible,
    editInfoVisible,
    userCourseList,
    content,
    userGrantCourse,
    drawerVisible,
    selectCourse,
    collapseActiveKey,
    referralInfoQuestions,
  } = userDetail;
  const { courses } = userGrantCourse || {};
  const {
    email,
    phone,
    picture,
    nickName,
    gender,
    subjects,
    addWay,
    isLogout,
    city,
    roleDefinition,
    emergencyContactPhoneNumber,
    emergencyContactName,
    emergencyContactRoleDefinition,
    source,
    countryCode,
    emergencyContactCountryCode,
  } = userInfo || {};
  const { auth } = currentUser || {};
  const { grantedPolicies } = auth || {};

  const { title, course } = selectCourse || {};
  const { contents } = course || {};

  const hasEmergencyInfo =
    emergencyContactName &&
    emergencyContactPhoneNumber &&
    emergencyContactRoleDefinition;

  const hasSubjectDetailPermission =
    grantedPolicies['Business.SubjectManagement'] === true;
  const hasCourseCreatePermission =
    grantedPolicies['RocketSystem.UserCourse.Create'] === true;
  const isLoading = loading.models.userDetail;
  const postCourseLoading = loading.effects['userDetail/postCourseToUser'];
  const editInfoLoading = loading.effects['userDetail/onUpdateUserInfo'];
  let tenantList = [];

  tenantList =
    subjects && Array.isArray(subjects) && subjects.length > 0
      ? Array.from(
          new Set(
            subjects.map(
              (item) =>
                item.userAddWay &&
                item.userAddWay.addTenant &&
                item.userAddWay.addTenant.displayName,
            ),
          ),
        ).filter((item) => item !== null)
      : [];

  const userClassTableListSupport = {
    grantTime: {
      showText: intl.formatMessage({ id: 'creationStartTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
    title: {
      showText: intl.formatMessage({ id: 'courseName' }),
      showType: 'Text',
    },
    length: {
      showText: intl.formatMessage({ id: 'sessionNumber' }),
      showType: 'Text',
    },
    isBGrant: {
      showText: intl.formatMessage({ id: 'createdByThisCenter' }),
      showType: 'Bool',
    },
    validity: {
      showText: intl.formatMessage({ id: 'availablePeriod' }),
      showType: 'Text',
    },
    showStatus: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
    },
    deletionTime: {
      showText: intl.formatMessage({ id: 'deauthorizationTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
  };

  const selectCourseModalProps = {
    title: intl.formatMessage({ id: 'openCourses' }),
    visible: selectModalVisible,
    loading: postCourseLoading,
    content,
    onCancel: () => {
      dispatch({
        type: 'userDetail/updateState',
        payload: { selectModalVisible: false },
      });
    },
    onOk: (data) => {
      const { courseIds, startDate, endDate } = data;
      const selectItem = courses.find((item) => item.id === courseIds);
      const selectItemTitle = selectItem ? selectItem.title : '';
      if (moment(startDate).valueOf() > moment(endDate).valueOf()) {
        message.error(intl.formatMessage({ id: 'dateError' }));
        return;
      }
      const title = intl.formatMessage(
        { id: 'confirmedHimSubmission' },
        {
          name: nickName ? nickName : intl.formatMessage({ id: 'unknown' }),
          title: selectItemTitle,
          time: showCourseValidity(
            { startTime: startDate, endTime: endDate },
            intl,
          ),
        },
      );
      Modal.confirm({
        title: <div style={{ color: 'red' }}>{title}</div>,
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk() {
          if (!postCourseLoading) {
            dispatch({
              type: 'userDetail/postCourseToUser',
              payload: { data, intl },
            });
          }
        },
      });
    },
  };

  const userClassTableProps = {
    listSupport: userClassTableListSupport,
    pagination: userClassTablePagination,
    listData: userCourseList.map((item, index) => {
      return {
        ...item,
        validity: showCourseValidity(item, intl),
        showStatus: getCourseStatus(item, intl),
        title: item.course.title,
        length: item.course.length,
        id: item.course.id,
        key: index,
      };
    }),
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { userClassTablePagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'userDetail/changeUserClassTable', payload: data });
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'StopAuthorization' }),
        itemRender(record) {
          const { isDeleted, status, isBGrant } = record;
          return !isDeleted && isBGrant && status !== 'Expired' ? (
            <Popconfirm
              placement="topLeft"
              title={intl.formatMessage({ id: 'SureStopAuthorization' })}
              onConfirm={() =>
                dispatch({
                  type: 'userDetail/cancelCourseToUser',
                  payload: record,
                })
              }
              okText={intl.formatMessage({ id: 'confirm' })}
              cancelText={intl.formatMessage({ id: 'cancel' })}
            >
              <a>{intl.formatMessage({ id: 'StopAuthorization' })}</a>
            </Popconfirm>
          ) : null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'testResult' }),
        itemRender(record) {
          const { course } = record;
          const { contents } = course;
          let questionnaire = contents.map((item) => item.questionnaires);
          questionnaire = questionnaire.flat(Infinity);
          return questionnaire && questionnaire.length > 0 ? (
            <a
              onClick={() =>
                dispatch({ type: 'userDetail/showTestResult', payload: record })
              }
            >
              {intl.formatMessage({ id: 'testResult' })}
            </a>
          ) : null;
        },
      },
    ],
  };

  const showUserSubject = (subjects = []) => {
    return subjects && subjects.length && subjects.length > 0 ? (
      subjects.map((item, index) => {
        const { coach = [] } = item || {};

        const coachNamesList =
          coach && Array.isArray(coach) && coach.length > 0
            ? coach.map((coachItem) => coachItem.name)
            : [];

        const coachNames = coachNamesList.join('; ');

        return (
          <div
            className={style.userSubjectItem}
            key={index}
            onClick={() => {
              if (hasSubjectDetailPermission) {
                history.push({
                  pathname: '/system/center/detail/subjectDetail',
                  query: {
                    id: item.id,
                  },
                });
              } else {
                message.warn(intl.formatMessage({ id: 'notPermission' }));
              }
            }}
          >
            <div className={style.userSubjectItemLeft}>
              {/* <Avatar
                                    size={48}
                                    src={item.picture || subject_default}
                                    shape='square'
                                    icon={<UserOutlined />}
                                /> */}
              {getUserImg(item, { height: '48px', width: '48px' })}
            </div>
            <div className={style.userSubjectItemRight}>
              <div className={style.userSubjectItemRightTop}>
                <div className={style.userSubjectItemRightTopName}>
                  {item.name}
                </div>
                {item.isLogout ? (
                  <span className={style.userSubjectItemRightTopDescriptionOff}>
                    {intl.formatMessage({ id: 'cancelled' })}
                  </span>
                ) : item.isBind ? (
                  <span className={style.userSubjectItemRightTopDescriptionOn}>
                    {intl.formatMessage({ id: 'authorized' })}
                  </span>
                ) : (
                  <span className={style.userSubjectItemRightTopDescriptionOff}>
                    {intl.formatMessage({ id: 'noLongerAuthorized' })}
                  </span>
                )}
              </div>
              <div className={style.userSubjectItemRightBottom}>
                {item.outpatientNumber && (
                  <div className={style.userSubjectItemRightBottomItem}>
                    {intl.formatMessage({ id: 'clinicNumber' })}:{' '}
                    {item.outpatientNumber}
                  </div>
                )}
                <div className={style.userSubjectItemRightBottomItem}>
                  <div className={style.userSubjectItemRightBottomItemChild}>
                    <Space split={<Divider type="vertical" />} size={1}>
                      <div className={style.userSubjectItemRightBottomSex}>
                        {showGender(item.gender, intl)}
                      </div>

                      <div>
                        {item.birthDay &&
                          moment(item.birthDay).format('MM/DD/YYYY')}
                      </div>

                      {item.addWay && (
                        <div>{addWayOption(intl)[item.addWay]}</div>
                      )}
                    </Space>
                  </div>
                </div>
                <div className={style.userSubjectItemRightBottomItem}>
                  <div className={style.userSubjectItemRightBottomItemChild}>
                    <Space split={<Divider type="vertical" />} size={1}>
                      {item.gradeName && (
                        <span>{formatterGradeName(item.gradeName)}</span>
                      )}
                      {item.language && (
                        <span>{getLanguageList(item.language)}</span>
                      )}
                    </Space>
                  </div>
                </div>
                <div className={style.userSubjectItemRightBottomItem}>
                  {coachNames && (
                    <div style={{ marginBottom: '10px' }}>
                      {intl.formatMessage({ id: 'trainer' })}: {coachNames}
                    </div>
                  )}
                </div>
                {/* {
                    item.guardians && item.guardians.length > 0 && item.guardians[0].roleDefinition && item.guardians[0].roleDefinition.displayName &&
                    <div className={style.userSubjectItemRightBottomItem}>{item.guardians[0].roleDefinition.displayName} {nickName}</div>
                }
                <div className={style.userSubjectItemRightBottomItem}>{handOption(intl)[item.dominantHand]}</div> */}
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <div></div>
    );
  };

  const onChangeCollapse = async (value) => {
    // 查找当前打开的小节，如果没有myquestionnaire属性就去获取API
    // if (collapseActiveKey.length < value.length) {
    //     let openId = ''
    //     value.forEach(item => {
    //         if (!collapseActiveKey.includes(item)) {
    //             openId = item
    //         }
    //     })
    //     const content = contents.find(item => item.id === openId)
    //     if (content && !content.myquestionnaire) {
    //         await dispatch({ type: 'userDetail/getAppUserQuestionnarie', payload: content })
    //     }
    // }
    dispatch({
      type: 'userDetail/updateState',
      payload: { collapseActiveKey: value },
    });
  };

  const modalProps = {
    visible: editInfoVisible,
    content,
    loading: editInfoLoading,
    title: intl.formatMessage({ id: 'editProfile' }),
    onSubmitText: intl.formatMessage({ id: 'submit' }),
    onOk: (values) => {
      dispatch({
        type: 'userDetail/onUpdateUserInfo',
        payload: { values, intl },
      });
    },
    onCancel() {
      dispatch({
        type: 'userDetail/updateState',
        payload: { editInfoVisible: false },
      });
    },
  };

  const showReferralInfoQuestionsText = () => {
    if (source && source.includes('customizeOthers')) {
      const { customizeOthersText, checkValues } = JSON.parse(source);
      const questionText = referralInfoQuestions.find((item) => {
        return item.value === checkValues;
      })?.text;
      // return questionText + ' ' + customizeOthersText;
      return customizeOthersText;
    }
    return referralInfoQuestions.find((item) => {
      return item.value === source;
    })?.text;
  };

  return (
    <Spin spinning={isLoading}>
      <div className={style.userDetail}>
        <div className={style.userDetailTop}>
          {from == 'notification' ? null : (
            <Button
              onClick={() => history.goBack()}
              className={style.topButton}
            >
              {intl.formatMessage({ id: 'back' })}
            </Button>
          )}
          <p className={style.detailDescript}>
            {intl.formatMessage({ id: 'guardianDetails' })}
          </p>
        </div>
        <div className={style.userContent}>
          <div className={style.userDetailInfo}>
            <div className={style.userDetailInfoLeft}>
              {/* <Avatar
                                size={72}
                                src={picture || subject_default}
                                shape='square'
                                icon={<UserOutlined />}
                            /> */}
              {getUserImg(userInfo, { height: '72px', width: '72px' })}
            </div>
            <div className={style.userDetailInfoRight}>
              <div className={style.userDetailInfoRightTop}>
                <div className={style.userDetailInfoRightTopName}>
                  {nickName}
                </div>
                <div className={style.userDetailInfoRightSex}>
                  {roleDefinition?.displayName}
                </div>
                <Fragment>
                  <Divider
                    type="vertical"
                    className={style.userDetailInfoRightDivider}
                  />
                  <div className={style.userDetailInfoRightAddStyle}>
                    {city}
                  </div>
                </Fragment>
                {/* <div className={nickName ? style.userDetailInfoRightSex : style.userDetailInfoRightSexEmpty}>{showGender(gender, intl)}</div>
                                {
                                    addWay &&
                                    <Fragment>
                                        <Divider type="vertical" className={style.userDetailInfoRightDivider} />
                                        <div className={style.userDetailInfoRightAddStyle}>{addWayOption(intl)[addWay]}</div>
                                    </Fragment>
                                } */}
                <EditOutlined
                  onClick={() => dispatch({ type: 'userDetail/editInfo' })}
                  style={{
                    marginLeft: '10px',
                    color: 'rgba(0,0,0,0.45)',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className={style.userDetailInfoRightBottom}>
                <div>
                  {countryCode}-{phone}
                </div>
                {email && (
                  <Fragment>
                    {phone && (
                      <Divider
                        type="vertical"
                        className={style.userDetailInfoRightBottomDivider}
                      />
                    )}
                    <div>{email}</div>
                  </Fragment>
                )}
              </div>
              <div className={style.userDetailInfoRightBottom}>
                <Space direction="vertical">
                  {source && (
                    <div>
                      {intl.formatMessage({ id: 'Referral Information' })}:{' '}
                      {showReferralInfoQuestionsText()}
                    </div>
                  )}
                  {hasEmergencyInfo && (
                    <div>
                      {intl.formatMessage({ id: 'Contact Person Information' })}
                      :{' '}
                      <Space split={<Divider type="vertical" />} size={2}>
                        {emergencyContactName}
                        {emergencyContactRoleDefinition?.displayName}
                        {emergencyContactPhoneNumber}
                      </Space>
                    </div>
                  )}
                </Space>
              </div>
              <div className={style.userDetailInfoRightCenterName}>
                {tenantList.length > 0 &&
                  tenantList.map((tenantListItem, tenantListIndex) => {
                    if (tenantList.length - 1 === tenantListIndex) {
                      return (
                        <span key={tenantListIndex}>{tenantListItem}</span>
                      );
                    } else {
                      return (
                        <span key={tenantListIndex}>{tenantListItem}，</span>
                      );
                    }
                  })}
              </div>
            </div>
          </div>

          <Card
            title={
              <strong>{intl.formatMessage({ id: 'childrenList' })}</strong>
            }
            bordered={false}
            className={style.userSubjectCard}
          >
            <div className={style.userSubject}>{showUserSubject(subjects)}</div>
          </Card>

          <Tabs activeKey={tabActiveKey} className={style.userClass}>
            <TabPane tab={intl.formatMessage({ id: 'course' })} key="class">
              <div className={style.userClassContent}>
                {hasCourseCreatePermission && !isLogout && (
                  <Button
                    className={style.openUserClassButton}
                    onClick={() => dispatch({ type: 'userDetail/openCourses' })}
                  >
                    {intl.formatMessage({ id: 'openCourses' })}
                  </Button>
                )}
                <MagicTable {...userClassTableProps} />
                <MagicFormModal {...selectCourseModalProps} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      <Drawer
        open={drawerVisible}
        title={<b style={{ fontSize: '18px' }}>{title}</b>}
        width={720}
        onClose={() =>
          dispatch({
            type: 'userDetail/updateState',
            payload: { drawerVisible: false },
          })
        }
      >
        <Collapse onChange={onChangeCollapse} activeKey={collapseActiveKey}>
          {contents &&
            contents.map((item) => {
              const { name, myquestionnaire, id } = item || {};
              const { questionnaireAndAnswer } = myquestionnaire || {};
              const { answer, questionnaire } = questionnaireAndAnswer || {};
              const { answerContent, creationTime } = answer || {};
              const { jsonString, correctRate } = questionnaire || {};
              const correntRateAndResult = getCorrentRateAndResult(
                jsonString,
                answerContent,
                correctRate,
              );
              const { correntCount, questionArr, correntRate } =
                correntRateAndResult || {};
              const showSurveyProps = {
                contents: questionArr,
                choice: answerContent
                  ? JSON.parse(JSON.stringify(answerContent))
                  : {},
              };
              return (
                <Panel
                  header={name}
                  key={id}
                  extra={
                    <div>
                      {myquestionnaire && (
                        <div>
                          <div style={{ fontWeight: '600' }}>
                            {intl.formatMessage(
                              { id: 'correctRateCorrentRate' },
                              { correntCount, correntRate },
                            )}
                          </div>
                          <div>
                            {moment(creationTime).format('MM/DD/YYYY HH:mm')}
                          </div>
                        </div>
                      )}
                    </div>
                  }
                >
                  {myquestionnaire ? (
                    <div style={{ padding: '30px 0 0 30px' }}>
                      <ShowSurvey {...showSurveyProps} />
                    </div>
                  ) : (
                    <div
                      style={{
                        height: '100px',
                        lineHeight: '100px',
                        textAlign: 'center',
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        {intl.formatMessage({ id: 'emptyData' })}
                      </span>
                    </div>
                  )}
                </Panel>
              );
            })}
        </Collapse>
      </Drawer>
      <MagicFormModal {...modalProps} />
    </Spin>
  );
};

export default connect(({ userDetail, user, loading }) => ({
  userDetail,
  currentUser: user.currentUser,
  loading,
}))(UserDetail);
