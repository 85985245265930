import React, { useEffect } from 'react'
import moment from "moment"
import { connect, history, useIntl } from 'umi'
import { Card, Menu, Tabs, Space, Dropdown, Divider, Input, Select, Button, Modal, Empty, Drawer, Spin, Tooltip } from 'antd'
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import Question from 'components/Question'
import QuestionnaireRecordDrawer from 'components/QuestionnaireRecordDrawer'
import AutoGenerationCertModal from 'components/AutoGenerationCertModal'
import { getScore } from 'utils/utils'
import styles from './index.less'

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const TrainingStaffList = ({ currentUser, trainingStaffList, dispatch, loading }) => {
    const intl = useIntl();

    const { hostRoles = [] } = currentUser || {};
    const isSupervisor = hostRoles.find(item => item.name === "supervisor");

    const exportLoading = loading.effects['trainingStaffList/exportExcel']

    useEffect(() => {
        dispatch({ type: 'trainingStaffList/changeTenant' })
    }, [])

    const {
        activeKey,
        currentTab,
        tabsData,
        currentTenant,
        name,
        // learningState,
        // qualifications,
        pagination,
        renewalModalVisible,
        content,
        list,
        examList,
        selectItem,
        type,
        showSupervisionVisible,
        supervisionTabActiveKey,
        eidtSupervisionVisible,
        supervisorQuestion,
        supervisorIsFinshed,
        supervisorSubmitData,
        questionnaireListData,
        supervisionQuestionDetail,
        currentTrainerSummaryId,
        currentSupervisorId,
        TrainerFrom,
        TrainingPayWay,
        remarkContent,
        remarkModalVisible,
        assesDrawerVisible,
        autoGenerationCertModalVisible,
        learningStatus,
        issuedStatus
    } = trainingStaffList

    if (currentTenant === null) {
        return (
            <Card
                title={<b style={{ fontSize: '20px' }}>培训人员管理</b>}
                bordered={false}>
                <Empty />
            </Card>)
    }

    const currentData = tabsData.find(item => item.trainingModule && item.trainingModule.id === activeKey)
    const { usedNumber, numberOfPlaces, validDate, issuedCount, trainingModule, usePriceNum, price } = currentData || {}
    const { displayName, oralQuestionnaireId, practicalQuestionnaireId } = trainingModule || {}

    const { items } = questionnaireListData || {}
    const { items: subItems } = items || {}

    let currentId = ''
    if (supervisionTabActiveKey === "TrainerSummary") {
        currentId = currentTrainerSummaryId
    } else {
        currentId = currentSupervisorId
    }
    const currentQuestion = subItems ? subItems.find(item => item.id === currentId) : null

    // 填写问卷
    const { questionnaireAndAnswer: supervisorQuestionnaireAndAnswer } = supervisorQuestion || {}
    const { questionnaire: supervisorQuestionnaire, answer: supervisorAnswer } = supervisorQuestionnaireAndAnswer || {}
    const { jsonString: supervisorJsonString } = supervisorQuestionnaire || {}
    const { answerContent: supervisorAnswerContent } = supervisorSubmitData || {}

    // 展示问卷
    const { questionnaireAndAnswer: supervisionDetaiQuestionnaireAndAnswerl, fillerUser } = supervisionQuestionDetail || {}
    const { answer: supervisionDetaiAnswer, questionnaire: supervisionDetaiQuestionnaire } = supervisionDetaiQuestionnaireAndAnswerl || {}
    const { jsonString: supervisionDetaiJsonString, correctRate: supervisionDetaiCorrectRate } = supervisionDetaiQuestionnaire || {}
    const { answerContent: supervisionDetaiAnswerContent } = supervisionDetaiAnswer || {}
    const { name: fillerName } = fillerUser || {}
    let questionArr = [], correntRateAndResult = null
    if (supervisionQuestionDetail) {
        correntRateAndResult = getCorrentRateAndResult(supervisionDetaiJsonString, supervisionDetaiAnswerContent, supervisionDetaiCorrectRate)
        questionArr = correntRateAndResult && correntRateAndResult.questionArr
    } else {
        questionArr = []
    }

    const listSupport = {
        arrangeTime: {
            showText: intl.formatMessage({ id: 'creationTime' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY HH:mm',
            },
        },
        name: {
            showText: intl.formatMessage({ id: 'name' }),
            showType: 'Text',
        },
        trainingStep: {
            showText: intl.formatMessage({ id: 'Overall progress' }),
            showType: 'Text',
            render: (text) => intl.formatMessage({ id: text }),
        },
        learningState: {
            showText: intl.formatMessage({ id: 'learnStatus' }),
            showType: 'Text',
            filter: {
                hasFilter: true,
                filterOptions: [
                    {
                        text: intl.formatMessage({ id: 'notStart' }),
                        value: 'NotStarted',
                    },
                    {
                        text: intl.formatMessage({ id: 'proceeding' }),
                        value: 'Learning',
                    },
                    {
                        text: intl.formatMessage({ id: 'paused' }),
                        value: 'Stopped',
                    },
                    {
                        text: intl.formatMessage({ id: 'expired' }),
                        value: 'Expired',
                    },
                ],
            },
        },
        speed: {
            showText: intl.formatMessage({ id: 'Course Progress' }),
            showType: 'Text',
        },
        endDate: {
            showText: intl.formatMessage({ id: 'Course end time' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY',
            },
        },
        training: {
            showText: intl.formatMessage({ id: 'Number of training sessions' }),
            showType: 'Text',
        },
        supervision: {
            showText: intl.formatMessage({ id: 'Number of supervisions' }),
            showType: 'Text',
        },
        writtenTestPassed: {
            showText: intl.formatMessage({ id: 'WrittenExam' }),
            showType: 'Text',
            render: (text) => {
                if (text === null) {
                    return '-'
                }
                return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
            }
        },
        oralTestPassed: {
            showText: intl.formatMessage({ id: 'OralExam' }),
            showType: 'Text',
            render: (text) => {
                if (text === null) {
                    return '-'
                }
                return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
            }
        },
        handsOnTestPassed: {
            showText: intl.formatMessage({ id: 'PracticalExam' }),
            showType: 'Text',
            render: (text) => {
                if (text === null) {
                    return '-'
                }
                return intl.formatMessage({ id: text ? 'pass' : 'notPass' })
            }
        },
        // from: {
        //     showText: "来源",
        //     showType: 'Text',
        // },
        // trainingPayWayText: {
        //     showText: "名额情况",
        //     showType: 'Text',
        // },
        // realPrice: {
        //     showText: "实付金额($)",
        //     showType: 'Text',
        // },
        // startDate: {
        //     showText: '开始学习时间',
        //     showType: 'Time',
        //     addtional: {
        //         format: 'MM/DD/YYYY',
        //     },
        // },
        // stopTime: {
        //     showText: "停止学习时间",
        //     showType: 'Time',
        //     addtional: {
        //         format: 'MM/DD/YYYY',
        //     },
        // },
        // addedDays: {
        //     showText: "续期天数",
        //     showType: 'Text',
        // },
        // issuedStatus: {
        //     showText: '证书',
        //     showType: 'Text',
        //     filter: {
        //         hasFilter: true,
        //         filterOptions: [
        //             {
        //                 text: '未颁发',
        //                 value: 'NotIssued',
        //             },
        //             {
        //                 text: '生效中',
        //                 value: 'InEffect',
        //             },
        //             {
        //                 text: '未生效',
        //                 value: 'Ineffective',
        //             },
        //             {
        //                 text: '已过期',
        //                 value: 'Expired',
        //             },
        //             {
        //                 text: '已取消',
        //                 value: 'Canceled',
        //             },
        //         ],
        //     },
        // },
        issueTime: {
            showText: intl.formatMessage({ id: 'Certificate issuance time' }),
            showType: 'Time',
            render: (text, record) => {
                const { certificateStatus } = record || {};
                return certificateStatus !== 'NotIssued' && moment(text).format('YYYY-MM-DD')
            }
        },
        lastModificationTime: {
            showText: intl.formatMessage({ id: 'Updated Time' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY HH:mm',
            },
        },
        remark: {
            showText: intl.formatMessage({ id: 'comments' }),
            showType: 'Text',
            render: (text) => {
                if (text && text.length >= 4) {
                    return <Tooltip title={text}>
                        <span>{text.slice(0, 4)}...</span>
                    </Tooltip>
                }
                return text;
            }
        },
    }

    const onchangeSearchValue = async (value, type) => {
        const defaultPagination = {
            current: 1,
            pageSize: 10,
            total: 0,
          }
        dispatch({ type: 'trainingStaffList/updateState', payload: { pagination: defaultPagination } })
        switch (type) {
            case "name":
                await dispatch({ type: 'trainingStaffList/updateState', payload: { [type]: value.target.value } })
                break
            case "learningState":
            case "qualifications":
            case "TrainerFrom":
            case "TrainingPayWay":
                await dispatch({ type: 'trainingStaffList/updateState', payload: { [type]: value } })
                dispatch({ type: 'trainingStaffList/onsearch' })
                break
        }
    }

    const onClickMenuItem = ({ key }, record) => {
        switch (key) {
            case "Supervision":
                dispatch({ type: "trainingStaffList/showSupervision", payload: { record } })
                break
            case "AssessRecords":
                dispatch({ type: 'trainingStaffList/updateState', payload: { assesDrawerVisible: true, selectItem: record } })
                break;
        }
    }

    const tableProps = {
        listData: list.map(item => {
            const { trainerFrom, trainingPayWay, isPay, price, certificateStatus } = item || {}
            return {
                ...item,
                learningState: {
                    "Learning": '学习中',
                    "NotStarted": '未开始',
                    "Stopped": '已停止',
                    "Expired": '已过期'
                }[item.status],
                speed: `${item.progress} / ${item.progressCount}`,
                issuedStatus: {
                    "Ineffective": '未生效',
                    "InEffect": '生效中',
                    "Expired": '已过期',
                    "NotIssued": '未颁发',
                    "Canceled": '已取消'
                }[certificateStatus],
                from: {
                    "Tenant": "中心安排",
                    "H5": "H5申请"
                }[trainerFrom],
                trainingPayWayText: {
                    "Quota": "中心名额",
                    "Pay": "自主缴费"
                }[trainingPayWay],
                realPrice: trainingPayWay !== "Pay" ? '-' : isPay ? price : '未缴费'
            }
        }),
        listSupport,
        loading: loading.models.trainingStaffList,
        pagination,
        filters: {
            learningState: learningStatus,
            issuedStatus
        },
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'trainingStaffList/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '详情',
                onClick(record) {
                    history.push({
                        pathname: '/system/manage/trainingStaffDetail',
                        query: {
                            id: record.trainerId
                        }
                    })
                },
            },
            {
                showText: '续期',
                onClick(record) {
                    dispatch({ type: 'trainingStaffList/showSelectItem', payload: { record, type: 'renewal' } })
                },
            },
            {
                showText: '停止',
                itemRender(record) {
                    const { name, status } = record;
                    return (
                        status === 'Learning' || status === 'NotStarted' || status === 'Stopped' ?
                            <React.Fragment>
                                {
                                    (status === 'Learning' || status === 'NotStarted') &&
                                    <a onClick={() => {
                                        Modal.confirm({
                                            title: <span style={{ color: 'red' }}>确定停止{name}的{currentTab}培训吗？停止后用户将不可继续学习，如需继续学习可在列表页操作【继续】</span>,
                                            okText: '确定',
                                            cancelText: '取消',
                                            onOk() {
                                                dispatch({ type: 'trainingStaffList/postStopOrStart', payload: record })
                                            }
                                        })
                                    }}>停止</a>
                                }
                                {
                                    status === 'Stopped' &&
                                    <a onClick={() => dispatch({ type: 'trainingStaffList/postStopOrStart', payload: record })}>继续</a>
                                }
                            </React.Fragment>
                            :
                            null
                    )
                }
            },
            // {
            //     showText: '颁发证书',
            //     itemRender(record) {
            //         const { name, status, issued, issueTime } = record;
            //         return (
            //             !issued && issueTime ?
            //                 <a onClick={() => dispatch({ type: 'trainingStaffList/showSelectItem', payload: { record, type: 'reissue' } })}>重新颁发{displayName}证书</a> :
            //                 (
            //                     !issued ?
            //                         <a onClick={() => dispatch({ type: 'trainingStaffList/showSelectItem', payload: { record, type: 'issue' } })}>颁发{displayName}证书</a>
            //                         :
            //                         null
            //                 )
            //         )
            //     }
            // },
            {
                showText: '编辑备注',
                onClick(record) {
                    dispatch({ type: 'trainingStaffList/editRemark', payload: record })
                },
            },
            // {
            //     showText: '更多',
            //     itemRender(record) {
            //         const menu = (
            //             <Menu onClick={(data) => onClickMenuItem(data, record)}>
            //                 <Menu.Item key="Supervision">见习督导记录</Menu.Item>
            //                 {(oralQuestionnaireId || practicalQuestionnaireId) && <Menu.Item key="AssessRecords">考核记录</Menu.Item>}
            //             </Menu>
            //         )

            //         return (
            //             <Dropdown overlay={menu}>
            //                 <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            //                     <span>更多</span>
            //                 </a>
            //             </Dropdown>
            //         )
            //     }
            // }
        ]
    }

    const modalProps = {
        title: {
            "renewal": '续期',
            "issue": `颁发${displayName}证书`,
            "reissue": `重新颁发${displayName}证书`
        }[type],
        visible: renewalModalVisible,
        content,
        onCancel() {
            dispatch({ type: 'trainingStaffList/updateState', payload: { renewalModalVisible: false } })
        },
        otherTopContent: type === 'renewal' && <div style={{ marginLeft: '90px' }}>
            为{selectItem?.name}的{displayName}续期，当前结束学习时间是{moment(selectItem?.endDate).format('YYYY-MM-DD')}:
            <p />
        </div>,
        onOk(value) {
            Modal.confirm({
                title: <span style={{ color: 'red' }}>{type === 'renewal' ? `确定为${selectItem?.name}的${displayName}续期到${moment(value?.endDate).format('YYYY-MM-DD')}吗？` : '确定提交吗？'}</span>,
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    dispatch({ type: 'trainingStaffList/onSubmit', payload: { record: value, type } })
                }
            })
        }
    }

    const autoGenerationCertModalProps = {
        visible: autoGenerationCertModalVisible,
        selectItem,
        trainingModuleId: activeKey,
        onCloseModal: () => {
            dispatch({ type: 'trainingStaffList/updateState', payload: { autoGenerationCertModalVisible: false } })
        }
    }

    const remarkModalProps = {
        title: '编辑备注',
        loading: loading.models.trainingStaffList,
        visible: remarkModalVisible,
        content: remarkContent,
        onCancel: () => {
            dispatch({ type: 'trainingStaffList/updateState', payload: { remarkModalVisible: false } })
        },
        onOk: (values) => {
            dispatch({ type: 'trainingStaffList/editRemarkSubmit', payload: values })
        },
    }

    const onChangSupervisionTabs = (value) => {
        dispatch({ type: 'trainingStaffList/onChangSupervisionTabs', payload: value })
    }

    // 见习督导跟踪表
    const supervisorOnComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            Modal.confirm({
                title: <div style={{ color: 'red' }}>确定提交吗？</div>,
                okText: '确定',
                cancelText: '取消',
                onOk: () => {
                    dispatch({ type: 'trainingStaffList/updateState', payload: { supervisorIsFinshed: true } })
                    dispatch({ type: 'trainingStaffList/supervisorSubmit', payload: { answerContent, isComplete, finishedPage } })
                }
            })
            return
        } else {
            dispatch({ type: 'trainingStaffList/supervisorSubmit', payload: { answerContent, isComplete, finishedPage } })
        }
    }

    const supervisorValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'trainingStaffList/updateState', payload: { supervisorSubmitData: { answerContent, isComplete, finishedPage } } })
        if (needSubmit) {
            supervisorOnComplete({ answerContent, isComplete, finishedPage })
        }
    }

    const supervisorOnClose = () => {
        if (!supervisorIsFinshed && supervisorSubmitData) {
            supervisorOnComplete(supervisorSubmitData)
        }
        dispatch({ type: 'trainingStaffList/onCloseEidtSupervision' })
    }

    const supervisorQuestionProps = {
        content: supervisorJsonString ? supervisorJsonString : '',
        answerContent: supervisorAnswerContent || '',
        answer: supervisorAnswer,
        onComplete: supervisorOnComplete,
        onValueChange: supervisorValueChange
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: supervisionDetaiAnswerContent ? JSON.parse(JSON.stringify(supervisionDetaiAnswerContent)) : {},
        showIcon: false
    }

    const assesDrawerProps = {
        visible: assesDrawerVisible,
        TrainingModuleId: activeKey,
        userInfo: selectItem,
        onClose: () => {
            dispatch({ type: 'trainingStaffList/updateState', payload: { assesDrawerVisible: false } })
        }
    }

    const menuList = subItems && subItems.length > 0 ? (
        <Menu>
            {
                subItems.map((item, index) => {
                    return (
                        <Menu.Item key={index} onClick={() => dispatch({ type: 'trainingStaffList/clickMenuItem', payload: item })}>{moment(item.finishTime).format("YYYY/MM/DD HH:mm")}</Menu.Item>
                    )
                })
            }
        </Menu>
    ) : null

    return (
        <Card
            title={<b style={{ fontSize: '20px' }}>培训人员管理</b>}
            bordered={false}
            extra={
                !isSupervisor && (
                    <AnalysisTenantSelect
                    showAll={false}
                    currentTenant={currentTenant}
                    onChange={(e) => {
                        dispatch({ type: 'trainingStaffList/changeTenant', payload: { id: e } })
                    }}
                />
                )
            }
        >
            {
                tabsData && tabsData.length > 0 ?
                    <React.Fragment>
                        <Tabs
                            activeKey={activeKey}
                            onChange={async (value) => {

                                await dispatch({ type: 'trainingStaffList/updateState', payload: { activeKey: value } })
                                dispatch({ type: 'trainingStaffList/getTenantTrainerModuleList' })

                            }}>
                            {
                                tabsData && tabsData.length > 0 &&
                                tabsData.map(item => {
                                    return <TabPane tab={item.trainingModule && item.trainingModule.displayName} key={item.trainingModule && item.trainingModule.id}></TabPane>
                                })
                            }
                        </Tabs>
                        <div className={styles.showData}>
                            <div className={styles.showDataLeft}>
                                <Space split={<Divider type="vertical" />}>
                                    <span>生效中证书 {issuedCount || 0} 人</span>
                                    {/* <span>学习已通过 Y 人</span> */}
                                    <span>共发放培训名额 {numberOfPlaces + usedNumber || 0} 人</span>
                                    <span>剩余名额 {numberOfPlaces || 0}人</span>
                                    <span>课程有效期 {validDate || 0} 天</span>
                                    {/* <span>自主缴费 {usePriceNum}</span> */}
                                </Space>
                            </div>
                            {/* <div className={styles.showRight}>
                                自主缴费价格：{price || 0}
                            </div> */}
                        </div>
                        <div className={styles.trainingStaffListSearch}>
                            <div className={styles.trainingStaffContentSearchTop}>
                                <Search
                                    placeholder="搜索姓名"
                                    value={name}
                                    onChange={(value) => onchangeSearchValue(value, 'name')}
                                    style={{ width: 200, margin: '0 30px 10px 0' }}
                                    onSearch={() => dispatch({ type: 'trainingStaffList/onsearch' })}
                                />
                                {/* <div className={styles.trainingStaffListSearchItem}>
                                    <div className={styles.trainingStaffListSearchItemLabel}>学习状态</div>
                                    <Select value={learningState} onChange={(value) => onchangeSearchValue(value, 'learningState')} style={{ width: 200 }}>
                                        <Option key={''}>全部</Option>
                                        <Option key={'NotStarted'}>未开始</Option>
                                        <Option key={'Learning'}>学习中</Option>
                                        <Option key={'Stopped'}>已停止</Option>
                                        <Option key={'Expired'}>已过期</Option>
                                    </Select>
                                </div>
                                <div className={styles.trainingStaffListSearchItem}>
                                    <div className={styles.trainingStaffListSearchItemLabel}>证书</div>
                                    <Select value={qualifications} onChange={(value) => onchangeSearchValue(value, 'qualifications')} style={{ width: 200 }}>
                                        <Option key={''}>全部</Option>
                                        <Option key={true}>已颁发</Option>
                                        <Option key={false}>未颁发</Option>
                                    </Select>
                                </div> */}
                                <div className={styles.trainingStaffContentSearchBottom}>
                                    <div className={styles.trainingStaffListSearchItem}>
                                        <div className={styles.trainingStaffListSearchItemLabel}>来源</div>
                                        <Select value={TrainerFrom} onChange={(value) => onchangeSearchValue(value, 'TrainerFrom')} style={{ width: 200 }}>
                                            <Option key={''}>全部</Option>
                                            <Option key={'Tenant'}>中心安排</Option>
                                            <Option key={'H5'}>H5申请</Option>
                                        </Select>
                                    </div>
                                    {/* <div className={styles.trainingStaffListSearchItem}>
                                        <div className={styles.trainingStaffListSearchItemLabel}>名额情况</div>
                                        <Select value={TrainingPayWay} onChange={(value) => onchangeSearchValue(value, 'TrainingPayWay')} style={{ width: 200 }}>
                                            <Option key={''}>全部</Option>
                                            <Option key={'Quota'}>中心名额</Option>
                                            <Option key={'Pay'}>自主缴费</Option>
                                        </Select>
                                    </div> */}
                                </div>
                                <Button onClick={() => dispatch({ type: 'trainingStaffList/onclear' })}>清空</Button>
                            </div>
                            <Button onClick={() => dispatch({ type: 'trainingStaffList/exportExcel' })} loading={exportLoading}>
                                导出列表
                            </Button>
                        </div>
                        <MagicTable {...tableProps} />
                        {autoGenerationCertModalVisible && <AutoGenerationCertModal {...autoGenerationCertModalProps} />}
                        <MagicFormModal {...modalProps} />
                        <MagicFormModal {...remarkModalProps} />

                    </React.Fragment>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            }

            {/* 展示见习督导记录 */}
            <Drawer
                title={<b style={{ fontSize: '20px' }}>见习督导记录</b>}
                width={700}
                open={showSupervisionVisible}
                onClose={() => dispatch({ type: 'trainingStaffList/updateState', payload: { showSupervisionVisible: false } })}
            >
                <Spin spinning={loading.models.trainingStaffList}>
                    <Tabs activeKey={supervisionTabActiveKey} onChange={onChangSupervisionTabs}>
                        <TabPane tab="督导师跟踪表" key="Supervisor"></TabPane>
                        <TabPane tab="训练师小结" key="TrainerSummary"></TabPane>
                    </Tabs>
                    <div className={styles.supervisionEditBtn}>
                        <div className={styles.supervisionEditBtnLeft}>
                            {
                                supervisionTabActiveKey === "Supervisor" && subItems && subItems.length > 0 &&
                                <span>
                                    <span>分数：{getScore(correntRateAndResult, supervisionDetaiAnswer)}</span>
                                    <span style={{ marginLeft: '20px' }}>{fillerName}</span>
                                </span>
                            }
                            {
                                supervisionTabActiveKey === "SupervisionReview" && subItems && subItems.length > 0 && supervisionDetaiAnswer &&
                                <span>最近提交时间：{supervisionDetaiAnswer ? moment(supervisionDetaiAnswer.lastModificationTime || supervisionDetaiAnswer.creationTime).local().format("YYYY/MM/DD HH:mm") : ''}</span>
                            }
                        </div>
                        <div className={styles.supervisionEditBtnRight}>
                            {/* admin仅查看见习督导跟踪表 */}
                            {/* {
                                supervisionTabActiveKey === "Supervisor" &&
                                <Button icon={<PlusOutlined />} onClick={() => dispatch({ type: 'trainingStaffList/eidtSupervision' })}>见习督导跟踪表</Button>
                            } */}
                            {
                                subItems && subItems.length > 0 &&
                                <div style={{ marginRight: "20px" }}>
                                    <Dropdown overlay={menuList}>
                                        <a>{currentQuestion ? moment(currentQuestion.finishTime).local().format("YYYY/MM/DD HH:mm") : ''}</a>
                                    </Dropdown>
                                    <DownOutlined style={{ marginLeft: "5px" }} />
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: '25px' }}>
                        <ShowSurvey {...showSurveyProps} />
                    </div>
                </Spin>
            </Drawer>

            {/* 填写见习督导跟踪表 */}
            <Drawer
                title={<b style={{ fontSize: '20px' }}>见习督导跟踪表</b>}
                width={700}
                open={eidtSupervisionVisible}
                onClose={supervisorOnClose}
            >
                <Question {...supervisorQuestionProps} />
            </Drawer>

            {/* 口试Drawer */}
            {assesDrawerVisible && <QuestionnaireRecordDrawer {...assesDrawerProps} />}
            </Card>
    )
}

export default connect(({ user, trainingStaffList, loading }) => ({ currentUser: user.currentUser, trainingStaffList, loading }))(TrainingStaffList)