import React, { useState } from 'react';
import {
  Avatar,
  Divider,
  Menu,
  Dropdown,
  Modal,
  Button,
  Popover,
  message,
  Space,
} from 'antd';
import {
  DiffOutlined,
  EditOutlined,
  DownOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  RightSquareFilled,
  LeftSquareFilled,
} from '@ant-design/icons';
import moment from 'moment';
import subjectDefault from 'assets/subject_default.png';
import styles from './index.less';
import { showGender, PrefixZero, getUserImg } from 'utils/utils';
import GenerateMusic from 'components/GenerateMusic';
import ClassListenRecord from 'components/ClassListenRecord';
import PdtStepForm from './components/PdtStepForm';
import CancellationModal from './CancellationModal';
import EditChildInfoModal from 'components/EditChildInfoModal';
import AddContractModal from 'components/AddContractModal';

import {
  formatterGradeName,
  showStage,
  getLanguageList,
} from 'cognitiveleap-core-us/utils/utils';
import { countAge } from 'utils/utils';
import {
  GetDiagnosticInformationList,
  GetChiefComplaintList,
} from 'services/trainingSystem';
import { PostRestartPdt, PostStopPdt } from 'services/pdt';
import { history, useIntl } from 'umi';

const TimeFormat = 'MM/DD/YYYY HH:mm';

const SubjectInfo = ({
  subjectInfo,
  currentUser,
  reviewData,
  classAndMusicInfo = {},
  reloadPage = () => {},
  getFASReport = () => {},
  from,
  institutionType,
  subjectResource,
  isShowArchives = true,
  hasReadUserQuestionnairePermission = false,
  hasWriteUserQuestionnairePermission = false,
  parentInterview,
  editNum,
  addContractModalVisible,
  changeModalVisible = () => {},
}) => {
  const intl = useIntl();

  const isAdmin = from === 'admin';

  const [visible, setVisible] = useState({
    musicModal: false,
    listenDrawer: false,
    pdtModal: false,
    cancelModal: false,
    editChildModal: false,
  });
  const [isOpen, setIsOpen] = useState(true);
  const [type, setType] = useState('add');
  const [pdtType, setPdtType] = useState('add');

  if (subjectInfo == null) {
    return <div></div>;
  }

  let fromTenantName = [];

  if (subjectResource && subjectResource.length > 0) {
    fromTenantName = Array.from(
      new Set(
        subjectResource.map((item) => item.tenant && item.tenant.displayName),
      ),
    ).filter((item) => item !== null);
  }
  const {
    changedTenant,
    auth,
    adminRoles: [{ name: roleName } = {}] = [],
  } = currentUser || {};
  const { grantedPolicies } = auth || {};
  const haveCreatePdtPermission = grantedPolicies['Pdt.SubjectPdt.Create'];
  const haveCreateContractPermission = grantedPolicies['RocketSystem.UserContract.Create'];

  const pdtProjectStatus =
    changedTenant && changedTenant.projectStatus
      ? changedTenant.projectStatus.find((item) => item.name == 'Project.PDT')[
          'status'
        ]
      : false;

  const { result, lastModificationTime } = reviewData || {};

  const {
    name,
    birthDay,
    gender,
    language,
    isBind = true, //cl admin端没有这个字段，会为undefined，设置默认值为true。
    guardians,
    dominantHand,
    creationTime,
    picture,
    outpatientNumber,
    isLogout,
    schoolName,
    gradeName,
    className,
    id: subjectId,
    stage,
    coach,
    pdtModules = [],
    pdtGoals = [],
    pdtCustomGoal,
    appStartTime,
    stopTime,
    startRecords,
  } = subjectInfo;
  const { groups = [], userMusics = [], userContract = {} } = classAndMusicInfo;

  const {
    id: contractId,
    stopTime: contractStopTime,
    remainClass: contractRemainClass,
  } = userContract || {};

  let guardianInfo = '';
  let guardianId = '';

  if (guardians && Array.isArray(guardians) && guardians.length > 0) {
    const { roleDefinition, nickName, id } = guardians[0];
    const { displayName } = roleDefinition || {};
    guardianInfo = `${displayName || ''} ${nickName || ''}`;
    guardianId = id;
  }

  const getGuardianDetailPath = () => {
    return from === 'center'
      ? '/system/center/detail/userDetail'
      : '/system/manage/userDetail';
  };

  const goArchives = () => {
    if (from === 'center') {
      const url = `/system/archivesV2?subjectId=${subjectId}&from=${from}`;
      window.open(url, '_blank');
    }
    if (from === 'admin') {
      const url = `/system/archivesV2?subjectId=${subjectId}&from=${from}`;
      window.open(url, '_blank');
    }
  };

  const coachNamesList =
    coach && Array.isArray(coach) && coach.length > 0
      ? coach.map((item) => item.name)
      : [];

  const coachNames = coachNamesList.join('；');

  const props = {
    subjectInfo,
    visible: visible.musicModal,
    type,
    resetType: () => {
      setType('add');
    },
    onClose() {
      setVisible({
        ...visible,
        musicModal: false,
      });
    },
    reloadPage,
    getFASReport,
  };

  const listenProps = {
    visible: visible.listenDrawer,
    subjectInfo,
    groups,
    onClose() {
      setVisible({
        ...visible,
        listenDrawer: false,
      });
    },
  };

  const onChickGenderMusic = async () => {
    if (stage === '黄金时代') {
      setType('add');
      setVisible({
        ...visible,
        musicModal: true,
      });
    } else {
      const resList = await Promise.all([
        GetDiagnosticInformationList({
          UserId: subjectId,
        }),
        GetChiefComplaintList({
          UserId: subjectId,
        }),
      ]);
      if (resList.every((res) => res.response.ok)) {
        const firstRes = resList[0].data.items.totalCount;
        const twoRes = resList[1].data.items.totalCount;

        if (firstRes === 0 || twoRes === 0) {
          Modal.confirm({
            title: intl.formatMessage({ id: 'fillNameProfile' }, { name }),
            width: 480,
            icon: <ExclamationCircleOutlined />,
            content: (
              <div style={{ color: 'gray' }}>
                {intl.formatMessage({ id: 'informationDes' })}
              </div>
            ),
            okText: intl.formatMessage({ id: 'fillOut' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk() {
              window.open(`/system/archivesV2?subjectId=${subjectId}`);
            },
          });
        } else {
          setType('add');
          setVisible({
            ...visible,
            musicModal: true,
          });
        }
      }
    }
  };

  const pdtStepProps = {
    type: pdtType,
    subjectId,
    guardianId,
    pdtModules: pdtModules.map((item) => item.pdtModule),
    pdtGoals,
    pdtCustomGoal,
    onClose: () => {
      setPdtType('add');
      setVisible({
        ...visible,
        pdtModal: false,
      });
    },
  };

  const openModules = (type) => {
    setPdtType(type);
    setVisible({
      ...visible,
      pdtModal: true,
    });
  };

  const focusAction = async (type) => {
    const params = {
      guardianId,
      subjectId,
    };

    const isStop = type === 'stop';

    Modal.confirm({
      title: intl.formatMessage(
        { id: isStop ? 'pauseFocusTitle' : 'continueFocuseTitle' },
        { name },
      ),
      icon: <ExclamationCircleOutlined />,
      content: isStop ? (
        <div>{intl.formatMessage({ id: 'pauseFocusContent' })}</div>
      ) : null,
      okText: intl.formatMessage({
        id: isStop ? 'focusPause' : 'continueFocuse',
      }),
      onOk: async () => {
        const res = isStop
          ? await PostStopPdt(params)
          : await PostRestartPdt(params);

        if (res.response.ok) {
          reloadPage();
        } else {
          const { error } = res.data;
          error.message && message.error(error.message);
        }
      },
    });
  };

  const FocusMoreContent = stopTime ? (
    <Button type="text" onClick={() => focusAction('start')}>
      {intl.formatMessage({ id: 'continueFocuse' })}
    </Button>
  ) : (
    <Button type="text" onClick={() => focusAction('stop')}>
      {intl.formatMessage({ id: 'focusPause' })}
    </Button>
  );

  const historyContent = (
    <div style={{ width: '180px' }}>
      {startRecords &&
        startRecords.length > 0 &&
        startRecords.map((item) => {
          const { times, stopTime, startTime } = item || {};
          return (
            <p key={times}>
              {times +
                ' : ' +
                moment(startTime).format(TimeFormat) +
                ' - ' +
                (stopTime
                  ? moment(stopTime).format(TimeFormat)
                  : intl.formatMessage({ id: 'none' }))}
            </p>
          );
        })}
    </div>
  );

  const cancelProps = {
    subjectInfo,
    reviewData,
    visible: visible.cancelModal,
    reloadPage,
    closeModal: () =>
      setVisible({
        ...visible,
        cancelModal: false,
      }),
  };

  const editProps = {
    subjectInfo,
    reloadPage,
    modalVisible: visible.editChildModal,
    closeModal: () =>
      setVisible({
        ...visible,
        editChildModal: false,
      }),
  };

  const editChildInfo = () => {
    setVisible({
      ...visible,
      editChildModal: true,
    });
  };

  const addContractModalProps = {
    visible: addContractModalVisible,
    type: 'add',
    userData: {
      name,
      userId: subjectId,
    },
    closeModal: () => {
      changeModalVisible('addContractModalVisible', false)
    },
    reloadPage,
  };

  return (
    <div className={isOpen ? styles.subjectInfo : styles.closeSubjectInfo}>
      <span onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <RightSquareFilled
            style={{ fontSize: '24px', color: '#3C84FF', cursor: 'pointer' }}
          />
        ) : (
          <LeftSquareFilled
            style={{ fontSize: '24px', color: '#3C84FF', cursor: 'pointer' }}
          />
        )}
      </span>

      <div className={styles.subjectInfoContent}>
        <div className={styles.subjectInfoRight}>
          <Space size={'middle'}>
            {getUserImg(subjectInfo, { height: '64px', width: '64px' })}
            {/* <Avatar
              shape="square"
              size={64}
              src={picture !== undefined ? picture : subjectDefault}
            /> */}
            <Space direction="vertical">
              <Space>
                <span className={styles.subjectName}>{name}</span>
                {from === 'center' ? (
                  isLogout ? (
                    <span className={styles.subjectIsLogout}>
                      {intl.formatMessage({ id: 'cancelled' })}
                    </span>
                  ) : (
                    <span
                      className={
                        isBind ? styles.subjectIsbind : styles.subjectIsNotbind
                      }
                    >
                      {isBind
                        ? intl.formatMessage({ id: 'authorized' })
                        : intl.formatMessage({ id: 'noLongerAuthorized' })}
                    </span>
                  )
                ) : null}
                {(roleName === 'admin' || from === 'center') && (
                  <span style={{ cursor: 'pointer' }} onClick={editChildInfo}>
                    <EditOutlined />
                  </span>
                )}
              </Space>
              <div className={styles.addTime}>{`${intl.formatMessage({
                id: 'addTime',
              })} : ${moment(creationTime).format('MM/DD/YYYY HH:mm')}`}</div>
            </Space>
          </Space>

          <Space
            split={<Divider type="vertical" />}
            size={1}
            style={{ margin: '10px 0' }}
          >
            <span>{showGender(gender, intl)}</span>
            {/* {showStage(stage) && <span>{showStage(stage)}</span>} */}
            <span>{moment(birthDay).format('MM/DD/YYYY')}</span>
            <span>{countAge(birthDay, intl)}</span>
            <span>{getLanguageList(language)}</span>
          </Space>

          <Space>
            {outpatientNumber && (
              <span className={styles.numText}>{outpatientNumber}</span>
            )}
            {institutionType === 'Medical' && (
              <span onClick={editNum}>
                <EditOutlined />
              </span>
            )}
          </Space>

          {coachNames && (
            <div style={{ marginBottom: '10px' }}>
              {intl.formatMessage({ id: 'trainer' })}：{coachNames}
            </div>
          )}

          <div className={styles.subjectInfoRightInfo}>
            <div className={styles.guardianInfo}>
              {guardianInfo && (
                <a
                  onClick={() => {
                    history.push({
                      pathname: getGuardianDetailPath(),
                      query: {
                        id: guardianId,
                      },
                    });
                  }}
                >
                  {guardianInfo}
                </a>
              )}
            </div>

            <div className={styles.schoolInfo}>
              {schoolName && (
                <span className={styles.schoolInfoItem}>{schoolName}</span>
              )}
              {gradeName && (
                <span className={styles.schoolInfoItem}>
                  {formatterGradeName(gradeName)}
                </span>
              )}
              {className && (
                <span className={styles.schoolInfoItem}>{className}</span>
              )}
            </div>

            <div className={styles.fromTenantName}>
              {fromTenantName &&
                Array.isArray(fromTenantName) &&
                fromTenantName.length > 0 &&
                fromTenantName.map(
                  (fromTenantNameItem, fromTenantNameIndex) => {
                    if (fromTenantName.length - 1 === fromTenantNameIndex) {
                      return (
                        <span key={fromTenantNameIndex}>
                          {fromTenantNameItem}
                        </span>
                      );
                    } else {
                      return (
                        <span key={fromTenantNameIndex}>
                          {fromTenantNameItem}，
                        </span>
                      );
                    }
                  },
                )}
            </div>

            {/* 班级列表 */}
            {groups && groups.length > 0 && (
              <div className={styles.classList}>
                <div>{intl.formatMessage({ id: 'class' })}：</div>
                <div className={styles.classItemList}>
                  {groups.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <span
                          className={styles.classItem}
                          onClick={() => {
                            history.push({
                              pathname:
                                '/system/center/detail/groupClassDetail',
                              query: {
                                classId: item.id,
                              },
                            });
                          }}
                        >
                          {item.name}
                        </span>
                        {index !== groups.length - 1 ? '，' : null}
                      </div>
                    );
                  })}
                </div>
                <span
                  className={styles.listenHistory}
                  onClick={() => {
                    setVisible({
                      ...visible,
                      listenDrawer: true,
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'trainingListeningRecords' })}
                </span>
              </div>
            )}

            {/* 幼儿没有优脑音乐 */}
            {showStage(stage) !== '幼儿' && from !== 'admin' && (
              <div className={styles.musicInfo}>
                <div style={{ margin: '10px 0' }}>
                  {intl.formatMessage({ id: 'ClassHFSMusic' })}：
                </div>
                {!userMusics || userMusics.length === 0 ? (
                  <div className={styles.willGen} onClick={onChickGenderMusic}>
                    {intl.formatMessage({ id: 'pending' })} &gt;
                  </div>
                ) : (
                  <div>
                    {userMusics[0].code.toUpperCase()}&nbsp;{' '}
                    {PrefixZero(userMusics[0].musicCourseNum, 2)}
                    &nbsp;&nbsp;&nbsp;
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item key={'edit'}>
                            <a
                              onClick={() => {
                                setType('edit');
                                setVisible({
                                  ...visible,
                                  musicModal: true,
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'editHFSMusic' })}
                            </a>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {intl.formatMessage({ id: 'more' })} <DownOutlined />
                      </a>
                    </Dropdown>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className={styles.subjectInfoRightInfoGender}>
            {/* {!isLogout && isBind && hasWriteUserQuestionnairePermission && (
              <span className={styles.interview} onClick={parentInterview}>
                <DiffOutlined style={{ fontSize: '22px' }} />
                <span className={styles.interviewText}>
                  {intl.formatMessage({ id: 'parentInterviews' })}
                </span>
                {'>'}
              </span>
            )} */}
            {roleName === 'agent'
              ? null
              : isShowArchives &&
                !isLogout &&
                isBind &&
                hasReadUserQuestionnairePermission && (
                  <span className={styles.archives} onClick={goArchives}>
                    <DiffOutlined style={{ fontSize: '22px' }} />
                    <span className={styles.archivescontent}>
                      {intl.formatMessage({ id: 'archives' })}
                    </span>
                    {'>'}
                  </span>
                )}
          </div>

          {pdtProjectStatus &&
            haveCreatePdtPermission &&
            !isLogout &&
            isBind &&
            (pdtModules.length > 0 || pdtGoals.length > 0 ? (
              <div className={styles.modulesList}>
                {result === null && (
                  <div
                    className={styles.modulesLeft}
                    onClick={() => {
                      setVisible({
                        ...visible,
                        cancelModal: true,
                      });
                    }}
                  >
                    <ExclamationCircleOutlined
                      style={{ color: 'red', fontSize: '18px' }}
                    />
                    &nbsp;
                    {intl.formatMessage({ id: 'Apply for Cancellation' })}
                  </div>
                )}
                {result === 'Allow' ? (
                  <div className={styles.modulesRight}>
                    <div className={styles.cancelPdtInfo}>
                      <div className={styles.title}>
                        {intl.formatMessage({ id: 'FocusEDTx is Cancelled' })}
                        <Popover
                          placement="bottomRight"
                          content={
                            <Button
                              onClick={() => {
                                setVisible({
                                  ...visible,
                                  pdtModal: true,
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'StartFocusEDTx' })}
                            </Button>
                          }
                          title={null}
                        >
                          <MoreOutlined
                            style={{ fontWeight: 600, color: 'black' }}
                          />
                        </Popover>
                      </div>
                    </div>
                    <div className={styles.cancelTime}>
                      {intl.formatMessage({ id: 'Cancelled Time' })}
                      {moment(lastModificationTime).format(
                        'MM/DD/YYYY HH:mm:ss',
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={styles.modulesRight}>
                    <div className={styles.showPdtInfo}>
                      {appStartTime ? (
                        <>
                          <div className={styles.title}>
                            <div
                              style={{ color: stopTime ? '#fb6120' : 'black' }}
                            >
                              {intl.formatMessage({
                                id: stopTime ? 'focusHasPause' : 'FocusEDTxOn',
                              })}
                              &nbsp;
                              <Popover
                                placement="bottomRight"
                                content={historyContent}
                                title={intl.formatMessage({
                                  id: 'Operation record',
                                })}
                              >
                                <ClockCircleOutlined />
                              </Popover>
                            </div>
                            <Popover
                              placement="bottomRight"
                              content={FocusMoreContent}
                              title={null}
                            >
                              <MoreOutlined style={{ fontWeight: 600 }} />
                            </Popover>
                          </div>
                          <div>
                            {intl.formatMessage({
                              id: stopTime ? 'stopTime' : 'startTime',
                            })}
                            :{' '}
                            {moment(stopTime || appStartTime).format(
                              'MM/DD/YYYY HH:mm:ss',
                            )}
                          </div>
                        </>
                      ) : (
                        <div className={styles.notStartText}>
                          {intl.formatMessage({
                            id: 'activatedOfficiallyStarted',
                          })}
                        </div>
                      )}
                    </div>
                    <div
                      className={styles.modules}
                      onClick={() => openModules('modules')}
                    >
                      {intl.formatMessage({ id: 'Modules' })} {'>'}
                    </div>
                    <div
                      className={styles.goals}
                      onClick={() => openModules('goals')}
                    >
                      {intl.formatMessage({ id: 'Goals' })} {'>'}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible({
                      ...visible,
                      pdtModal: true,
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'StartFocusEDTx' })}
                </Button>
              </div>
            ))}

          {
            !isAdmin && (
              <div className={styles.contractInfo}>
              <div className={styles.contractTitle}>{intl.formatMessage({ id: 'User Contract Details' })}: </div>
              {Object.keys(userContract || {}).length > 0 ? (
                <Space>
                  {contractStopTime ? (
                    <div>{intl.formatMessage({ id: 'ContractStop' })}</div>
                  ) : (
                    <Space split={<Divider type="vertical" />} size={2}>
                      <div>{intl.formatMessage({ id: 'ContractActive' })}</div>
                      <div>
                        {intl.formatMessage({ id: 'User Remaining Sessions' })}:{' '}
                        <span style={contractRemainClass <= 3 ? { color: 'red', fontSize: '18px' } : {}}>{contractRemainClass}</span>
                      </div>
                    </Space>
                  )}
                  {
                    haveCreateContractPermission && (
                      <a
                        onClick={() => {
                          // const url = `/system/center/detail/userContractDetail?name=${name}&userId=${subjectId}`
                          // window.open(url, '_blank')
                          history.push({
                            pathname: '/system/center/detail/userContractDetail',
                            query: {
                              name,
                              userId: subjectId,
                            },
                          });
                        }}
                      >
                        {intl.formatMessage({ id: 'details' })}
                      </a>
                    )
                  }
                </Space>
              ) : (
                <Space>
                  <div>{intl.formatMessage({ id: 'none' })}</div>
                  {
                    haveCreateContractPermission && (
                      <a
                        onClick={() => {
                          changeModalVisible('addContractModalVisible', true)
                        }}
                      >
                        {intl.formatMessage({ id: 'Add Contract' })}
                      </a>
                    )
                  }
                </Space>
              )}
              </div>
            )
          }
        </div>
      </div>

      {(visible.musicModal || type === 'edit') && <GenerateMusic {...props} />}
      {visible.listenDrawer && <ClassListenRecord {...listenProps} />}
      {visible.pdtModal && <PdtStepForm {...pdtStepProps} />}
      {visible.cancelModal && <CancellationModal {...cancelProps} />}
      <AddContractModal {...addContractModalProps} />
      <EditChildInfoModal {...editProps} />
    </div>
  );
};

export default SubjectInfo;
