import React, { useEffect } from 'react'
import { connect, useIntl } from 'umi';
import { Card, Divider, Input, Select, DatePicker, Button } from 'antd'
import styles from './index.less'
import moment from 'moment'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { isLegitimateDate, getFormatDate } from 'utils/utils'

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD';
const dateOption = ['openingTime']

const inputAttrs = ['userNickName', 'courseName']

const UserClassAnalysis = ({ dispatch, adminUserClassAnalysis }) => {
    const intl = useIntl();

    useEffect(() => {
        dispatch({ type: 'adminUserClassAnalysis/initPage' })
    }, [])

    const { userClassAnalysisTablePagination, searchOption, courseAnalysisData, currentTenant } = adminUserClassAnalysis

    const { addedCourseMonth, totalCourseCount, totalCancelCount, userCourse } = courseAnalysisData || {}

    const userClassAnalysisTableListSupport = {
        creationTime: {
            showText: '开通时间',
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY HH:mm',
            },
        },
        title: {
            showText: '课程名称',
            showType: 'Text',
        },
        picture: {
            showText: intl.formatMessage({ id: 'photo' }),
            showType: 'Image',
        },
        userName: {
            showText: '姓名',
            showType: 'Text',
        },
        length: {
            showText: '课时数',
            showType: 'Text',
        }
    }

    const onChange = async (type, value) => {
        const cloneSearchOption = JSON.parse(JSON.stringify(searchOption))
        if (dateOption.includes(type)) {
            cloneSearchOption[type].startTime = value && value[0] ? getFormatDate(value[0], true) : ''
            cloneSearchOption[type].endTime = value && value[1] ? getFormatDate(value[1], false) : ''
        } else {
            cloneSearchOption[type] = value
        }
        await dispatch({
            type: 'adminUserClassAnalysis/updateState',
            payload: {
                searchOption: cloneSearchOption,
                userClassAnalysisTablePagination: { ...userClassAnalysisTablePagination, ...{ current: 1 } }
            }
        })
        if (!inputAttrs.includes(type)) onSearch();
    }

    const onSearch = () => {
        dispatch({ type: 'adminUserClassAnalysis/getUserCourseAnalysis' })
    }

    const userClassAnalysisTableProps = {
        listSupport: userClassAnalysisTableListSupport,
        pagination: userClassAnalysisTablePagination,
        listData: userCourse ? userCourse.items.map(item => {
            return {
                ...item,
                length: item.course.length,
                title: item.course.title
            }
        }) : [],
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { userClassAnalysisTablePagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'adminUserClassAnalysis/changeTable', payload: data })
        },
    }

    const { userNickName, courseName, openingTime } = searchOption

    return (
        <Card
            title={
                <b style={{ fontSize: '20px' }}>课程分析</b>
            }
            extra={
                <AnalysisTenantSelect
                    showAll={true}
                    currentTenant={currentTenant}
                    onChange={(e) => {
                        dispatch({ type: 'adminUserClassAnalysis/changeTenant', payload: { id: e } })
                    }}
                />
            }
            bordered={false}
        >
            <div className={styles.userClassAnalysisTop}>
                <span>本月新增课程数：{addedCourseMonth || 0}</span>
                <Divider type='vertical' />
                <span>累计课程数：{totalCourseCount || 0}</span>
                <Divider type='vertical' />
                <span>累计停授课程数：{totalCancelCount || 0}</span>
            </div>
            <div className={styles.userClassAnalysisSearch}>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>关键字</span>
                    <Search
                        placeholder="用户昵称"
                        value={userNickName}
                        onChange={e => onChange('userNickName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, width: 220 }}
                    />
                </div>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>课程名称</span>
                    <Search
                        placeholder='课程名称'
                        value={courseName}
                        onChange={e => onChange('courseName', e.target.value)}
                        onSearch={value => onSearch(value)}
                        style={{ height: 32, width: 220 }}
                    />
                </div>
                <div className={styles.userClassAnalysisSearchItem}>
                    <span className={styles.userClassAnalysisSearchItemTitle}>开通时间</span>
                    <RangePicker
                        onChange={date => onChange('openingTime', date)}
                        placeholder={['开始日期', '结束日期']}
                        disabledDate={(current) => {
                            return current && current > moment()
                        }}
                        value={[
                            openingTime.startTime ? moment(openingTime.startTime, dateFormat) : null,
                            openingTime.endTime ? moment(openingTime.endTime, dateFormat) : null
                        ]}
                    />
                </div>
                <Button className={styles.clear} onClick={() => { dispatch({ type: 'adminUserClassAnalysis/onClear' }) }}>清空</Button>
            </div>
            <Button className={styles.toExcelButton} onClick={() => { dispatch({ type: 'adminUserClassAnalysis/downloadExcel' }) }}>导出列表</Button>
            <MagicTable {...userClassAnalysisTableProps} />
        </Card>
    )
}

export default connect(({ adminUserClassAnalysis }) => ({ adminUserClassAnalysis }))(UserClassAnalysis)