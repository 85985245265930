import { connect, history, useIntl } from 'umi'
import { useEffect } from 'react';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import { Input, Row, Col, Modal, Button, Card } from 'antd'
const { Search } = Input

const SubjectList = ({ dispatch, adminSubjectList, loading }) => {
  const intl = useIntl();
  const { list, pagination, searchField, school, currentTenant } = adminSubjectList
  const isLoading = loading.effects['adminSubjectList/loadPage'];

  useEffect(() => {
    dispatch({ type: 'adminSubjectList/changeTenant' })
  }, [])

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    birthDay: {
      showText: intl.formatMessage({ id: 'birthDay' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    name: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
    },
    gender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
    },
    schoolName: {
      showText: intl.formatMessage({ id: 'school' }),
      showType: 'Text',
    },
    // userStatus: {
    //   showText: '用户状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['已激活', '已冻结'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [{ text: '已激活', value: 'on' }, { text: '已冻结', value: 'off' }],
    //   },
    // },
    // vipStatus: {
    //   showText: '会员状态',
    //   showType: 'Status',
    //   addtional: {
    //     statusArray: ['非会员', '会员', '过期会员'],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [
    //       { text: '非会员', value: 'None' },
    //       { text: '会员', value: 'Vip' },
    //       { text: '过期会员', value: 'ExpiredVip ' },
    //     ],
    //   },
    // },
  }

  const searchProps = {
    placeholder: intl.formatMessage({ id: 'searchName' }),
    onSearch(value) {
      dispatch({ type: 'adminSubjectList/filterFetch', payload: { searchField: value } })
    },
    onChange(value) {
      dispatch({ type: 'adminSubjectList/updateState', payload: { searchField: value.target.value } })
    },
    value: searchField,
  }

  const schoolSearchProps = {
    placeholder: intl.formatMessage({ id: 'searchSchool' }),
    onSearch(value) {
      dispatch({ type: 'adminSubjectList/filterFetch', payload: { school: value } })
    },
    onChange(value) {
      dispatch({ type: 'adminSubjectList/updateState', payload: { school: value.target.value } })
    },
    value: school,
  }

  // const showList = list.map(item => {
  //   const { subjects } = item
  //   const subjectCount = subjects.length
  //   return {
  //     ...item,
  //     subjectCount,
  //   }
  // })

  const tableProps = {
    listData: list,
    listSupport,
    pagination,
    loading: isLoading,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'adminSubjectList/changeTable', payload: data })
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'details' }),
        onClick(record) {
          history.push({
            pathname: '/system/manage/subjectDetail',
            query: {
              id: record.id,
            },
          })
        },
      },
      {
        showText: intl.formatMessage({ id: 'Freeze Users' }),
        onClick(record) {
          console.log('冻结用户')
        },
      },
    ],
  }

  const onClear = () => {
    dispatch({ type: 'adminSubjectList/clearAllFilterAndSorter' })
  }

  return (
    <Card
        title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'subjectList' })}</b>}
        extra={
            <AnalysisTenantSelect
                showAll={true}
                currentTenant={currentTenant}
                onChange={(e) => {
                    dispatch({ type: 'adminSubjectList/changeTenant', payload: { id: e } })
                }}
            />
        }
        bordered={false}
    >
      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Search {...searchProps} style={{ height: 32, width: 220, marginRight: '10px' }} />
        <Search {...schoolSearchProps} style={{ height: 32, width: 220, marginRight: '10px' }} />
        <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
      </Row>
      <MagicTable {...tableProps} />
    </Card>
  )
}

export default connect(({ adminSubjectList, loading }) => ({ adminSubjectList, loading }))(SubjectList)
