import React, { useState, useEffect } from 'react'
import { Row, Col, Divider, Card, Tag, message, Spin, Menu, Dropdown, Button, Space } from 'antd'
import { AlignLeftOutlined, LeftOutlined, RightOutlined, CustomerServiceOutlined, StarOutlined, LayoutOutlined, DownOutlined } from '@ant-design/icons'
import { showEndTime } from 'utils/utils'
import { deepCopy } from 'cognitiveleap-core-us/utils/utils';
import styles from '../components.less'
import { useIntl, connect } from 'umi'
import courseCardStyle from './index.less';
import activityImg from 'assets/icon_fill.png';
import brainLogo from 'assets/brainLogo.png'

const operateOptions = {
  'Increase': '+1',
  'Decrease': '-1'
}

const CourseCard = ({
  allData,
  musicProgress,
  type = 'v1',
  cardLoading = false,
  showTop = false,
  isCurrent,
  onClickCard = () => { },
  onClickCardItem = () => { },
  onOpenFeedback = () => { },
  onFinishCourse,
  endCourseLoading = false,
  onFeedback,
  hasOfflinePlanWritePermission,
  rocketProjectStatus = null,
  isFixed = null,
  isAdmin = false,
  isShowApp = null,
  canNext = false,
  isBind = null,
  isLogout = null,
  canChange = null,
  hasOfflinePlanReadPermission,
  onMusicPlayerDrawer,
  onStartNextCourse,
  onAdjustLevel,
  onSwitchCourseType,
  previewHfsRecord,
  onDownloadHfsRecord,
  onChangeNum = () => { },
  endTime,
  from,
}) => {
  if (allData === null) {
    return <span></span>
  }

  const intl = useIntl()

  // showNextClass 一对多的是否显示 开启下一课时，一对多没有总的课时数
  const {
    iLs,
    isTry,
    planItems = [],
    userOfflineTrainingPlan,
    showNextClass = false,
    num: currentNum,
    nonePlaybook = [],
    numPunchOutput,
    categoryPunch,
    isAdjustment,
    isOpening,
    isApp,
    coachName,
    canViewDetails,
    totalNum,
    operate
  } = allData || {}
  const { canListen = false, cannotListenReason } = musicProgress || {}
  const [num, setNum] = useState(currentNum) // 没有销毁不会重新赋值
  const tracks = iLs ? iLs.tracks : []
  const { feedbacks: guardianFeedback, hasDone: guardianHasDone, audioRecordPunch, userCourseItems = [] } = deepCopy(numPunchOutput || { feedbacks: [], hasDone: false, audioRecordPunch: false })
  const { feedbacks: categoryFeedbacks, hasDone: categoryHasDone } = categoryPunch || {};

  if (audioRecordPunch) {
    if (audioRecordPunch.punched) {
      audioRecordPunch.hasDone = true
      if (audioRecordPunch.listenedList.length === 0) {
        audioRecordPunch.hasDone = false
      }
    } else {
      audioRecordPunch.hasDone = null
    }
  }

  const changeDetail = () => {
    userOfflineTrainingPlan && onChangeNum(userOfflineTrainingPlan.id, num)
  }

  useEffect(() => {
    canChange && changeDetail();
  }, [num])

  useEffect(() => {
    num !== currentNum && setNum(currentNum)
  }, [currentNum])

  const clickCard = e => {
    e && e.stopPropagation()
    userOfflineTrainingPlan && canViewDetails && onClickCard(userOfflineTrainingPlan.id, num, currentNum)
  }
  const finishCourse = e => {
    e.stopPropagation()
    let musicFeedBackFlag = true
    const guardianFeedbackFlag = guardianHasDone == false ? true : guardianFeedback.length > 0
    if (audioRecordPunch) {
      if (!audioRecordPunch.punched) {
        musicFeedBackFlag = false
      }
    }

    // 暂时没有音乐反馈
    // 结束课时去除一对多儿童反馈限制
    // userCourseItems.filter(item => item.attendance === 'Attend').every(item => item.hasDone !== null) &&
    if (type === 'v2' && planItems.every(item => item.hasDone !== null) && nonePlaybook.every(item => item.hasDone !== null) && guardianHasDone != null) {
      userOfflineTrainingPlan && onFinishCourse(userOfflineTrainingPlan, num)
    } else if (planItems.every(item => item.hasDone !== null) && nonePlaybook.every(item => item.hasDone !== null) && guardianFeedbackFlag && musicFeedBackFlag && categoryHasDone != null) {
      userOfflineTrainingPlan && onFinishCourse(userOfflineTrainingPlan, num)
    } else {
      message.warning(intl.formatMessage({ id: 'submitFeedBackDes' }))
    }
  }

  const startNextCourse = e => {
    userOfflineTrainingPlan && onStartNextCourse(userOfflineTrainingPlan.id, num)
  }

  const showAdjustLevel = e => {
    onAdjustLevel()
  }

  const giveFeedback = (e) => {
    e.stopPropagation()
    userOfflineTrainingPlan && onFeedback(userOfflineTrainingPlan.id, num)
  }

  const downloadHfsRecord = (e) => {
    e.stopPropagation()
    userOfflineTrainingPlan && onDownloadHfsRecord(userOfflineTrainingPlan.id, num)
  }

  const onPreviewHfsRecord = (e) => {
    e.stopPropagation()
    previewHfsRecord(userOfflineTrainingPlan.id, num)
  }

  const showPlanFeedBackDetail = (item, type) => {
    const { trainingProgram = null, trainingProgramDetail = null, hasDone, id: courseItemId = '' } = item || {}
    let showWord = ''
    if (hasDone === null) showWord = intl.formatMessage({ id: 'feedbackincompleted' })
    if (hasDone) showWord = intl.formatMessage({ id: 'feedbacksubmitted' })
    if (hasDone === false) showWord = intl.formatMessage({ id: 'notStarted' })
    return (
      <Row type="flex" justify="space-between" key={item && item.id} style={{ paddingLeft: 36 }}>
        <Col
          style={{ color: (!isAdmin && canViewDetails && type != 'nonePlaybook') ? '#1890ff' : '' }}
          onClick={(e) => {
            if (type == 'nonePlaybook') {
              return
            }
            onClickCardItem(trainingProgram.id, e, num, courseItemId)
          }}
        >
          {trainingProgram && <span>{trainingProgram.title}</span>}
          {trainingProgram && trainingProgram.difficulty && <span className={courseCardStyle.difficulty}>{trainingProgram.difficulty.replace('L', 'lv')}</span>}

          {trainingProgramDetail && <span>{trainingProgramDetail.title}</span>}
          {trainingProgramDetail && trainingProgramDetail.difficulty && <span className={courseCardStyle.difficulty}>{trainingProgramDetail.difficulty.replace('L', 'lv')}</span>}
        </Col>
        <Col>
          <p style={{ color: showWord === intl.formatMessage({ id: 'feedbackincompleted' }) ? '#F96B4B' : '#8C8C8C', whiteSpace: 'nowrap' }}>{showWord}</p>
        </Col>
      </Row >
    )
  }

  const showChildFeedBackDetail = (data) => {
    let showWord = intl.formatMessage({ id: 'Incompleted' })

    // 正常上课儿童数据
    const effectData = data.filter(item => item.attendance === 'Attend')

    if (data.length > 0) {
      if (effectData.length > 0) {
        if (effectData.every(item => item.hasDone === false)) {
          showWord = intl.formatMessage({ id: 'Incompleted' })
        } else if (effectData.every(item => item.hasDone === true)) {
          showWord = intl.formatMessage({ id: 'submittedOther' })
        }
      } else {
        showWord = intl.formatMessage({ id: 'Incompleted' })
      }
    }

    return <p style={{ color: showWord === intl.formatMessage({ id: 'Incompleted' }) ? '#F96B4B' : '#8C8C8C', whiteSpace: 'nowrap' }}>{showWord}</p>
  }

  const showCategoryFeedBackDetail = () => {
    let showWord;

    if (categoryHasDone === null) showWord = intl.formatMessage({ id: 'feedbackincompleted' })
    if (categoryHasDone) showWord = intl.formatMessage({ id: 'feedbacksubmitted' })
    if (categoryHasDone === false) showWord = intl.formatMessage({ id: 'notStarted' })

    return (
      <p
        style={{ color: showWord === intl.formatMessage({ id: 'feedbackincompleted' }) ? '#F96B4B' : '#8C8C8C', whiteSpace: 'nowrap' }}
      >
        {showWord}
      </p>
    )
  }

  const clickCourseType = (e) => {
    e.stopPropagation()
    if (onSwitchCourseType) {
      userOfflineTrainingPlan && onSwitchCourseType({ userOfflinePlanId: userOfflineTrainingPlan.id, num, isApp: !isApp })
    }
  }

  const isShowClassEnd = isCurrent && rocketProjectStatus && isBind && !isLogout && hasOfflinePlanWritePermission && !canNext && !(from === "userOfflinePlanDetail" && isCurrent)
  const isShowNextClass = isCurrent && rocketProjectStatus && isBind && !isLogout && hasOfflinePlanWritePermission && canNext && userOfflineTrainingPlan && userOfflineTrainingPlan.totalNum !== num
  // const isShowClassFeedback = hasOfflinePlanReadPermission && !(from === "userOfflinePlanDetail" && isCurrent)
  const isShowClassFeedback = hasOfflinePlanReadPermission

  const ilsVersion = userOfflineTrainingPlan.ilsVersion
  return (
    <div className={styles.courseCard}>
      <Spin spinning={endCourseLoading}>
        <Card
          title={
            <div>
              {canChange && num > 1 && <LeftOutlined
                className={styles.leftChange}
                onClick={() => {
                  setNum(num - 1)
                }} />}
              {
                (isFixed || num !== currentNum) ?
                  (
                    <span></span>
                  ) :
                  (
                    isCurrent && <Tag color="#1890FF">
                      {intl.formatMessage({ id: 'currentTime' })}
                    </Tag>
                  )
              }
              <span>{intl.formatMessage({ id: 'whichSession' }, { num: num })}</span>
              {
                isAdjustment &&
                <span className={courseCardStyle.isAdjustment}>{intl.formatMessage({ id: 'adjust' })}</span>
              }
              {
                isTry &&
                <span className={courseCardStyle.isAdjustment}>{intl.formatMessage({ id: 'trial' })}</span>
              }
              {canChange && (num < currentNum) && <RightOutlined
                className={styles.rightChange}
                onClick={() => {
                  setNum(num + 1)
                }} />}
            </div>
          }
          extra={
            <span>
              <Space split={<Divider type="vertical" />} size={2}>
                {
                  hasOfflinePlanReadPermission && !isAdmin && canViewDetails && <React.Fragment>
                    <a onClick={e => clickCard(e)}>{intl.formatMessage({ id: 'courseDetail' })}</a>
                  </React.Fragment>
                }
                {/* {
                  onAdjustLevel && (isShowClassEnd || isShowClassEnd === null) && !showNextClass &&
                  from !== 'groupOfflinePlanDetail' &&
                  num === currentNum &&
                  <React.Fragment>
                    <a onClick={e => showAdjustLevel(e)}>
                      {intl.formatMessage({ id: type === 'v2' ? 'Adjust Difficulty' : 'adjustSessionDifficulty' })}
                    </a>
                  </React.Fragment>
                } */}
                {
                  (isShowNextClass || showNextClass) &&
                  num === currentNum &&
                  !isAdmin &&
                  <React.Fragment>
                    <a
                      onClick={e => startNextCourse(e)}
                    >
                      {intl.formatMessage({ id: 'activateNextSession' })}
                    </a>
                  </React.Fragment>
                }
                {
                  // 判断引用的父组件
                  from === 'userOfflinePlanDetail' &&
                  // 判断当前
                  isCurrent &&
                  // 判断是管理员
                  isAdmin &&
                  // 判断已结束
                  coachName &&
                  <React.Fragment>
                    <a
                      style={{color: 'rgb(140, 140, 140)'}}
                    >
                      {intl.formatMessage({ id: 'activateNextSession' })}
                    </a>
                  </React.Fragment>
                }
                {
                  // 判断引用的父组件
                  from === 'userOfflinePlanDetail' &&
                  // 判断当前
                  isCurrent &&
                  // 判断是管理员
                  isAdmin &&
                  // 判断未结束
                  !coachName &&
                  <React.Fragment>
                    <a onClick={e => finishCourse(e)}>{intl.formatMessage({ id: 'classEnd' })}</a>
                  </React.Fragment>
                }
                {
                  hasOfflinePlanReadPermission &&
                  <React.Fragment>
                    <Dropdown overlay={<Menu>
                      <Menu.Item key="preview" style={{ textAlign: 'center' }}>
                        <a onClick={e => onPreviewHfsRecord(e)}>
                          {intl.formatMessage({ id: 'preview' })}
                        </a>
                      </Menu.Item>
                      <Menu.Item key="download" style={{ textAlign: 'center' }}>
                        <a onClick={e => downloadHfsRecord(e)}>
                          {intl.formatMessage({ id: 'download' })}
                        </a>
                      </Menu.Item>
                    </Menu>}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {intl.formatMessage({ id: 'sessionFeedbackForm' })} <DownOutlined />
                      </a>
                    </Dropdown>
                  </React.Fragment>
                }
                {
                  isShowClassFeedback &&
                  <React.Fragment>
                    <a onClick={e => giveFeedback(e)}>{intl.formatMessage({ id: 'sessionFeedback' })}</a>
                  </React.Fragment>
                }
                {
                  (isShowClassEnd || isShowClassEnd === null) && !showNextClass &&
                  from !== 'groupOfflinePlanDetail' &&
                  num === currentNum &&
                  !isAdmin &&
                  <React.Fragment>
                    <a onClick={e => finishCourse(e)}>{intl.formatMessage({ id: 'classEnd' })}</a>
                  </React.Fragment>
                }
              </Space>
            </span>
          }
        >
          <Spin spinning={cardLoading}>
            <div onClick={() => hasOfflinePlanReadPermission && clickCard()} style={{ cursor: (canViewDetails) ? "pointer": "default" }}>
              <div className={showTop ? styles.courseCurrentTopPosition : styles.courseCurrentPosition}>
                <div className={styles.courseCurrentPositionLeft}>
                  {
                    isFixed && isCurrent &&
                    // 两个显示位置不一样
                    <Tag color="#1890FF">
                      {intl.formatMessage({ id: 'currentTime' })}
                    </Tag>
                  }
                  {
                    isShowApp &&
                    <Tag color={isApp ? "#0DC6AA" : "#1890FF"} onClick={clickCourseType}>{intl.formatMessage({ id: isApp ? 'online' : 'offline' })}</Tag>
                  }
                  {
                    isFixed && operate &&
                    <Tag color="#FEF6ED">
                      <span style={{ color: '#FB992C' }}>{intl.formatMessage({ id: 'Difficulty' })}{operateOptions[operate]}</span>
                    </Tag>
                  }
                </div>
                <div className={styles.courseCurrentPositionRight}>
                  {/* {
                    endTime &&
                    <div style={{ marginRight: '10px' }}>{showEndTime(guardianHasDone, endTime)}</div>
                  } */}
                  {
                    coachName && <Tag style={{ marginRight: 0 }}>{`${coachName} ${intl.formatMessage({ id: 'finish' })}`}</Tag>
                  }
                </div>
              </div>
              <div style={{ textAlign: 'right' }}>
                {
                  endTime && <div>{showEndTime(guardianHasDone, endTime)}</div>
                }
              </div>
              {
                hasOfflinePlanReadPermission && type === 'v1' &&
                <div>
                  {
                    iLs && iLs.audioType == "BrainAudio" ?
                      <div
                        className={isCurrent ? courseCardStyle.iLsMusicCanPlay : courseCardStyle.iLsMusic}
                        onClick={(e) => { e.stopPropagation(); isCurrent && !canNext && canListen && onMusicPlayerDrawer(allData) }}
                      >
                        <div>
                          <img src={brainLogo} className={courseCardStyle.brainLogo} />
                          <span className={courseCardStyle.brainTitle}>{intl.formatMessage({ id: 'HFSMusic' })}</span>
                        </div>
                        {
                          isCurrent && canListen ?
                            <div className={courseCardStyle.iLsMusicRight}>
                              {isOpening &&
                                <AlignLeftOutlined
                                  style={{
                                    transform: "rotate(-90deg)",
                                    color: "#55B3B7",
                                    fontSize: "19px",
                                    marginRight: "8px",
                                  }}
                                />
                              }
                              {!canNext ?
                                <React.Fragment>
                                  <a>{intl.formatMessage({ id: 'see' })}</a>
                                  <RightOutlined className={courseCardStyle.iLsMusicRightIcon} />
                                </React.Fragment>
                                :
                                <div className={courseCardStyle.brainSubTitle}>{intl.formatMessage({ id: 'finishedCanNotPlay' })}</div>
                              }
                            </div>
                            :
                            <div className={courseCardStyle.brainSubTitle}>{isCurrent ? cannotListenReason : intl.formatMessage({ id: 'finishedCanNotPlay' })}</div>
                        }
                      </div>
                      :
                      <div className={courseCardStyle.showIlsMusic}>
                        <div>
                          <CustomerServiceOutlined style={{ color: '#0DC6AA', fontSize: 18, marginRight: 8 }} />
                          <span>{intl.formatMessage({ id: 'ilsMusic' })}</span>
                          <span>
                            {
                              tracks.map((item, index) => {
                                if (index !== tracks.length - 1)
                                  return <span key={item.code + index} className={item.isPunched ? courseCardStyle.trackPunchedColor : courseCardStyle.trackUnPunchedColor}> {item.courseCode}-{item.code}, </span>
                                else return <span key={item.code + index} className={item.isPunched ? courseCardStyle.trackPunchedColor : courseCardStyle.trackUnPunchedColor}> {item.courseCode}-{item.code}</span>
                              })
                            }
                            <span style={{ marginLeft: 5 }}>
                              {iLs && `(${intl.formatMessage({ id: 'Volume' })} ${iLs.boneConduction})`}
                            </span>
                            <span className={courseCardStyle.iLsMusicDescription}>
                              {intl.formatMessage({ id: 'volumeDes' })}
                            </span>
                          </span>
                        </div>
                        <div>
                          {
                            audioRecordPunch && userOfflineTrainingPlan && userOfflineTrainingPlan.isAudioRecommend &&
                            showPlanFeedBackDetail(audioRecordPunch)
                          }
                        </div>
                      </div>
                  }
                </div>
              }
              <div>
                {
                  planItems.length > 0 &&
                  <div className={courseCardStyle.plans}>
                    <StarOutlined style={{ color: '#0DC6AA', fontSize: 15, marginRight: 5 }} />
                    <b className={courseCardStyle.plansName}>{intl.formatMessage({ id: 'trainingActivities' })}</b>
                  </div>
                }
                {
                  planItems.map(item => {
                    return showPlanFeedBackDetail(item);
                  })
                }
              </div>
              <div>
                {
                  nonePlaybook.length > 0 &&
                  <div className={courseCardStyle.plans}>
                    <img src={activityImg} className={courseCardStyle.activityImg} />
                    <b className={courseCardStyle.plansName}>{intl.formatMessage({ id: 'staticActivities' })}</b>
                  </div>
                }
                {
                  nonePlaybook.map(item => {
                    return showPlanFeedBackDetail(item, 'nonePlaybook');
                  })
                }
              </div>
              <div>
                {
                  hasOfflinePlanReadPermission &&
                  <div>
                    <div className={courseCardStyle.plans}>
                      <LayoutOutlined style={{ color: '#0DC6AA', fontSize: 15, marginRight: 5 }} />
                      <b className={courseCardStyle.plansName}>{intl.formatMessage({ id: 'Overall Session Feedback' })}{type === 'v2' && ` / ${intl.formatMessage({ id: 'personalFeedback' })}`}</b>
                    </div>
                    <div>
                      <Row
                        type="flex"
                        justify="space-between"
                        style={{ paddingLeft: 36 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onOpenFeedback('sessionFeedback', num)
                        }}
                      >
                        <Col>
                          <span
                            style={{ color: (type === 'v2' && from !== 'groupOfflinePlanDetail') ? '#1890ff' : '', cursor: (type === 'v2' && from !== 'groupOfflinePlanDetail') && 'pointer' }}
                          >
                            {intl.formatMessage({ id: type === 'v2' ? 'Overall Session Feedback' : 'sessionFeedback' })}
                          </span>
                        </Col>
                        <Col>
                          {showPlanFeedBackDetail(numPunchOutput)}
                        </Col>
                      </Row>
                      {
                        type === 'v1' && (
                          <Row
                            type="flex"
                            justify="space-between"
                            style={{ paddingLeft: 36 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onOpenFeedback('sessionFeedback', num)
                            }}
                          >
                            <Col>
                              <span>
                                {intl.formatMessage({ id: 'Category Feedback' })}
                              </span>
                            </Col>
                            <Col>
                              {showCategoryFeedBackDetail()}
                            </Col>
                          </Row>
                        )
                      }
                      {
                        type === 'v2' && (
                          <Row
                            type="flex"
                            justify="space-between"
                            style={{ paddingLeft: 36 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              onOpenFeedback('personalFeedback', num)
                            }}
                          >
                            <Col>
                              <span style={{ color: from !== 'groupOfflinePlanDetail' && '#1890ff', cursor: from !== 'groupOfflinePlanDetail' && 'pointer' }}>{intl.formatMessage({ id: 'personalFeedback' })}</span>
                            </Col>
                            <Col>
                              {showChildFeedBackDetail(userCourseItems)}
                            </Col>
                          </Row>
                        )
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </Spin>
        </Card>
      </Spin>
    </div>
  )
}

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  CourseCard
)
