import React from 'react'
import { Card } from 'antd'
import moment from 'moment'
import { genderLocale, handLocale } from 'utils/utils'
import { getLanguageList, formatterGradeName } from 'cognitiveleap-core-us/utils/utils'
import { useIntl } from 'umi'
import { countAge } from 'utils/utils';

const SubjectInfoCard = ({ subjectInfo }) => {
    const { fullName, language, birthDay, gender, dominantHand, relationshipLocale, coachNames, city, gradeName, schoolNature, outpatientNumber } = subjectInfo
    const { firstName, lastName } = fullName || {}
    const intl = useIntl()

    return (
        <Card bordered={false} hoverable={false}>
            {outpatientNumber && <p>{`${intl.formatMessage({ id: 'clinicNumber' })}: ${outpatientNumber}`}</p>}
            {(firstName || lastName) && <p>{`${intl.formatMessage({ id: 'subjectName' })}: ${firstName || ''} ${lastName || ''}`}</p>}
            {birthDay && <p>{`${intl.formatMessage({ id: 'birthday' })}: ${moment(birthDay).format('MM/DD/YYYY')}`}</p>}
            {birthDay && <p>{`${intl.formatMessage({ id: 'age' })}: ${countAge(birthDay, intl)}`}</p>}
            {gender && <p>{`${intl.formatMessage({ id: 'GenderChoice' })}: ${genderLocale(gender, intl)}`}</p>}
            {/* {dominantHand && <p>{`${intl.formatMessage({ id: 'dominantHand' })}: ${handLocale(dominantHand, intl)}`}</p>}
            {relationshipLocale && <p>{`${intl.formatMessage({ id: 'relationship' })}: ${relationshipLocale}`}</p>} */}
            {/* {city && <p>{`${intl.formatMessage({ id: 'city' })}: ${city}`}</p>} */}
            {gradeName && <p>{`${intl.formatMessage({ id: 'grade' })}: ${formatterGradeName(gradeName)}`}</p>}
            {language && <p>{`${intl.formatMessage({ id: 'Preferred Language' })}: ${getLanguageList(language)}`}</p>}
            {/* {schoolNature && <p>{`${intl.formatMessage({ id: 'schoolNature' })}: ${formatterSchoolNature(schoolNature)}`}</p>} */}
            {coachNames && <p>{`${intl.formatMessage({ id: 'trainer' })}: ${coachNames}`}</p>}
        </Card>
    )
}

export default SubjectInfoCard
