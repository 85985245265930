import React, { useState, useEffect } from 'react';
import {
  LeftOutlined,
  RightOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { useIntl } from 'umi';
import moment from 'moment';

import styles from './index.less';

const WeekCalendar = ({ finishDays = [] }) => {
  const intl = useIntl();

  const [currentWeek, setCurrentWeek] = useState(0);
  const [renderDays, setRenderDays] = useState([]);

  // 渲染日历头部
  const renderHeader = () => {
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
    return days.map((day, index) => (
      <th
        key={index}
        style={{
          fontSize: '14px',
          color: 'rgba(0,0,0,0.45)',
          fontWeight: '400',
          width: '40px',
        }}
      >
        {intl.formatMessage({ id: day })}
      </th>
    ));
  };

  useEffect(() => {
    const rows = [];

    for (let i = 0; i <= 6; i++) {
      const date = moment().subtract(currentWeek, 'w').day(i);
      const dateFormat = date.format('MM-DD-YYYY');
      if (moment().format('MM-DD-YYYY') === dateFormat) {
        const hasFinished =
          finishDays.find(
            (item) =>
              moment(item).format('MM-DD-YYYY') ===
              moment().format('MM-DD-YYYY'),
          ) || {};
        rows.push(
          <td key={`${i}`}>
            <div
              style={
                Object.keys(hasFinished).length > 0
                  ? {
                      backgroundColor: '#55B3B7',
                      color: '#FFFFFF',
                      borderRadius: '12px',
                      height: '24px',
                      width: '24px',
                    }
                  : {
                      border: '1px solid #55B3B7',
                      borderRadius: '12px',
                      height: '24px',
                      width: '24px',
                    }
              }
            >
              {moment().format('D')}
            </div>
          </td>,
        );
      } else {
        const hasFinished =
          finishDays.find(
            (item) => moment(item).format('MM-DD-YYYY') === dateFormat,
          ) || {};
        rows.push(
          <td key={`${i}`}>
            <div
              style={
                Object.keys(hasFinished).length > 0
                  ? {
                      color: '#55B3B7',
                      borderRadius: '12px',
                      height: '24px',
                      width: '24px',
                      backgroundColor: '#def0f1',
                    }
                  : {}
              }
            >
              {date.format('D')}
            </div>
          </td>,
        );
      }
    }

    setRenderDays(rows);
  }, [currentWeek]);

  const handlePrev = (e) => {
    e.stopPropagation();
    // 允许查看最近三个月的数据
    if (currentWeek > 12) return;
    setCurrentWeek(currentWeek + 1);
  };

  const handleNext = (e) => {
    e.stopPropagation();
    if (currentWeek === 0) return;
    setCurrentWeek(currentWeek - 1);
  };

  return (
    <div className={styles.WeekCalendar}>
      <Space className={styles.calendarTitle}>
        <CaretLeftOutlined onClick={handlePrev} className={styles.changeIcon} />
        {moment().subtract(currentWeek, 'w').format('MM-DD-YYYY')}
        <CaretRightOutlined
          onClick={handleNext}
          className={styles.changeIcon}
        />
      </Space>
      <table cellPadding="6">
        <thead>
          <tr>
            <th></th>
            {renderHeader()}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <td>
            <LeftOutlined
              style={{ color: 'rgba(0,0,0,0.45)' }}
              onClick={handlePrev}
            />
          </td>
          {renderDays}
          <td>
            <RightOutlined
              style={{ color: 'rgba(0,0,0,0.45)' }}
              onClick={handleNext}
            />
          </td>
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(WeekCalendar);
