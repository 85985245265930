
import moment from 'moment'
import { formatConfig } from '../../config';
import { useIntl } from 'umi';
import { GetVRATTotalData, GetVRATAnalysis } from 'services/rocketService/Analysis' 

export default {
  namespace: 'vratAnalysis',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    analysisData: null,
    keyIndicators: null  
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    }
  },
  effects: {
    *loadPage({ payload: data }, { call, put, select }) {
      const res = yield call(GetVRATTotalData);
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            keyIndicators: res.data,
          }
        })
      }
    },

    *loadAnalysisData(_, { call, put, select }) {
      const { currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.vratAnalysis);
      const date = {
        "Year": dateYearPickerData,
        "Month": dateMonthPickerData
      }[currentTag];

      const res = yield call(GetVRATAnalysis, {
        Type: currentTag,
        Year: parseInt(moment(date).format('YYYY')),
        Month: parseInt(moment(dateMonthPickerData).format('MM'))
      });

      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            analysisData: res.data
          }
        })
      }
    },

    *handlePanelChange({ payload: { time } }, { call, put, select }) {
      const { datePickerIsOpen, currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.vratAnalysis);
      yield put({
        type: 'updateState',
        payload: {
            dateMonthPickerData: {
              "Year": dateMonthPickerData,
              "Month": moment(time)
            }[currentTag],
            dateYearPickerData: {
              "Year": moment(time),
              "Month": dateYearPickerData
            }[currentTag],
            datePickerIsOpen: !datePickerIsOpen
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *changeTag({ payload: { key } }, { call, put, select }){
      yield put({
        type: 'updateState',
        payload: {
            currentTag: key
        }
      })
      yield put({ type: 'loadAnalysisData' });
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/analysis/vrat') {
    //       dispatch({ type: 'loadPage' })
    //       dispatch({ type: 'loadAnalysisData' })
    //     }
    //   })
    // },
  },
}
