import React, { useRef } from 'react';
import { Input, Space } from 'antd';
import { useIntl } from 'umi';

const CustomName = ({ value, onChange, disabled }) => {
  const intl = useIntl();

  const { lastName = '', firstName = '' } = value || {};

  const userNameRef = useRef({
    lastName,
    firstName,
  });

  const onNameChange = (e, type) => {
    userNameRef.current = {
      ...userNameRef.current,
      [type]: e.target.value,
    };

    if (Object.values(userNameRef.current || {}).some((item) => item)) {
      onChange(userNameRef.current);
    } else {
      onChange(null)
    }
  };

  const inputStyle = {
    width: '150px',
  };

  return (
    <Space>
      <Input
        disabled={disabled}
        style={inputStyle}
        type="text"
        value={userNameRef.current.firstName}
        placeholder={intl.formatMessage({ id: 'First Name' })}
        onChange={(e) => onNameChange(e, 'firstName')}
      />
      <Input
        disabled={disabled}
        style={inputStyle}
        type="text"
        value={userNameRef.current.lastName}
        placeholder={intl.formatMessage({ id: 'Last Name' })}
        onChange={(e) => onNameChange(e, 'lastName')}
      />
    </Space>
  );
};

export default CustomName;
