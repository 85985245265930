import React, { useRef, useState } from 'react';
import { connect, useIntl } from 'umi';
import { Card, Tabs, Button } from 'antd';
import Comments from './Comments';
import Gamification from './Gamification';
import Message from './Message';

const { TabPane } = Tabs;

const ContactUS = ({ currentUser }) => {
  const intl = useIntl();
  const childRef = useRef(null);

  const [exportLoading, setExportLoading] = useState(false);
  const [activeKey, setActiveKey] = useState('1');

  const { adminRoles, current } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const childProps = {
    isAdmin,
    setExportLoading,
  };

  const operations = (
    <Button
      loading={exportLoading}
      type="primary"
      onClick={() => childRef.current.exportData()}
    >
      {intl.formatMessage({ id: 'exportList' })}
    </Button>
  );

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {isAdmin ? '建议审核' : intl.formatMessage({ id: 'Contact Us' })}
        </b>
      }
      bordered={false}
    >
      <Tabs
        activeKey={activeKey}
        destroyInactiveTabPane
        tabBarExtraContent={isAdmin && activeKey != 3 && operations}
        onChange={(key) => setActiveKey(key)}
      >
        <TabPane tab={intl.formatMessage({ id: 'gameComments' })} key="1">
          <Comments {...childProps} onRef={childRef} />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'gameGamification' })} key="2">
          <Gamification {...childProps} onRef={childRef} />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'Contact Us' })} key="3">
          <Message {...childProps} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(ContactUS);
