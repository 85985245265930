import { useIntl } from 'umi';
import { Tag } from 'antd';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';

const AssignCoachModal = ({
  loading,
  coaches,
  visible,
  onCancel,
  onOk,
  targetKeys,
}) => {
  const intl = useIntl();

  const options = coaches.map((item) => {
    const { id, name, email, isActive, state, roles } = item || {};
    const { displayName } = roles[0] || {};

    const showName = name || intl.formatMessage({ id: 'No Name' });

    if (!isActive) {
      return {
        Value: showName + ' - ' + displayName + ' - ' + email,
        Id: id,
        ShowLabel: showName,
        DisableToolTip: intl.formatMessage({ id: 'AccountNotActivated' }),
      };
    }
    if (state !== 'Effective') {
      return {
        Value: showName + ' - ' + displayName + ' - ' + email,
        Id: id,
        ShowLabel: showName,
        DisableToolTip: intl.formatMessage({ id: 'Invalid Account' }),
      };
    }
    return {
      Value: showName + ' - ' + displayName + ' - ' + email,
      Id: id,
      ShowLabel: showName,
    };
  });

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={onClose}
        style={{
          marginRight: 5,
          color: closable ? '#000000' : '#b8b8b8',
        }}
      >
        {label}
      </Tag>
    );
  };

  const contentMenu = {
    Properties: [
      {
        EditorType: 'MultipleSingleWithSearch',
        ShowTitle: intl.formatMessage({ id: 'trainer' }),
        FormKey: 'coachUserIds',
        AdditionalData: null,
        Value: targetKeys,
        Setting: {
          Required: false,
          DropdownOptions: options,
          Props: {
            defaultValue: targetKeys,
            tagRender
          },
        },
        Description: '',
      },
    ],
  };

  const modalProps = {
    content: contentMenu,
    title: intl.formatMessage({ id: 'assignTrainers' }),
    visible,
    onCancel,
    onOk,
    loading,
  };

  return <MagicFormModal {...modalProps} />;
};

export default AssignCoachModal;
