import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'
import { propertyTypes } from 'utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
  PrepareForEdit,
} from 'services/rocketService/Prepare'

const contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '属性名（英文）',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '显示名称',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '描述',
      FormKey: 'description',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      Value: null,
      Setting: {
        DropdownOptions: propertyTypes,
        Required: false,
      },
      ShowTitle: '数据类型',
      FormKey: 'type',
      Description: '',
    },
    {
      EditorType: 'Input',
      ShowTitle: '反馈备注',
      FormKey: 'extra',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: '此处编辑有难度，如需修改请联系Alan',
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否默认必填',
      FormKey: 'isRequired',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: '是否默认带上',
      FormKey: 'isDefault',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
}

const listSupport = {
  displayName: {
    showText: '显示名称',
    showType: 'Text',
  },
  name: {
    showText: '属性名（英文）',
    showType: 'Text',
  },
  type: {
    showText: '数据类型',
    showType: 'Text',
  },
}

const listToOptions = (list, show) => {
  return list.map(item => {
    return { Value: item[show], Id: item[show] }
  })
}

// const showDescription = availableTypes => {
//   let showWords = ''
//   availableTypes.forEach(typeItem => {
//     const { constraints } = typeItem
//     let showAvailableConstraints = ''
//     constraints.forEach(item => {
//       showAvailableConstraints += item.name + ';'
//     })
//     showWords += 'Type: ' + typeItem.name + ' AvailableKeys: ' + showAvailableConstraints + '\r'
//   })
//   return showWords
// }

export default {
  namespace: 'feedbackProperty',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    availableTypes: [],
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    searchInput: null,
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination, searchInput } = yield select(state => state.feedbackProperty)
      let query = { skipCount: 0, maxResultCount: 10, filter: searchInput }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'feedback')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination: originPage } = yield select(state => state.feedbackProperty)
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination: { ...originPage, ...pagination } } })
      yield put({ type: 'query' })
    },

    *clearAllFilterAndSorter(_, { call, put, select }) {
      const defaultPagination = {
        current: 1,
        pageSize: 12,
        total: 0,
      }
      const payload = { pagination: defaultPagination, searchInput: '' }
      yield put({ type: 'updateState', payload: payload })
      yield put({ type: 'query' })
    },


    *onEdit({ payload: id }, { call, put, select }) {
      const res = yield call(PrepareForEdit, { id }, 'feedback')
      if (res.response.ok) {
        const { feedbackProperty, } = res.data
        const { extraProperties } = feedbackProperty
        const extra = JSON.stringify(extraProperties)
        const editUserInfoForm = matchDynamicForm(contentMenu, { ...feedbackProperty, extra })
        yield put({
          type: 'updateState',
          payload: {
            content: editUserInfoForm,
            modalType: 'edit',
            modalVisible: true,
            selectItem: feedbackProperty,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'feedback')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    *onAdd(_, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: contentMenu, modalVisible: true, },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.feedbackProperty)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'feedback')
      } else {
        res = yield call(PostPrepareData, data, 'feedback')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/programPieces/feedbackProperty') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
