
import { GetChildrenTotalData, GetChildrenAnalysis, GetILSTotalData } from 'services/rocketService/Analysis'
import { formatConfig } from '../../config';
import moment from 'moment'

export default {
  namespace: 'childrenAnalysis',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    analysisData: null,
    keyIndicators: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {

    *loadKeyIndicators(_, { call, put, select }) {
      const resList = yield [call(GetChildrenTotalData), call(GetILSTotalData)];

      if (resList.every(item => item.response.ok)) {
        const firstRes = resList[0].data
        const twoRes = resList[1].data
        const { enabledOfflineCourseSubjectTotal } = twoRes
        const tempData = {
          ...firstRes,
          enabledOfflineCourseSubjectTotal
        }
        yield put({
          type: 'updateState',
          payload: {
            keyIndicators: tempData,
          }
        })
      }
    },

    *loadAnalysisData(_, { call, put, select }) {
      const { currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.childrenAnalysis);
      const date = {
        "Year": dateYearPickerData,
        "Month": dateMonthPickerData
      }[currentTag];

      const res = yield call(GetChildrenAnalysis, {
        Type: currentTag,
        Year: parseInt(moment(date).format('YYYY')),
        Month: parseInt(moment(dateMonthPickerData).format('MM'))
      });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            analysisData: res.data
          }
        })
      }
    },

    *handlePanelChange({ payload: { time } }, { call, put, select }) {
      const { datePickerIsOpen, currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.childrenAnalysis);
      yield put({
        type: 'updateState',
        payload: {
          dateMonthPickerData: {
            "Year": dateMonthPickerData,
            "Month": moment(time)
          }[currentTag],
          dateYearPickerData: {
            "Year": moment(time),
            "Month": dateYearPickerData
          }[currentTag],
          datePickerIsOpen: !datePickerIsOpen
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *changeTag({ payload: { key } }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          currentTag: key
        }
      })
      yield put({ type: 'loadAnalysisData' });
    }
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/analysis/children') {
    //       dispatch({ type: 'loadKeyIndicators' })
    //       dispatch({ type: 'loadAnalysisData' }) 
    //     }
    //   })
    // },
  },
}
