import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  message,
  Divider,
  Dropdown,
  Button,
  Menu,
  Drawer,
  Modal,
  Empty,
  Spin,
  Card,
  Radio,
  Checkbox,
  Form,
  InputNumber,
  Select,
} from 'antd';
import {
  UpOutlined,
  DownOutlined,
  AppstoreOutlined,
  MoreOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { history, connect, useIntl, getLocale } from 'umi';
import moment from 'moment';
// import InfiniteScroll from 'react-infinite-scroll-component';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import Question from 'components/Question';
import CourseCard from 'components/CourseCard';
import EndPlanModal from 'components/EndPlanModal';
import CheckEndPlanModal from 'components/CheckEndPlanModal';

import {
  PrefixZero,
  convertDuration,
  getSeconds,
  isParentalInterviewsNeed,
  getDurationTime,
  getGroupTag,
  getUserImg,
  showUserGroupContractMsg,
  getVersionReportUrl
} from 'utils/utils';
import { cleanObject, showErrorMsg, eventDebounce, deepCopy } from 'cognitiveleap-core-us/utils/utils';
import chartRequest from 'cognitiveleap-core-us/utils/chartRequest.js';
import GenerateMusic from 'components/GenerateMusic';
import StartClassModal from 'components/ClassGroupModal/StartClassModal';
import AddOrEditClassModal from 'components/ClassGroupModal/AddOrEditClassModal';
import ConnectTrainPlan from 'components/ClassGroupModal/ConnectTrainPlan';
import ManageChildModal from 'components/ClassGroupModal/ManageChildModal';
import ChartsDrawer from 'components/ClassGroupModal/ChartsDrawer';
import DownLoadModal from 'components/DownLoadModal';
import AdjustLevelModal from 'components/AdjustLevelModal';
import RecordsTabs from 'components/RecordsTabs';
import FeedBack from './components/feedback';
import ShowItem from 'components/ShowVideoItem';
import useTimer from './components/timer';
import { GetDownloadPdf } from 'services/puppeteer';

import config from 'utils/config';
const { baseURL } = config;
const { Option } = Select;

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth';

import styles from './index.less';

import brainLogo from 'assets/brainLogo.png';
import playing from 'assets/playing.png';

import {
  GetDetailGroup,
  BeforeStartClass,
  GetPlanCourseByNum,
  PostEndCourse,
  PutAttendance,
  GetPlanList,
  CheckState,
  PostEndTrain,
  StartCourse,
  EndClass,
  ContinueClass,
  StartClass,
  PostChangeClassStatus,
  GetCheckMusciStatus,
  GetChildStatus,
  GetParentalInterviewsNeed,
  GetChildList,
  GetChildListDetail,
  PostRestart,
} from 'services/group';
import {
  PostGroupAdjustProgramLevel
} from 'services/rocketService/UserOfflinePlan'
import {
  GetDiagnosticInformationList,
  GetChiefComplaintList
} from 'services/trainingSystem';

const getStage = (stage, intl) => {
  if (stage === 'PreSchool') {
    return intl.formatMessage({ id: 'preschool' });
  } else if (stage === 'GradeSchool') {
    return intl.formatMessage({ id: 'gradeSchool' });
  } else if (stage === 'TeenSchool') {
    return intl.formatMessage({ id: 'teenage' });
  } else if (stage === 'GradeOrTeenSchool') {
    return intl.formatMessage({ id: 'grade_teenage' });
  } else if (stage === 'GoldenAge') {
    return intl.formatMessage({ id: 'GoldenAge' });
  }
  return intl.formatMessage({ id: 'other' });
};

const listSupport = (intl) => {
  return {
    displayName: {
      showText: intl.formatMessage({ id: 'trainingPlanName' }),
      showType: 'Text',
    },
    currentNum: {
      showText: intl.formatMessage({ id: 'progress' }),
      showType: 'Text',
    },
    startTime: {
      showText: intl.formatMessage({ id: 'activationTime' }),
      showType: 'Time',
    },
    endTime: {
      showText: intl.formatMessage({ id: 'endTime' }),
      showType: 'Time',
    },
  };
}

const getPlatform = (type, intl) => {
  switch (type) {
    case intl.formatMessage({ id: 'Online' }):
      return 'App';
    case intl.formatMessage({ id: 'Offline' }):
      return 'Web';
    default:
      return null;
  }
};

const GroupClassDetail = ({ location, currentUser, dispatch, musicplayer }) => {
  const intl = useIntl();

  const [downform] = Form.useForm();
  const [previewform] = Form.useForm();

  const { isOpen: musicIsOpen, subjectInfo: musicPlayChildInfo } = musicplayer;

  const { classId } = location.query;

  const {
    auth: { grantedPolicies },
    changedTenant: { institutionType },
    staff,
  } = currentUser;
  const hasOfflinePlanWritePermission =
    grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true;
  const hasOfflinePlanReadPermission =
    grantedPolicies['RocketSystem.UserOfflinePlan'];
  const hasReadUserQuestionnairePermission = grantedPolicies['RocketSystem.UserQuestionnaire'] === true

  const currentChild = useRef(null);
  const currentSelectChild = useRef(null);
  const currentState = useRef(null);
  const currentClassDetail = useRef([]); // 遍历刷新状态使用
  const currentTrainPlan = useRef(null);
  const funTag = useRef(0);
  const durationRef = useRef();

  const [open, setOpen] = useState(true);
  const [classDetail, setClassDetail] = useState({});
  const [planList, setPlanList] = useState([]);
  const [trainPlan, setTrainPlan] = useState({});
  const [isFininshed, setIsFininshed] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [questionData, setQuestionData] = useState({});
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); // 展开行
  const [getCurrentNum, setGetCurrentNum] = useState(1);
  const [childStateNum, setChildStateNum] = useState(null); // 子组件的课时
  // const [childClassData, setChildClassData] = useState([]); // 结束课时的用户信息
  const [programId, setProgramId] = useState(null); // 传给ShowItem 的id
  const [courseItemId, setCourseItemId] = useState(null);
  const [changeChildData, setChangeChildData] = useState([]); // 切换上课状态数据
  const [childClassList, setChildClassList] = useState([]); // 开启课时数据
  const [beforeStartCourseData, setBeforeStartCourseData] = useState([]); // 开启结束前状态
  const [durationTime, setDurationTime] = useState(0);
  const [defaultFeedbackTab, setDefaultFeedbackTab] = useState('sessionFeedback');
  const [percent, setPercent] = useState(0);
  // 批量下载pdf
  const [downInfo, setDownInfo] = useState({
    startPage: 1,
    endPage: 0,
  });
  const [visible, setVisible] = useState({
    classModal: false,
    drawerVisible: false,
    openClassModal: false,
    changeClassModal: false,
    // endClassModal: false,
    feedbackVisible: false,
    maskVisible: false,
    generateMusicVisible: false,
    manageChildVisible: false,
    trainModalVisible: false,
    chartDrawerVisible: false,
    downLoadModalVisible: false,
    adjustLevelVisible: false,
    endPlanModalVisible: false, // 结束计划
    checkEndPlanModalVisible: false, // 结束计划确认
  });
  const [downPdfModal, setDownPdfModal] = useState(false);
  const [allowReloadTab, setAllowReloadTab] = useState(false); // 是否更新Tab页面

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [loading, setLoading] = useState({
    detailLoading: false,
    endClassLoading: false,
    classModalLoading: false,
    changeStatusLoading: false,
    endCourseContentLoading: false,
    submitAdjustLevelLoading: false
  });
  const [endCourseLoading, setEndCourseLoading] = useState(false)
  const [cardLoading, setCardLoading] = useState(false); // 子组件课程详情的loading

  const groupTrainingPlans =
    (planList && planList.find((item) => item.status === 'Started')) || null;
  const {
    trainingPlanV2,
    creationTime,
    status,
    id: trainingPlanV2Id,
    hasClassRecord,
  } = groupTrainingPlans || {};
  const { displayName: trainPlanName } = trainingPlanV2 || {};
  const { groupTrainingPlanCourse, groupTrainingPlan } = trainPlan;
  const isCurrentClass =
    (groupTrainingPlanCourse && getCurrentNum <= groupTrainingPlanCourse.num) ||
    null; //是否是最新的课时
  const {
    endClassTime,
    startClassTime,
    startTime,
    endTime,
    lastStartClassTime,
    duration,
    courseItems = [],
    nonePlaybook = [],
    num: groupTrainingPlanCourseNum,
  } = groupTrainingPlanCourse || {};
  const { groupLinkUsers = [], groupLinkTrainers = [] } = classDetail;

  const hasStartClass = Object.keys(groupTrainingPlanCourse || {}).length > 0

  // 倒计时状态只显示当前课时不随切换课时改变
  const currentTime = useTimer(
    isCurrentClass,
    startClassTime,
    lastStartClassTime,
    endClassTime,
    duration,
  ); // 课时计时器

  // 匹配儿童插入相应的状态
  const matchValue = (groupLinkUsers = [], insertData = []) => {
    let tempGroupLinkUsers = [];
    Object.keys(insertData).forEach((key) => {
      const data = groupLinkUsers.find(
        (item) => item.id === insertData[key].userId,
      );
      tempGroupLinkUsers.push({
        ...data,
        ...insertData[key],
      });
    });
    return tempGroupLinkUsers;
  };

  const getClassDetail = async () => {
    setLoading({
      ...loading,
      detailLoading: true,
    });
    const resList = await Promise.all([
      GetDetailGroup({ id: classId }),
      GetPlanList({
        GroupId: classId,
        Status: ['Ended', 'Started'],
        SkipCount: (pagination.current - 1) * pagination.pageSize,
        MaxResultCount: pagination.pageSize,
      }),
      GetChildStatus({ GroupId: classId }),
    ]);
    if (resList.every((item) => item.response.ok)) {
      const firstApiData = resList[0].data;
      const twoApiData = resList[1].data;
      const threeApiData = resList[2].data;

      const { groupLinkUsers } = firstApiData || {};
      currentClassDetail.current = firstApiData;
      setClassDetail({
        ...firstApiData,
        groupLinkUsers: matchValue(groupLinkUsers, threeApiData),
      });

      setPlanList(twoApiData.items);

      // 正在进行的训练计划
      const activePlan = twoApiData.items.find(
        (plan) => plan.status === 'Started',
      );
      if (activePlan) {
        setCardLoading(true);
        // 有关联训练计划
        const { currentNum } = activePlan;
        setGetCurrentNum(() => currentNum);
        const res = await GetPlanCourseByNum({
          GroupId: classId,
          CourseNum: childStateNum || currentNum,
        });
        if (res.response.ok) {
          setTrainPlan(res.data);
        } else {
          showErrorMsg(res, intl);
        }
        setCardLoading(false);
      } else {
        setTrainPlan({});
      }

      setPagination({
        ...pagination,
        total:
          twoApiData.items.filter((item) => item.status === 'Ended').length ||
          0,
      });
    }
    setLoading({
      ...loading,
      detailLoading: false,
    });
  };

  const reportDataFetch = async (url = '', data = {}) => {
    const zone = moment().utcOffset() / 60;
    const zoneStr = `${zone > 0 ? '+' : '-'}${zone}:00`;

    const res = await fetch(url, {
      method: 'POST',
      keepalive: true,
      credentials: 'include',
      headers: {
        ...getAuthHeader(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-TimeZone-Offset': zoneStr,
      },
      body: JSON.stringify(data),
    });
    return res.json();
  };

  // 音乐播放时页面刷新或者关闭，调用音乐Stop的API
  window.onunload = (e) => {
    const { groupLinkUsers } = classDetail
    const isPause = groupLinkUsers.some(user => user.audioOperate === 'Pause');
    if (musicIsOpen || isPause) {
      const {
        planInfo,
        subjectInfo: { id: userId },
        currentIndex,
        currentTime,
      } = musicplayer;
      const {
        id: groupTrainingPlanId,
        num,
        iLs: { tracks },
      } = planInfo || {};

      const trackCode =
        tracks && tracks.length > 0 && tracks[currentIndex].code;
      const data = {
        userId,
        trackCode,
        progress: convertDuration(currentTime),
        groupTrainingPlanId,
        num,
        audioOperate: 'Stop',
        platform: 'Web',
      };

      const stopUrl = baseURL + '/api/rocketAdmin/groupTrainingPlan/stopAudioTrack';

      reportDataFetch(stopUrl, data);
    }
  };

  // 更新儿童的状态
  const updateStatus = async () => {
    const res = await GetChildStatus({ GroupId: classId });
    const { groupLinkUsers = [] } = currentClassDetail.current;
    if (res?.response?.ok && groupLinkUsers.length > 0) {
      const result = res.data;
      setClassDetail({
        ...currentClassDetail.current,
        groupLinkUsers: matchValue(groupLinkUsers, result),
      });
    }
  };

  useEffect(() => {
    getClassDetail();

    // 十秒轮询更新上课状态;
    currentState.current = setInterval(async () => {
      updateStatus();
    }, 6000);

    return () => {
      if (currentState.current) clearInterval(currentState.current);
    };
  }, []);

  // 修改班级props
  const editClassModalProps = {
    type: 'edit',
    visible: visible.classModal,
    editData: classDetail,
    closeModal: () => {
      setVisible({
        classModal: false,
      });
    },
    reloadPage: getClassDetail,
  };

  const menu = (
    <Menu>
      <Menu.Item key="edit">
        <div
          style={{ width: '80px', textAlign: 'center' }}
          onClick={() => {
            setVisible({
              classModal: true,
            });
          }}
        >
          {intl.formatMessage({ id: 'edit' })}
        </div>
      </Menu.Item>
      {/* <Menu.Item key="delete">
        <div style={{ width: '80px', textAlign: 'center' }}>停用</div>
      </Menu.Item> */}
    </Menu>
  );

  // 跳转训练总览
  const goTrainList = (id, displayName = trainPlanName) => {
    history.push({
      pathname: '/system/center/detail/groupOfflinePlanDetail',
      query: {
        id,
        displayName: encodeURIComponent(displayName),
        className: encodeURIComponent(classDetail?.name),
        groupId: classId,
      },
    });
  };

  const expandOptions = (record, index, indent, expanded) => {
    const recordsProps = {
      activePlan: record,
      from: 'group',
    }

    return (
      <RecordsTabs {...recordsProps}/>
    );
  };

  const tableProps = {
    listData: planList
      .filter((item) => item.status === 'Ended')
      .map((item) => {
        const { displayName } = item.trainingPlanV2;
        return {
          ...item,
          displayName,
        };
      }),
    listSupport: listSupport(intl),
    pagination,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: expandOptions,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'batchDownloadForm' }),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                const { currentNum } = record;
                currentTrainPlan.current = record;
                batchPdf(currentNum);
              }}
            >
              {intl.formatMessage({ id: 'batchDownloadForm' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'chartsAndFeedbackRecords' }),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                setVisible({
                  ...visible,
                  chartDrawerVisible: true,
                });
                currentTrainPlan.current = record;
              }}
            >
              {intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'planDetail' }),
        itemRender(record) {
          return (
            <a
              onClick={() => {
                const {
                  id,
                  trainingPlanV2: { displayName },
                } = record;
                goTrainList(id, displayName);
              }}
            >
              {intl.formatMessage({ id: 'planDetail' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'startPlan' }),
        itemRender(record) {
          const { status, id } = record || {};
          return (
            status === 'Ended' && (
              <a
                key="startPlan"
                onClick={async (e) => {
                  e.stopPropagation();
                  if (
                    await showUserGroupContractMsg(
                      intl,
                      { id },
                      currentUser,
                      () => {
                        history.push({
                          pathname: '/system/center/menu/manage/userContractConfiguration'
                        });
                      },
                    )
                  ) {
                    return;
                  }
                  PostRestart({ groupTrainingPlanId: id })
                    .then((res) => {
                      const { ok } = res.response || {}
                      const { error } = res.data || {}
                      if(ok) {
                        message.success(intl.formatMessage({ id: 'successStart' }));
                        getClassDetail();
                      } else {
                        message.error(error.message);
                      }
                    })
                }}
              >
                {intl.formatMessage({ id: 'startPlan' })}
              </a>
            )
          );
        },
      },
    ],
  };

  // 家长访谈
  const submitQuestion = async (payload) => {
    const { id } = questionData || {};

    const { answerContent, isComplete, finishedPage } = payload || {};
    const params = {
      userQuestionnaireId: id,
      answerContent,
      isComplete,
      finishedPage,
    };
    const submitQuestionRes = await chartRequest()(
      '/api/rocketAdmin/userQuestionnaire/questionnaire',
      {
        method: 'PUT',
        data: params,
      },
    );
    if (submitQuestionRes.response?.ok) {
      if (isComplete) {
        message.success(intl.formatMessage({ id: 'success' }));
      }
      setSubmitData(null);
      setVisible({ drawerVisible: false });
      setIsFininshed(false);
    }
  };

  const onValueChange = ({
    answerContent,
    isComplete,
    finishedPage,
    needSubmit,
  }) => {
    setSubmitData({ answerContent, isComplete, finishedPage });
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage });
    }
  };

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'submitDes' })}</div>,
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          setIsFininshed(true);
          submitQuestion({ answerContent, isComplete, finishedPage });
        },
      });
    } else {
      submitQuestion({ answerContent, isComplete, finishedPage });
    }
  };

  // 家长访谈关闭
  const onClose = () => {
    if (!isFininshed && submitData) {
      onComplete(submitData);
    }
    setVisible({ drawerVisible: false });
  };

  // 反馈props
  const drawProps = {
    GroupTrainingPlanId: trainingPlanV2Id,
    CourseNum: childStateNum || getCurrentNum,
    drawVisible: visible.feedbackVisible,
    defaultTab: defaultFeedbackTab,
    isStuding: startClassTime && !endClassTime, // 是否开始上课但没结束
    guardianSubmit: () => checkMusic('class'),
    onClose() {
      getClassDetail();
      setChildStateNum(null);
      setVisible({
        ...visible,
        feedbackVisible: false,
      });
    },
  };

  const chartDrawerProps = {
    visible: visible.chartDrawerVisible,
    currentNum: currentTrainPlan?.current?.currentNum ?? getCurrentNum,
    onClose: () => {
      setVisible({
        ...visible,
        chartDrawerVisible: false,
      });
      currentTrainPlan.current = null;
    },
    currentTablePlan:
      (currentTrainPlan.current && currentTrainPlan.current.trainingPlanV2) ||
      trainingPlanV2,
    GroupTrainingPlanId:
      (currentTrainPlan.current && currentTrainPlan.current.id) ||
      trainingPlanV2Id,
  };

  // 单个训练项目props
  const showItemProps = {
    visible: visible.maskVisible,
    programId,
    courseItemId,
    from: 'groupOfflinePlanDetail',
    onClose() {
      setProgramId(null);
      setVisible({
        ...visible,
        maskVisible: false,
      });
    },
  };

  const questionProps = () => {
    const { questionnaireAndAnswer: questionnaireAndAnswer2 } = questionData;
    const { questionnaire: questionnaire2, answer: answer2 } =
      questionnaireAndAnswer2 || {};
    const { jsonString: jsonString2 } = questionnaire2 || {};
    const { answerContent: answerContent2 } = submitData || {};
    return {
      content: jsonString2 ? jsonString2 : '',
      answerContent: answerContent2 || '',
      answer: answer2,
      onComplete: onComplete,
      onValueChange: onValueChange,
    };
  };

  const onTerminateThePlanClick = (e) => {
    e.stopPropagation()
    if (!hasClassRecord) {
      setVisible({
        ...visible,
        endPlanModalVisible: true,
      })
    } else {
      setVisible({
        ...visible,
        checkEndPlanModalVisible: true,
      })
    }
  }

  const onEndPlan = async (values) => {
    const {
      groupTrainingPlan: { id },
    } = trainPlan;

    const res = await PostEndTrain({
      groupTrainingPlanId: id,
      ...values,
    });
    if (res.response.ok) {
      getClassDetail();
      setVisible({
        ...visible,
        endPlanModalVisible: false,
      })
    } else {
      showErrorMsg(res, intl);
    }
  };

  // 开始上课props
  const startClassProps = {
    allData: groupTrainingPlans,
    openClassModal: visible.openClassModal,
    modalContentLoding: loading.classModalLoading,
    childClassList,
    onOk: async () => {
      const { id: groupTrainingPlanId, currentNum: courseNum } =
        groupTrainingPlans;

      const tempData = childClassList
        .filter((item) => item.platform !== '')
        .map((item) => {
          const { userId, platform } = item;
          if (platform === 'Leave') {
            return {
              userId,
            };
          } else {
            return {
              userId,
              platform,
            };
          }
        });

      const params = {
        groupTrainingPlanId,
        courseNum: courseNum,
        courseWay: tempData,
      };

      if (tempData.length > 0) {
        // 有数据
        const res = await StartClass(params);

        if (res.response.ok) {
          getClassDetail();
        } else {
          showErrorMsg(res, intl);
        }
      }

      setVisible({
        openClassModal: false,
      });
      setChildClassList([]);
    },
    closeModal: () => {
      setVisible({
        openClassModal: false,
      });
      setChildClassList([]);
    },
    onRadioChange: (data) => {
      setChildClassList(data);
    },
  };

  // 切换上课状态props
  const changeClassProps = {
    type: 'change',
    allData: groupTrainingPlans,
    openClassModal: visible.changeClassModal,
    modalContentLoding: loading.changeStatusLoading,
    childClassList: changeChildData,
    onOk: async () => {
      const { userName, music, id } = changeChildData[0];
      const { music: oldMusic } = groupLinkUsers.find((item) => item.id === id);

      Modal.confirm({
        title: intl.formatMessage({ id: 'changeChildMusic' }, { name: userName, oldStatus: oldMusic, newStatus: music }),
        icon: <ExclamationCircleOutlined />,
        content: null,
        okText: intl.formatMessage({ id: 'yes' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: async () => {
          const { id: groupTrainingPlanId, currentNum: courseNum } =
            groupTrainingPlans;

          const params = {
            userId: id,
            groupTrainingPlanId,
            num: courseNum,
            platform: getPlatform(music, intl),
          };

          const res = await PostChangeClassStatus(cleanObject(params));

          if (res.response.ok) {
            message.success(intl.formatMessage({ id: 'success' }));
            getClassDetail();
          } else {
            showErrorMsg(res, intl);
          }

          setVisible({
            ...visible,
            changeClassModal: false,
          });
        },
      });
    },
    closeModal: () => {
      setVisible({
        ...visible,
        changeClassModal: false,
      });
      setChangeChildData([]);
    },
    onRadioChange: (data) => {
      setChangeChildData(data);
    },
  };

  // 管理儿童props
  const manageProps = {
    visible: visible.manageChildVisible,
    currentRecord: classDetail,
    institutionType,
    reloadPage: getClassDetail,
    closeModal: () => {
      setVisible({
        ...visible,
        manageChildVisible: false,
      });
    },
  };

  // 课程进度提醒
  const isShowInfo = (listenNotEnough, type, percent) => {
    if (listenNotEnough.length > 0) {
      Modal.confirm({
        title: (
          <div>
            {listenNotEnough.map((item, index) => {
              return item + (index !== listenNotEnough.length - 1 ? '，' : '');
            })}
            {intl.formatMessage({ id: 'HFSMusicListening' })}{percent}
            %。
            {intl.formatMessage({ id: type === 'courses'
              ? 'musicCannotSureEnd'
              : 'AppContinueListen'})}
          </div>
        ),
        icon: <ExclamationCircleOutlined />,
        content: null,
        okText: intl.formatMessage({ id: 'finishClass' }),
        cancelText: type === 'courses' ? intl.formatMessage({ id: 'cancel' }) : null,
        onOk: () => {
          type === 'courses' ? endCourse() : endClassFun();
        },
      });
    } else {
      type === 'courses' ? endCourse() : endClassFun();
    }
  };

  const checkMusic = async (type) => {
    // if (
    //   !groupLinkUsers.every(
    //     (user) => user.audioOperate === 'Stop' || user.audioOperate === null,
    //   )
    // ) {
    //   message.warning('当前班级有儿童正在收听音乐，关闭音乐后重试');
    //   return;
    // }

    const params = {
      GroupTrainingPlanId: trainingPlanV2Id,
      CourseNum: getCurrentNum,
    };
    const res = await GetCheckMusciStatus(params);

    if (res.response.ok) {
      const { musicCourseEnd, listenNotEnough, percent } = res.data;
      // 音乐模块播完
      if (musicCourseEnd.length > 0 && type === 'courses') {
        Modal.confirm({
          title: (
            <div>
              {musicCourseEnd.map((item, index) => {
                return item + (index !== musicCourseEnd.length - 1 ? '，' : '');
              })}
              {intl.formatMessage({ id: 'musicPlayedStartNew' })}
            </div>
          ),
          icon: <ExclamationCircleOutlined />,
          content: null,
          okText: intl.formatMessage({ id: 'submit' }),
          onOk: () => {
            isShowInfo(listenNotEnough, type, percent);
          },
        });
        return;
      }
      isShowInfo(listenNotEnough, type, percent);
    } else {
      showErrorMsg(res, intl);
    }
  };

  const beforeEndCourse = async () => {
    const params = {
      courseNum: getCurrentNum,
      groupTrainingPlanId: trainingPlanV2Id,
    };

    setLoading({
      ...loading,
      endCourseContentLoading: true,
    });

    // setVisible({
    //   endClassModal: true,
    // });

    // 结束课时 PutAttendance
    const res = await BeforeStartClass(params);

    if (res.response.ok) {
      setBeforeStartCourseData(res.data);
      // 结束课时
      const tempData = res.data
        .filter((item) => item.userMusic)
        .map((user) => {
          return {
            attendance: user.attendance,
            userId: user.userId,
          };
        });

      setChildClassData(tempData);
    } else {
      showErrorMsg(res, intl);
    }

    setLoading({
      ...loading,
      endCourseContentLoading: false,
    });
  };

  // 结束课时方法
  const endCourse = async () => {
    setLoading({
      ...loading,
      endClassLoading: true,
    });

    Modal.confirm({
      title: intl.formatMessage({ id: 'sureEndSession' }),
      okText: intl.formatMessage({ id: 'sureEnd' }),
      onOk: async () => {
        const params = {
          courseNum: getCurrentNum,
          groupTrainingPlanId: trainingPlanV2Id,
        };

        const res = await PostEndCourse(params);
        if (res.response.ok) {
          funTag.current = 0;

          setBeforeStartCourseData([]);
          getClassDetail();
          await dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' });

          startCourse('next');
        } else {
          showErrorMsg(res, intl);
        }
      }
    })

    setLoading({
      ...loading,
      endClassLoading: false,
    });
  };

  // const endCourse = async () => {
  //   setLoading({
  //     ...loading,
  //     endClassLoading: true,
  //   });

  //   const params = {
  //     courseNum: getCurrentNum,
  //     groupTrainingPlanId: trainingPlanV2Id,
  //   };

  //   const tempParams = {
  //     ...params,
  //     courseWay: childClassData,
  //   };

  //   const attendRes = await PutAttendance(tempParams);

  //   if (attendRes.response.ok) {
  //     const res = await PostEndCourse(params);
  //     if (res.response.ok) {
  //       funTag.current = 0;

  //       setBeforeStartCourseData([]);
  //       setVisible({ endClassModal: false });
  //       getClassDetail();
  //       await dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' });

  //       startCourse('next');
  //     } else {
  //       showErrorMsg(res, intl);
  //     }
  //   }

  //   setLoading({
  //     ...loading,
  //     endClassLoading: false,
  //   });
  // };

  const startCourseImplement = async (type) => {
    // 无存续状态的合同，则不能开始下一节
    if (
      await showUserGroupContractMsg(
        intl,
        { id: trainingPlanV2Id },
        currentUser,
        () => {
          history.push({
            pathname: '/system/center/menu/manage/userContractConfiguration'
          });
        },
      )
    ) {
      return;
    }

    Modal.confirm({
      title: intl.formatMessage({ id: type === 'current' ? 'activateClass' : 'activateNextSessionDes' }),
      icon: <ExclamationCircleOutlined />,
      content: null,
      okText: intl.formatMessage({ id: 'sureActivate' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk: async () => {
        const { canNext } = groupTrainingPlan;
        if (canNext) {
          const params = {
            groupTrainingPlanId: trainingPlanV2Id,
            courseNum: getCurrentNum + 1,
          };
          const res = await StartCourse(params);
          if (res.response.ok) {
            message.success(intl.formatMessage({ id: 'success' }));
            getClassDetail();
          } else {
            showErrorMsg(res, intl);
          }
        } else {
          message.warning(intl.formatMessage({ id: 'notScheduledNextSession' }));
        }
      },
    });
  };

  // 开启课时方法
  const startCourse = async (type) => {
    if (isParentalInterviewsNeed(getCurrentNum, 'next') && classDetail.ageStage !== 'GoldenAge') {
      const needData = await getParentalInterviewsNeedData();
      if (needData !== null) {
        if(needData.some(item => item.need)) {
          const childList = needData.map((item) => {
            const { need, subjectName } = item;
            return need ? subjectName : '';
          });
          Modal.warning({
            title: intl.formatMessage({ id: 'shouleParentInterview' }, { name: childList.join(', ') }),
            content: null,
          });
        } else {
          startCourseImplement(type);
        }
      }
    } else {
      startCourseImplement(type);
    }
  };

  const batchPdf = (num = getCurrentNum) => {
    setDownPdfModal(true);
    setDownInfo({
      startPage: 1,
      endPage: num <= 20 ? num : 20,
    });
  };

  // 预览下载pdf
  const previewOrDownloadPdf = async (type, userPlanId, num) => {

    // if (!startClassTime) {
    //   // 没有开始上课不能反馈
    //   message.warning('请先开始上课后进行操作');
    //   startClass();
    //   return;
    // }

    let res;
    if (!startClassTime) {
      // 没有开始上课不能反馈
      res = await GetChildListDetail({
        groupId: classId,
      });
    } else {
      res = await GetChildList({
        GroupTrainingPlanId: trainingPlanV2Id,
        Num: groupTrainingPlanCourseNum,
      });
    }
    
    if (res.response.ok) {
      // 默认全部选中
      const groupLinkUsers = !startClassTime ? res.data.map((item) => {
        const { id } = item
        return {
          ...item,
          attendance: 'Attend',
          userId: id,
        }
      }): res.data;

      const checkBoxData = groupLinkUsers.length > 0 &&
        groupLinkUsers
          .filter((item) => item.attendance === 'Attend')
          .map((item) => {
            return item.userId;
          });

      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };
      const { displayName } = currentTrainPlan.current || {};

      const title = intl.formatMessage({ 
          id: type === 'download'
              ? 'selectChildDownloadLesson'
              : 'selectChildPreview' 
      })

      const showContent =
        type === 'download' ? (
          <Form
            form={downform}
            {...formItemLayout}
            onFinish={async (data) => {
              const { checkChildList, downloadType } = data;

              let urls = [];
              let countDown;
              const origin = window.location.origin;
              const CognitiveleapUser =
                localStorage.getItem('CognitiveleapUser');
              const userTenantId = localStorage.getItem('userTenantId');
              const tenantId = localStorage.getItem('tenantId');
              const childNames = [];

              const baseUrl = await getVersionReportUrl('v1', userPlanId, num, 'group')

              checkChildList.forEach((item) => {
                const childItem = groupLinkUsers.find(
                  (user) => user.userId === item,
                );
                const { userName } = childItem || {};
                const tempUrl =
                  origin + baseUrl
                  `?subjectId=${item}&groupTrainingPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id
                  }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${tenantId}&from=oneTomore&culture=${getLocale()}`;
                urls.push(tempUrl);
                childNames.push(userName);
              });
              const totalTime = getDurationTime(downloadType === 'totalDownLoad' ? urls.length : 1);
              durationRef.current = totalTime;
              countDown = setInterval(() => {
                if (durationRef.current > 0) {
                  durationRef.current--;
                  setPercent(
                    parseInt(
                      ((totalTime - durationRef.current) / totalTime) * 100,
                    ),
                  );
                  setDurationTime(durationRef.current);
                }
              }, 1000);

              setVisible({
                ...visible,
                downLoadModalVisible: true,
              });

              if (downloadType === 'totalDownLoad') {
                const fileBaseName =
                  childNames.map((item) => item + ' ') +
                  '-' +
                  (displayName ?? trainPlanName) +
                  '-';

                await GetDownloadPdf(
                  { urlList: { ...urls }, formatSize: 'Letter' },
                  intl.formatMessage({ id: 'downLoadWhichFeedback' }, { fileBaseName, num })
                );
              } else {
                await Promise.all(urls.map((url, index) => {
                  const fileBaseName =
                    childNames[index] +
                    '-' +
                    (displayName ?? trainPlanName) +
                    '-';

                  return GetDownloadPdf(
                    { urlList: { url }, formatSize: 'Letter' },
                    fileBaseName + '第' + num + '课时课程反馈',
                  )
                }))
              }

              setVisible({
                ...visible,
                downLoadModalVisible: false,
              });

              clearInterval(countDown);
              setDurationTime(0);
              setPercent(0);

              currentTrainPlan.current = null;
            }}
            initialValues={{
              checkChildList: checkBoxData,
              downloadType: 'totalDownLoad'
            }}
            style={{ marginTop: '20px' }}
          >
            <Form.Item name="checkChildList" label={intl.formatMessage({ id: 'subjectList' })}>
              <Checkbox.Group>
                {groupLinkUsers.length > 0 &&
                  groupLinkUsers.map((item) => {
                    const { userName, userId, attendance } = item;
                    return (
                      <Checkbox
                        key={userId}
                        value={userId}
                        disabled={attendance !== 'Attend'}
                        style={{ lineHeight: '32px' }}
                      >
                        {userName}
                      </Checkbox>
                    );
                  })}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="downloadType"
              label="下载方式"
            >
              <Radio.Group>
                <Radio.Button value="totalDownLoad">合并下载</Radio.Button>
                <Radio.Button value="oneDownLoad">分别下载</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        ) : (
          <Form
            form={previewform}
            {...formItemLayout}
            onFinish={async (data) => {
              const { checkChild } = data;

              const baseUrl = await getVersionReportUrl('v1', userPlanId, num, 'group')

              const previewUrl = baseUrl + `?subjectId=${checkChild}&groupTrainingPlanId=${userPlanId}&num=${num}&trainerId=${
                staff && staff.id
              }&type=preview&from=oneTomore&culture=${getLocale()}`;
              window.open(previewUrl, '_blank');

              currentTrainPlan.current = null;
            }}
            initialValues={{
              checkChild: checkBoxData[0],
            }}
            style={{ marginTop: '20px' }}
          >
            <Form.Item name="checkChild" label={intl.formatMessage({ id: 'subjectList' })}>
              <Radio.Group>
                {groupLinkUsers.length > 0 &&
                  groupLinkUsers.map((item) => {
                    const { userName, userId, attendance } = item;
                    return (
                      <Radio
                        key={userId}
                        value={userId}
                        disabled={startClassTime && attendance !== 'Attend'}
                        style={{ lineHeight: '32px' }}
                      >
                        {userName}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </Form.Item>
          </Form>
        );

      Modal.confirm({
        title: title,
        width: 600,
        content: showContent,
        onOk() {
          type === 'download' ? downform.submit() : previewform.submit();
        },
        onCancel() {
          currentTrainPlan.current = null;
        },
      });
    } else {
      showErrorMsg(res, intl);
    }
  };

  // 结束课时提示
  const finishCourse = async () => {
    const res = await CheckState({
      groupTrainingPlanId:
        trainPlan &&
        trainPlan.groupTrainingPlan &&
        trainPlan.groupTrainingPlan.id,
      courseNum: getCurrentNum,
    });
    setEndCourseLoading(false);
    if (res.response.ok) {
      const { needTry, tryCount } = res.data;
      if (needTry && tryCount < 500) {
        if (tryCount > 0) {
          Modal.confirm({
            title: intl.formatMessage({ id: 'onlyTrialsLeft' }, { tryCount: tryCount}),
            content: intl.formatMessage({ id: 'reasonablyAccording' }),
            okText: intl.formatMessage({ id: 'ok' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk() {
              checkMusic('courses');
            },
          });
        } else {
          message.warning(intl.formatMessage({ id: 'noTrialsAvailable' }));
        }
      } else {
        checkMusic('courses');
      }
    } else {
      showErrorMsg(res, intl);
    }
  };

  // 获取家长访谈结果
  const getParentalInterviewsNeedData = async () => {
    const parentRes = await GetParentalInterviewsNeed({
      GroupTrainingPlanId:
        trainPlan &&
        trainPlan.groupTrainingPlan &&
        trainPlan.groupTrainingPlan.id,
      Num: getCurrentNum,
    });
    if (parentRes.response.ok) {
      return parentRes.data;
    }
    return null;
  };

  const onFinishCourse = async () => {
    setEndCourseLoading(true)
    // 需要提醒家长访谈
    if (isParentalInterviewsNeed(getCurrentNum) && classDetail.ageStage !== 'GoldenAge') {
      const needData = await getParentalInterviewsNeedData();
      if (needData !== null) {
        if (needData.some((item) => item.need)) {
          Modal.warning({
            title: intl.formatMessage({ id: 'completeInterviewPoperly' }),
            content: (
              <div style={{ color: 'gray', fontSize: '14px' }}>
                {intl.formatMessage({ id: 'NoteParentsInterview' })}
              </div>
            ),
            onOk: () => {
              finishCourse();
            },
            onCancel: () => {
              setEndCourseLoading(false);
            }
          });
        } else {
          finishCourse();
        }
      }
    } else {
      finishCourse();
    }
  }

  // 课时详情props
  const courseCardProps = {
    allData: {
      ...groupTrainingPlanCourse,
      planItems: courseItems,
      userOfflineTrainingPlan: groupTrainingPlans,
      num: getCurrentNum,
      showNextClass: endTime,
      numPunchOutput: groupTrainingPlanCourse,
      nonePlaybook,
    },
    type: 'v2',
    cardLoading,
    hasOfflinePlanWritePermission,
    hasOfflinePlanReadPermission,
    isCurrent: true,
    canChange: true,
    endCourseLoading,
    onStartNextCourse: async () => {
      startCourse('next');
    },
    onClickCardItem: (programId, e, num, courseItemId) => {
      e.stopPropagation();
      if (num !== getCurrentNum) return;

      setProgramId(programId);
      setCourseItemId(courseItemId)
      setVisible({
        ...visible,
        maskVisible: true,
      });
    },
    // onAdjustLevel: () => {
    //   setVisible({
    //     ...visible,
    //     adjustLevelVisible: true,
    //   });
    // },
    onOpenFeedback: (type, num) => {
      setChildStateNum(num);

      if (!startClassTime) {
        // 没有开始上课不能反馈
        message.warning(intl.formatMessage({ id: 'giveFeedbackUnless' }));
        startClass();
        return;
      }

      setDefaultFeedbackTab(type);
      setVisible({
        ...visible,
        feedbackVisible: true,
      });
    },
    onClickCard: (groupTrainingPlanId, num, currentNum) => {
      history.push({
        pathname: '/system/center/detail/userOfflineCourseDetail',
        query: {
          userPlanId: groupTrainingPlanId,
          num, // 当前页数
          currentNum, // 总页数
          from: 'groupClassDetail',
          userOrClassName: encodeURI(classDetail?.name),
          trainPlanName: encodeURI(trainPlanName),
        },
      });
    },
    previewHfsRecord: (userPlanId, num) => {
      previewOrDownloadPdf('preview', userPlanId, num);
    },
    onDownloadHfsRecord: (userPlanId, num) => {
      previewOrDownloadPdf('download', userPlanId, num);
    },
    onFinishCourse: onFinishCourse,
    onFeedback: async (userPlanId, num) => {
      setChildStateNum(num);
      // 反馈之前结束上课
      // if (startClassTime) {
      //   if (!endClassTime) {
      //     checkMusic('class');
      //     return;
      //   }
      // } else {
      //   // 没有开始上课不能反馈
      //   message.warning('请在开始上课之后进行反馈');
      //   startClass();
      //   return;
      // }

      if (!startClassTime) {
        // 没有开始上课不能反馈
        message.warning(intl.formatMessage({ id: 'giveFeedbackUnless' }));
        startClass();
        return;
      }

      setVisible({
        ...visible,
        feedbackVisible: true,
      });
    },
    onChangeNum: async (userPlanId, num) => {
      setCardLoading(true);
      // 初始化这里会请求一次
      const res = await GetPlanCourseByNum({
        GroupId: classId,
        CourseNum: num,
      });
      if (res.response.ok) {
        setTrainPlan(res.data);
      } else {
        showErrorMsg(res, intl);
      }
      setCardLoading(false);
    },
  };

  // 打开音乐弹窗
  const openMusicDrawer = (data) => {
    currentChild.current = data;
    dispatch({
      type: 'musicplayer/openGroupMusicPlayerDrawer',
      payload: {
        planInfo: groupTrainingPlans,
        data,
        from: 'groupClassDetail',
      },
    });
  };

  // 结束课时
  const endPlanModalProps = {
    from: 'groupClassDetail',
    currentClassItem: groupTrainingPlan,
    visible: visible.endPlanModalVisible,
    onOk: onEndPlan,
    onCancel: () => {
      setVisible({
        ...visible,
        endPlanModalVisible: false,
      })
    },
  };

  const parentInterview = async (id) => {
    const res = await chartRequest()(
      '/api/rocketAdmin/userQuestionnaire/parentalInterviews',
      {
        method: 'POST',
        data: { userId: id },
      },
    );
    if (res.response.ok) {
      setQuestionData(res.data);
      setVisible({
        drawerVisible: true,
      });
    }
  }

  const genderMusic = async (data) => {
    const { subjectInfoReturnModel: { name, id, stage } } = data;
    currentChild.current = data;
    if (stage === '黄金时代') {
      // 生成音乐
      setVisible({
        ...visible,
        generateMusicVisible: true,
      });
    } else {
      const resList = await Promise.all([
        GetDiagnosticInformationList({
          UserId: id
        }),
        GetChiefComplaintList({
          UserId: id
        })
      ])
      if(resList.every(res => res.response.ok)) {
        const firstRes = resList[0].data.items.totalCount
        const twoRes = resList[1].data.items.totalCount

        if (firstRes === 0 || twoRes === 0) {
          Modal.confirm({
            title: intl.formatMessage({ id: 'fillNameProfile' }, { name }),
            width: 480,
            icon: <ExclamationCircleOutlined />,
            content: <div style={{ color: 'gray' }}>
              {intl.formatMessage({ id: 'informationDes' })}
            </div>,
            okText: intl.formatMessage({ id: 'fillOut' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk() {
              window.open(`/system/archivesV2?subjectId=${id}`)
            }
          });
        } else {
          // 生成音乐
          setVisible({
            ...visible,
            generateMusicVisible: true,
          });
        }
      }
    }
  }

  // 点击音乐部分方法
  const openMusic = (data, music) => {
    const { userMusics, subjectInfoReturnModel, audioOperate, id } = data;

    if (userMusics && userMusics.length === 0 && music !== intl.formatMessage({ id: 'Absent' })) {
      genderMusic(data)
    } else {
      // 打开播放音乐弹窗
      if (
        !(audioOperate === 'Stop' || audioOperate === null) &&
        !(musicPlayChildInfo && musicPlayChildInfo.id === id)
      ) {
        message.warning(intl.formatMessage({ id: 'currentDevicePlayable' }));
      } else {
        if (music === intl.formatMessage({ id: 'Offline' })) {
          const { id, name } = subjectInfoReturnModel;
          const {
            subjectInfoReturnModel: { id: currentId },
          } = currentChild.current || { subjectInfoReturnModel: { id: '' } };

          if (musicIsOpen) {
            if (id !== currentId) {
              Modal.confirm({
                title: (
                  <div style={{ color: 'red', fontWeight: 'bold' }}>
                    {intl.formatMessage({ id: 'replaceHFSmusic' }, { name: name })}
                  </div>
                ),
                icon: <ExclamationCircleOutlined />,
                content: (
                  <div style={{ color: 'red' }}>
                    {intl.formatMessage({ id: 'otherChildrenMusicPlaying' })}
                  </div>
                ),
                okText: intl.formatMessage({ id: 'confirmChange' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                  openMusicDrawer(data);
                },
              });
            } else {
              dispatch({
                type: 'musicplayer/updateState',
                payload: {
                  musicPlayerDrawerVisible: true,
                },
              });
            }
          } else {
            openMusicDrawer(data);
          }
        } else if (music === intl.formatMessage({ id: 'Online' })) {
          // 线上课程暂时未做
          message.warning(intl.formatMessage({ id: 'onlineSessionCannotPlayed' }));
          return;
        } else if (music === intl.formatMessage({ id: 'ToBeActivated' })) {
          if (
            groupTrainingPlan === undefined ||
            (groupTrainingPlan && groupTrainingPlan.length === 0)
          ) {
            // 未关联训练计划
            connectedTrainPlan();
          } else {
            if (isCurrentClass || groupTrainingPlanCourse === null) {
              if (startTime && !startClassTime) {
                // 在切换到了当前课时点击
                startClass();
              } else {
                startCourse(endTime ? 'next' : 'current');
              }
            } else {
              message.warning(intl.formatMessage({ id: 'switchCurrentLatest' }));
            }
          }
        } else {
          message.warning(`${music}${intl.formatMessage({ id: 'notPlayable' })}`);
        }
      }
    }
  };

  const musicProps = {
    subjectInfo:
      currentChild.current && currentChild.current.subjectInfoReturnModel,
    visible: visible.generateMusicVisible,
    onClose() {
      setVisible({
        ...visible,
        generateMusicVisible: false,
      });
    },
    reloadPage: getClassDetail,
    getFASReport: () => {
      history.push({
        pathname: '/system/center/detail/subjectDetail',
        query: {
          id: currentChild.current.id,
        },
      });
      setTimeout(() => {
        dispatch({ type: 'subjectDetail/getFASReport' })
      }, 1500)
    }
  };

  // 开始上课方法
  const startClass = async () => {
    setLoading({
      ...loading,
      classModalLoading: true,
    });
    setVisible({
      ...visible,
      openClassModal: true,
    });
    const res = await BeforeStartClass({
      GroupTrainingPlanId: trainingPlanV2Id,
      CourseNum: getCurrentNum,
    });

    if (res.response.ok) {
      const tempData = res.data.map((item) => {
        const { userMusic, isFree, platform } = item;
        // 无优脑音乐不可以切换线上线下，只可以请假且默认选中
        // 其他班级上课中也是标为请假
        const tempPlatform = userMusic
          ? isFree
            ? platform || 'Web'
            : 'Leave'
          : 'Leave';
        return {
          ...item,
          platform: tempPlatform,
        };
      });
      setChildClassList(tempData);
    } else {
      showErrorMsg(res, intl);
    }

    setLoading({
      ...loading,
      classModalLoading: false,
    });
  };

  // 结束上课方法
  const endClassFun = async (type = 'click') => {
    const params = {
      groupTrainingPlanId: trainingPlanV2Id,
      courseNum: getCurrentNum,
    };
    const res = await EndClass(params);
    if (res.response.ok) {
      getClassDetail();

      await dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' });

      type !== 'click'
        ? message.success(intl.formatMessage({ id: 'thisSessionEnded' }))
        : setVisible({
            ...visible,
            feedbackVisible: true,
          });
    } else {
      showErrorMsg(res, intl);
    }
  };

  // 前端自动结束上课
  if (currentTime != '00 : 00 : 00' && getSeconds(currentTime) % 7200 === 0) {
    funTag.current++;
    if (funTag.current <= 1) {
      endClassFun('auto');
    }
  }

  // 结束上课弹窗
  const endClass = () => {
    // 结束上课
    Modal.confirm({
      title: intl.formatMessage({ id: 'finishClass' }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: 'sureEndSession' }),
      okText: intl.formatMessage({ id: 'yes' }),
      cancelText: intl.formatMessage({ id: 'continueClass' }),
      onOk: () => {
        endClassFun();
      },
    });
  };

  const getEndClassTime = () => {
    const tempData = duration.split('.');
    return tempData[0].replaceAll(':', ' : ');
  };

  // 音乐状态文字样式
  const getStyle = (music) => {
    switch (music) {
      case intl.formatMessage({ id: 'ToBeGenerated' }):
        return {
          color: '#FF6600',
        };
      case intl.formatMessage({ id: 'Offline' }):
        return {
          backgroundColor: '#3c84ff',
          borderRadius: '5px',
          color: 'white',
          padding: '0 5px 0 5px',
        };
      case intl.formatMessage({ id: 'Online' }):
        return {
          backgroundColor: '#2dcfa7',
          borderRadius: '5px',
          color: 'white',
          padding: '0 5px 0 5px',
        };
      case intl.formatMessage({ id: 'Absent' }):
        return {
          backgroundColor: 'red',
          borderRadius: '5px',
          color: 'white',
          padding: '0 5px 0 5px',
        };
      case intl.formatMessage({ id: 'OccupiedClass' }):
        return {
          color: 'gray',
        };
      default:
        return {};
    }
  };

  // 关联计划弹窗
  const connectedTrainPlan = () => {
    setVisible({
      ...visible,
      trainModalVisible: true,
    });
  };

  // 关联计划弹窗
  const connectTrainProps = {
    type: 'add',
    currentItem: classDetail,
    closeModal: () => {
      setVisible({
        ...visible,
        trainModalVisible: false,
      });
    },
    reloadPage: getClassDetail,
    openSelectChild: () => {
      setVisible({
        ...visible,
        manageChildVisible: true,
      });
    },
  };

  // 切换上课状态
  const changeClassStatus = async (item) => {
    const { id, userName, music, userMusics, audioOperate } = item;

    if (!(audioOperate === 'Stop' || audioOperate === null)) {
      message.warning(intl.formatMessage({ id: 'musicPlayingAgain' }));
      return;
    }

    setVisible({
      ...visible,
      changeClassModal: true,
    });
    setLoading({
      ...loading,
      changeStatusLoading: true,
    });
    const params = {
      courseNum: getCurrentNum,
      groupTrainingPlanId: trainingPlanV2Id,
    };

    const res = await BeforeStartClass(params);
    if (res.response.ok) {
      const newData = res.data.find((data) => data.userId === id);

      const simpleData = {
        id,
        userName,
        music,
        userMusics,
        ...newData,
      };

      setChangeChildData([simpleData]);
    }
    setLoading({
      ...loading,
      changeStatusLoading: false,
    });
  };

  const downLoadModalProps = {
    visible: visible.downLoadModalVisible,
    percent,
    duration: durationTime,
  };

  const adjustLevelProps = {
    from: 'OneToMore',
    visible: visible.adjustLevelVisible,
    currentItem: groupTrainingPlanCourse,
    submitAdjustLevelLoading: loading.submitAdjustLevelLoading,
    onOk: async (data) => {
      setLoading({
        ...loading,
        submitAdjustLevelLoading: true
      })

      const { id: groupTrainingPlanCourseId } = groupTrainingPlanCourse || {};
      const params = {
        groupTrainingPlanCourseId,
        ...data,
      }
      const res = await PostGroupAdjustProgramLevel(params);
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        setAllowReloadTab(!allowReloadTab)
        getClassDetail();
      } else {
        showErrorMsg(res, intl);
      }

      setVisible({
        ...visible,
        adjustLevelVisible: false
      })
      setLoading({
        ...loading,
        submitAdjustLevelLoading: false
      })
    },
    onCancel: () => {
      setVisible({
        ...visible,
        adjustLevelVisible: false
      })
    }
  }

  const currentRecordsProps = {
    activePlan: groupTrainingPlan,
    from: 'group',
    allowReloadTab,
  }

  const checkEndPlanProps = {
    open: visible.checkEndPlanModalVisible,
    onCancel: () => {
      setVisible({
        ...visible,
        checkEndPlanModalVisible: false
      })
    },
    onEndPlan: () => {
      setVisible({
        ...visible,
        endPlanModalVisible: true,
        checkEndPlanModalVisible: false,
      })
    },
    onEndClass: () => {
      setVisible({
        ...visible,
        checkEndPlanModalVisible: false,
      })

      const { hasDone: guardianHasDone } = deepCopy(groupTrainingPlanCourse || { hasDone: false })

      if (courseItems.every(item => item.hasDone !== null) && nonePlaybook.every(item => item.hasDone !== null) && guardianHasDone) {
        onFinishCourse();
      } else {
        message.warning(intl.formatMessage({ id: 'submitFeedBackDes' }))
      }
    }
  }

  return (
    <div className={styles.groupClass}>
      <Spin spinning={loading.detailLoading}>
        <div className={styles.title}>
          {/* <Button
            onClick={() => {
              if (musicIsOpen) {
                Modal.confirm({
                  title: intl.formatMessage({ id: 'musicPlayingProceed' }),
                  okText: intl.formatMessage({ id: 'leave' }),
                  cancelText: intl.formatMessage({ id: 'cancel' }),
                  onOk: async () => {
                    await dispatch({
                      type: 'musicplayer/closeGlobalMusicPlayer',
                    });
                    history.goBack();
                  },
                });
              } else {
                history.goBack();
              }
            }}
          >
            {intl.formatMessage({ id: 'back' })}
          </Button> */}
          <span className={styles.titleName}>{classDetail.name}</span>
        </div>
        <div className={styles.content}>
          <div className={styles.leftContent}>
            <div className={styles.leftTop}>
              <Dropdown overlay={menu} placement="bottomRight">
                <AppstoreOutlined className={styles.icon} />
              </Dropdown>
              <div className={styles.leftName}>{classDetail.name}</div>
              <div className={styles.tagList}>
                <div className={styles.tag}>
                  {classDetail.type === 'OneToOne' ? intl.formatMessage({ id: 'OneToOne' }): getGroupTag(classDetail.type, intl)}
                </div>
                {/* <div className={styles.tag}>
                  {getStage(classDetail.ageStage, intl)}
                </div> */}
              </div>
              <div className={styles.trainerList}>
                {groupLinkTrainers.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <span
                        onClick={() => {
                          history.push({
                            pathname: '/system/center/detail/groupDetail',
                            query: {
                              id: item.id,
                            },
                          });
                        }}
                      >
                        {item.name}
                      </span>
                      {index !== groupLinkTrainers.length - 1 ? '，' : null}
                    </div>
                  );
                })}
              </div>
              <div className={styles.leftDes}>
                {intl.formatMessage({ id: 'startFrom' })}{' '}
                {moment(classDetail.creationTime).format('YYYY-MM-DD HH:mm')}
              </div>
            </div>
            <div className={styles.leftBody}>
              <div className={styles.leftBodyTitle}>
                <div className={styles.titleName}>{intl.formatMessage({ id: 'subjectClass' })}</div>
                <AppstoreOutlined
                  className={styles.icon}
                  onClick={() => {
                    setVisible({
                      ...visible,
                      manageChildVisible: true,
                    });
                  }}
                />
              </div>
              {/* 儿童列表 */}
              <div className={styles.leftChildList}>
                {groupLinkUsers.map((item) => {
                  const {
                    id,
                    music,
                    isFree,
                    userMusics,
                    userName,
                    ageStage,
                    audioOperate,
                    outpatientNumber,
                    subjectInfoReturnModel,
                  } = item;
                  const { gender, picture } = subjectInfoReturnModel;
                  const isStudying = 
                    music === intl.formatMessage({ id: 'Absent' }) || music === intl.formatMessage({ id: 'Online' }) || music === intl.formatMessage({ id: 'Offline' });
                  return (
                    <div key={id} className={styles.children}>
                      <div className={styles.childTop}>

                        <div className={styles.childHeader}>
                          {getUserImg(subjectInfoReturnModel, { height: '35px', width: '35px' })}
                          {/* <img src={picture ? picture : subjectDefault} /> */}
                        </div>
                        <div className={styles.childInfo}>
                          <div
                            className={styles.childName}
                            onClick={() => {
                              history.push({
                                pathname: '/system/center/detail/subjectDetail',
                                query: {
                                  id,
                                  groupMusicIsOpen: musicIsOpen,
                                },
                              });
                            }}
                          >
                            {userName}
                          </div>
                          <div className={styles.childInfoDetails}>
                            {outpatientNumber && (
                              <>
                                <div className={styles.childNum}>
                                  {outpatientNumber}
                                </div>
                                <Divider type="vertical" />
                              </>
                            )}
                            <div className={styles.childStages}>
                              {getStage(ageStage, intl)}
                            </div>
                            <Divider type="vertical" />
                            <div className={styles.childSex}>
                              {intl.formatMessage({ id: gender === 'Female' ? 'female' : 'male' })}
                            </div>
                          </div>
                        </div>
                        <Dropdown
                          overlay={
                            <Menu>
                              {/* <Menu.Item key="changeMusic">
                                <div
                                  style={{
                                    width: '80px',
                                    textAlign: 'center',
                                  }}
                                >
                                  调整音乐
                                </div>
                              </Menu.Item> */}
                              <Menu.Item
                                key="down"
                                // 请假状态，无优脑和在其他班级上课中都是不能切换的
                                disabled={
                                  !isStudying ||
                                  (userMusics && userMusics.length === 0) ||
                                  (!isFree && music === intl.formatMessage({ id: 'Absent' })) ||
                                  groupTrainingPlanCourse?.hasDone !== null
                                }
                                onClick={() => changeClassStatus(item)}
                              >
                                <div
                                  style={{
                                    minWidth: '100px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {intl.formatMessage({ id: 'switchClassStatus' })}
                                </div>
                              </Menu.Item>
                              <Menu.Item
                                key="data"
                                onClick={() => {
                                  const { id } = item.subjectInfoReturnModel;
                                  const url = `/system/archivesV2?subjectId=${id}&from=center`;
                                  window.open(url, '_blank');
                                }}
                                disabled={!hasReadUserQuestionnairePermission}
                              >
                                <div
                                  style={{
                                    minWidth: '100px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {intl.formatMessage({ id: 'archives' })}
                                </div>
                              </Menu.Item>
                              {/* <Menu.Item key="parent">
                                <div
                                  style={{
                                    minWidth: '100px',
                                    textAlign: 'center',
                                  }}
                                  onClick={() => parentInterview(id)}
                                >
                                  {intl.formatMessage({ id: 'parentInterviews' })}
                                </div>
                              </Menu.Item> */}
                            </Menu>
                          }
                          placement="bottomRight"
                        >
                          <MoreOutlined className={styles.childIcon} />
                        </Dropdown>
                      </div>
                      <div
                        className={styles.childBottom}
                        onClick={() => {
                          eventDebounce(() => openMusic(item, music), 1000, true)
                        }}
                      >
                        <div className={styles.musicInfo}>
                          <div className={styles.musicImg}>
                            <img src={brainLogo} />
                          </div>
                          {userMusics && userMusics.length > 0 ? (
                            <div className={styles.musicName}>
                              {userMusics[0].code.toUpperCase()}{' '}
                              {PrefixZero(userMusics[0].musicCourseNum, 2)}
                            </div>
                          ) : (
                            <div className={styles.musicName}>
                              {intl.formatMessage({ id: 'HFSMusic' })} &nbsp;
                            </div>
                          )}
                          <span style={getStyle(music)}>{music}</span>
                        </div>
                        {/* audioOperate为null说明两边都没开启，audioOperate为Start不可以播放说明有一个设备在播放，Stop证明播放了且关闭了 */}
                        {audioOperate &&
                          audioOperate !== 'Stop' &&
                          !(
                            musicPlayChildInfo && musicPlayChildInfo.id === id
                          ) && (
                            <div className={styles.musicOther}>
                              {intl.formatMessage({ id: 'playingOtherDevice' })}
                            </div>
                          )}
                        {musicIsOpen &&
                          userMusics &&
                          userMusics.length > 0 &&
                          musicPlayChildInfo &&
                          musicPlayChildInfo.id === id && (
                            <>
                              <div
                                className={`${styles.musicWhere} ${styles.active}`}
                              >
                                {intl.formatMessage({ id: 'playingCurrentDevice' })}
                              </div>
                              <div className={styles.musicPlay}>
                                <img src={playing} />
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* 左边没有儿童 */}
              {groupLinkUsers.length === 0 && (
                <div className={styles.empty}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  <Button
                    type="primary"
                    onClick={() => {
                      setVisible({
                        ...visible,
                        manageChildVisible: true,
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'toAdd' })}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.rightTop}>
              {/* 开始/结束/继续上课 */}
              {groupTrainingPlanCourse && (
                <div className={styles.topTitle}>
                  <div>
                    {startClassTime ? (
                      endClassTime ? (
                        // 继续上课删除结束时间字段
                        <Button
                          type="primary"
                          disabled={endTime}
                          onClick={async () => {
                            funTag.current = 0;

                            const params = {
                              groupTrainingPlanId: trainingPlanV2Id,
                              courseNum: getCurrentNum,
                            };
                            const res = await ContinueClass(params);
                            if (res.response.ok) {
                              getClassDetail();
                            } else {
                              showErrorMsg(res, intl);
                            }
                          }}
                        >
                          {intl.formatMessage({ id: 'continueClass' })}
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          disabled={endClassTime}
                          onClick={() => checkMusic('class')}
                        >
                          {intl.formatMessage({ id: 'finishClass' })}
                        </Button>
                      )
                    ) : (
                      <Button
                        type="primary"
                        onClick={startClass}
                        disabled={!startTime}
                      >
                        {intl.formatMessage({ id: 'startClass' })}
                      </Button>
                    )}
                  </div>
                  <div className={styles.timer}>
                    {isCurrentClass ? (
                      <div>
                        {`${intl.formatMessage({ id: 'goingTime' })}： ${
                          !endClassTime
                            ? startClassTime
                              ? currentTime
                              : '00 : 00 : 00'
                            : getEndClassTime()
                        }`}
                      </div>
                    ) : (
                      <div>
                        {intl.formatMessage({ id: 'finished' })}：
                        {getEndClassTime()}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* 关联计划头部信息 */}
              {groupTrainingPlans && (
                <div style={{ marginBottom: 24 }}>
                  <div style={{ marginBottom: 2 }}>
                    <Card hoverable type="inner">
                      <div
                        className={styles.listTitle}
                        onClick={() => {
                          goTrainList(trainingPlanV2Id);
                        }}
                      >
                        <div>
                          <p>
                            <span className={styles.planTitle}>
                              {trainPlanName}
                            </span>
                          </p>
                          <p>
                            <span>{intl.formatMessage({ id: 'start' })}: </span>
                            <span>
                              {moment(creationTime).format('YYYY/MM/DD HH:mm')}
                            </span>
                          </p>
                        </div>
                        <div onClick={(e)=>{e.stopPropagation()}}>
                          {
                            hasStartClass && (
                              <>
                                <span>
                                  <Button
                                    type="link"
                                    className={styles.classAdjustmentButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      batchPdf();
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'batchDownloadForm' })}
                                  </Button>
                                  <Divider type="vertical" />
                                </span>
                                <span>
                                  <Button
                                    type="link"
                                    className={styles.classAdjustmentButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      goTrainList(trainingPlanV2Id);
                                    }}
                                  >
                                    {intl.formatMessage({ id: 'planDetail' })}
                                  </Button>
                                  <Divider type="vertical" />
                                </span>
                              </>
                            )
                          }
                          <span>
                            <Button
                              type="link"
                              className={styles.classAdjustmentButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                setVisible({
                                  ...visible,
                                  chartDrawerVisible: true,
                                });
                              }}
                            >
                              {intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}
                            </Button>
                          </span>
                          {
                            hasStartClass && (
                              <span>
                                <Divider type="vertical" />
                                <Button
                                  type="link"
                                  className={styles.classAdjustmentButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVisible({
                                      ...visible,
                                      adjustLevelVisible: true,
                                    });
                                  }}
                                >
                                  {intl.formatMessage({ id: 'Adjust Difficulty' })}
                                </Button>
                              </span>
                            )
                          }
                          <span>
                            <Divider type="vertical" />
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item key="terminateThePlan">
                                    <div
                                      style={{ width: '80px', textAlign: 'center', color: '#1890ff' }}
                                      onClick={onTerminateThePlanClick}
                                    >
                                      {intl.formatMessage({ id: 'terminateThePlan' })}
                                    </div>
                                  </Menu.Item>
                                </Menu>
                              }
                              placement="bottom"
                            >
                              <a>{intl.formatMessage({ id: 'more'})}</a>
                            </Dropdown>
                          </span>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              )}

              {/* 有关联计划有儿童有开启课时 */}
              {groupTrainingPlans && groupTrainingPlanCourse && (
                <div className={styles.courseCard}>
                  {status === 'Started' ? (
                    <CourseCard {...courseCardProps} />
                  ) : null}
                </div>
              )}

              {/* 未开启课时 有关联计划，儿童数量为空 */}
              {groupLinkUsers.length === 0 &&
                groupTrainingPlan &&
                !groupTrainingPlanCourse && (
                  <div className={styles.classEmpty}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Button
                      type="primary"
                      onClick={async () => {
                        setVisible({
                          ...visible,
                          manageChildVisible: true,
                        });
                      }}
                    >
                      {intl.formatMessage({ id: 'authorisingChildren' })}
                    </Button>
                  </div>
                )}

              {/* 未开启课时 有关联计划 并且 儿童数量不为空 */}
              {groupTrainingPlans &&
                groupLinkUsers.length > 0 &&
                groupTrainingPlan &&
                !groupTrainingPlanCourse && (
                  <div className={styles.classEmpty}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    <Button
                      type="primary"
                      onClick={async () => {
                        startCourse('current');
                      }}
                    >
                      {intl.formatMessage({ id: 'activateClass' })}
                    </Button>
                  </div>
                )}

              {/* 没有关联计划 */}
              {!groupTrainingPlans && (
                <div className={styles.empty}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  <Button
                    type="primary"
                    onClick={() => {
                      connectedTrainPlan();
                    }}
                  >
                    {intl.formatMessage({ id: 'toAdd' })}
                  </Button>
                </div>
              )}
            </div>

            {groupTrainingPlans && <RecordsTabs {...currentRecordsProps}/>}

            <div className={styles.rightBottom}>
              <div className={styles.bottomTitle}>
                {intl.formatMessage({ id: 'finishedTrainingPlan' })}
                <span className={styles.expend} onClick={() => setOpen(!open)}>
                  {intl.formatMessage({ id: open ? 'open' : 'putItAway'})}
                  {open ? <DownOutlined /> : <UpOutlined />}
                </span>
              </div>
              {!open && (
                <div className={styles.magicTable}>
                  <MagicTable {...tableProps} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
      {/* 修改班级 Modal */}
      <AddOrEditClassModal {...editClassModalProps} />
      {/* 课时反馈 */}
      {visible.feedbackVisible && !loading.detailLoading && (
        <FeedBack {...drawProps} />
      )}
      {/* 点击单个训练显示内容 */}
      {visible.maskVisible && programId && <ShowItem {...showItemProps} />}
      {visible.chartDrawerVisible && <ChartsDrawer {...chartDrawerProps} />}

      {/* 家长访谈Drawer */}
      <Drawer
        title={<b style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'parentInterviews' })}</b>}
        open={visible.drawerVisible}
        width={700}
        onClose={onClose}
      >
        {/* 这样写每次都会子组件重新render */}
        {/* {visible.drawerVisible && <Question {...questionProps()} />} */}
        {useMemo(() => {
          return visible.drawerVisible ? (
            <Question {...questionProps()} />
          ) : (
            <></>
          );
        }, [visible.drawerVisible])}
      </Drawer>
      {/* 开启课时 Modal */}
      {groupTrainingPlans && <StartClassModal {...startClassProps} />}
      {/* 切换上课状态 */}
      {visible.changeClassModal && <StartClassModal {...changeClassProps} />}
      {/* 管理儿童 Modal */}
      {visible.manageChildVisible && <ManageChildModal {...manageProps} />}
      {/* 生成音乐 */}
      {visible.generateMusicVisible && <GenerateMusic {...musicProps} />}
      {/* 训练计划 Modal */}
      {visible.trainModalVisible && <ConnectTrainPlan {...connectTrainProps} />}
      {/* 调整课程难度 Modal */}
      {
        useMemo(() => {
          return visible.adjustLevelVisible && <AdjustLevelModal {...adjustLevelProps}/>
        }, [visible.adjustLevelVisible, loading.submitAdjustLevelLoading])
      }
      {/* 批量下载pdf */}
      <Modal
        title={intl.formatMessage({ id: 'batchDownloadForm' })}
        width={600}
        open={downPdfModal}
        onOk={() => {
          Modal.confirm({
            title: intl.formatMessage({ id: 'sureDownload' }),
            icon: <ExclamationCircleOutlined />,
            content: null,
            okText: intl.formatMessage({ id: 'submit' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk: async () => {
              if (currentSelectChild.current === null) {
                message.warning(intl.formatMessage({ id: 'selectAChild' }));
                return;
              }

              if (downInfo.endPage - downInfo.startPage > 19) {
                message.warning(intl.formatMessage({ id: 'downloadedTips' }));
                return;
              }

              let countDown;
              let totalTime;

              Modal.destroyAll();
              setDownPdfModal(false);

              const { displayName } = currentTrainPlan.current || {};

              const childItem = groupLinkUsers.find(
                (user) => user.id === currentSelectChild.current,
              );
              const { userName } = childItem || {};
              const fileBaseName =
                userName + '-' + (displayName ?? trainPlanName) + '-';

              const origin = window.location.origin;
              const CognitiveleapUser =
                localStorage.getItem('CognitiveleapUser');
              const userTenantId = localStorage.getItem('userTenantId');
              const localTenantId = localStorage.getItem('tenantId');

              if (downInfo.startPage === downInfo.endPage) {
                const baseUrl = await getVersionReportUrl('v1', trainingPlanV2Id, downInfo.startPage, 'group')

                const url = [
                  origin + baseUrl +
                  `?subjectId=${currentSelectChild.current
                  }&groupTrainingPlanId=${trainingPlanV2Id}&num=${downInfo.startPage
                  }&trainerId=${staff && staff.id
                  }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${localTenantId}&from=oneTomore&culture=${getLocale()}`,
                ];

                totalTime = getDurationTime(url.length);
                durationRef.current = totalTime;
                countDown = setInterval(() => {
                  if (durationRef.current > 0) {
                    durationRef.current--;
                    setPercent(
                      parseInt(
                        ((totalTime - durationRef.current) / totalTime) * 100,
                      ),
                    );
                    setDurationTime(durationRef.current);
                  }
                }, 1000);
                setVisible({
                  ...visible,
                  downLoadModalVisible: true,
                });
                await GetDownloadPdf(
                  { urlList: { ...url }, needPadding: true },
                  intl.formatMessage({ id: 'downLoadWhichFeedback' }, { fileBaseName, num: downInfo.startPage })
                );
              } else {
                let urls = [];

                const baseUrl = await getVersionReportUrl('v1', trainingPlanV2Id, num, 'group');

                for (
                  let num = downInfo.startPage;
                  num <= downInfo.endPage;
                  num++
                ) {
                  const tempUrl =
                    origin + baseUrl +
                    `?subjectId=${currentSelectChild.current
                    }&groupTrainingPlanId=${trainingPlanV2Id}&num=${num}&trainerId=${staff && staff.id
                    }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${localTenantId}&from=oneTomore&culture=${getLocale()}`;
                  urls.push(tempUrl);
                }
                totalTime = getDurationTime(urls.length);
                durationRef.current = totalTime;
                countDown = setInterval(() => {
                  if (durationRef.current > 0) {
                    durationRef.current--;
                    setPercent(
                      parseInt(
                        ((totalTime - durationRef.current) / totalTime) * 100,
                      ),
                    );
                    setDurationTime(durationRef.current);
                  }
                }, 1000);
                setVisible({
                  ...visible,
                  downLoadModalVisible: true,
                });
                await GetDownloadPdf(
                  { urlList: { ...urls }, needPadding: true },
                  intl.formatMessage({ id: 'downLoadWhichToWhichFeedback' }, { fileBaseName, startNum: downInfo.startPage, endNum: downInfo.endPage})
                );
              }
              clearInterval(countDown);
              setVisible({
                ...visible,
                downLoadModalVisible: false,
              });
              setDurationTime(0);
              setPercent(0);
              currentTrainPlan.current = null;
            },
          });
        }}
        okText={intl.formatMessage({ id: 'download' })}
        onCancel={() => {
          setDownPdfModal(false);
          currentTrainPlan.current = null;
        }}
      >
        <div>
          <div>
            <span className={styles.planTitle}>
              {(currentTrainPlan.current &&
                currentTrainPlan.current.displayName) ||
                trainPlanName}
            </span>
            <span className={styles.planCurrentNum}>
              {(currentTrainPlan.current &&
                currentTrainPlan.current.currentNum) ||
                getCurrentNum}
            </span>
          </div>
          <div style={{ marginTop: 25 }}>
            <span>{intl.formatMessage({ id: 'enterSessionDownload' })} &nbsp;</span>
            &nbsp;
            <InputNumber
              min={1}
              value={downInfo.startPage}
              max={downInfo.endPage}
              onChange={(num) => {
                setDownInfo({
                  ...downInfo,
                  startPage: num,
                });
              }}
            />
            <span>&nbsp; - &nbsp;</span>
            &nbsp;
            <InputNumber
              min={downInfo.startPage}
              value={downInfo.endPage}
              max={getCurrentNum}
              onChange={(num) => {
                setDownInfo({
                  ...downInfo,
                  endPage: num,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 25 }}>
            <span>{intl.formatMessage({ id: 'children' })}: &nbsp;</span>
            <Select
              showSearch
              defaultValue={currentSelectChild.current}
              style={{ width: 150 }}
              placeholder={intl.formatMessage({ id: 'selectOneChild' })}
              optionFilterProp="children"
              onChange={(data) => {
                currentSelectChild.current = data;
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {groupLinkUsers.length > 0 &&
                groupLinkUsers.map((user) => {
                  const { id, userName } = user;
                  return (
                    <Option key={id} value={id}>
                      {userName}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </Modal>
      {/* 结束课时 */}
      <EndPlanModal {...endPlanModalProps} />
      <DownLoadModal {...downLoadModalProps} />
      {/* 结束计划弹窗确认 */}
      <CheckEndPlanModal {...checkEndPlanProps} />
    </div>
  );
};

export default connect(({ user, musicplayer }) => ({
  currentUser: user.currentUser,
  musicplayer: musicplayer,
}))(GroupClassDetail);
