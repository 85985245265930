import { useIntl } from 'umi'
import { formatConfig } from '../../config';
import moment from 'moment'
import { GetILSTotalData, GetILSAnalysis, GetPlanList, DownloadExcelApi } from 'services/rocketService/Analysis'
import { GetSleepRecord } from 'services/rocketService/TrainingPlan';
import { GetBrainRecord, GetMusicRecord } from 'services/rocketService/UserOfflinePlan'
import download from 'cognitiveleap-core-us/utils/download'

export default {
  namespace: 'adminILsAnalysis',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    analysisData: null,
    keyIndicators: null,
    activeKey: 'iLsAnalysis',
    isOpenUp: false,
    planList: [],
    ilsActiveKey: 'chart',
    currentIlsId: null,
    ilsDrawerVisible: false,
    ilsActiveKey: 'chart',
    currentonlinePlanId: null,
    shouldGetData: false,
    audioType: '',
    ilstrainerRecord: null,
    ilsparentRecord: null,
    ilsappRecord: null,
    brainRecord: null,
    userId: null,
    currentTenant: null,
    searchOption: {
      userNickName: '',
      planName: '',
      planType: '',
      status: '',
      createTime: {
        startTime: '',
        endTime: ''
      },
      realStartTime: {
        startTime: '',
        endTime: ''
      },
      endTime: {
        startTime: '',
        endTime: ''
      },
      courseTime: {
        startTime: '',
        endTime: ''
      }
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 55
    },
    recordPagination: {
      ilstrainer: {
        current: 0,
        pageSize: 10,
      },
      ilsparent: {
        current: 0,
        pageSize: 10,
      },
      ilsapp: {
        current: 0,
        pageSize: 10,
      }
    },
    musicRecord: null
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *initPage({ payload }, { call, put, select }) {
      const { currentUser } = yield select(state => state.user);
      const { currentTenant, activeKey } = yield select(state => state.adminILsAnalysis);
      const { canChangeList } = currentUser;
      if (!currentTenant) {
        yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' }} })
      } else if (payload) {
        const { id } = payload;
        if (id) {
          const newCurrentTenant = canChangeList.find(item => item.id == id)
          yield put({ type: 'updateState', payload: { currentTenant: newCurrentTenant } })
        } else {
          yield put({ type: 'updateState', payload: { currentTenant: { id: '', displayName: '全部' }} })
        }
      }
      switch (activeKey) {
        case 'iLsAnalysis':
          yield put({ type: 'loadPage' })
          yield put({ type: 'loadAnalysisData' })
          break;
        case 'iLsTable':
          yield put({ type: 'getPlanList' })
          break
      }
    },

    *loadPage({ payload: data }, { call, put, select }) {
      const { currentTenant } = yield select(state => state.adminILsAnalysis);
      const res = yield call(GetILSTotalData, { TenantId: currentTenant.id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            keyIndicators: res.data,
          }
        })
      }
    },

    *loadAnalysisData(_, { call, put, select }) {
      const { currentTag, dateMonthPickerData, dateYearPickerData, currentTenant } = yield select(state => state.adminILsAnalysis);
      const date = {
        "Year": dateYearPickerData,
        "Month": dateMonthPickerData
      }[currentTag];

      const res = yield call(GetILSAnalysis, {
        Type: currentTag,
        Year: parseInt(moment(date).format('YYYY')),
        Month: parseInt(moment(dateMonthPickerData).format('MM')),
        TenantId: currentTenant.id
      });

      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            analysisData: res.data
          }
        })
      }
    },

    *handlePanelChange({ payload: { time } }, { call, put, select }) {
      const { datePickerIsOpen, currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.adminILsAnalysis);
      yield put({
        type: 'updateState',
        payload: {
          dateMonthPickerData: {
            "Year": dateMonthPickerData,
            "Month": moment(time)
          }[currentTag],
          dateYearPickerData: {
            "Year": moment(time),
            "Month": dateYearPickerData
          }[currentTag],
          datePickerIsOpen: !datePickerIsOpen
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *changeTag({ payload: { key } }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          currentTag: key
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *onClear({ payload }, { call, put, select }) {
      const searchOption = {
        userNickName: '',
        planName: '',
        planType: '',
        status: '',
        createTime: {
          startTime: '',
          endTime: ''
        },
        realStartTime: {
          startTime: '',
          endTime: ''
        },
        endTime: {
          startTime: '',
          endTime: ''
        },
        courseTime: {
          startTime: '',
          endTime: ''
        }
      }
      const pagination = {
        current: 1,
        pageSize: 10,
        total: 0
      }

      yield put({ type: 'updateState', payload: { searchOption, pagination } })
      yield put({ type: 'getPlanList' })
    },

    *getPlanList({ payload }, { call, put, select }) {
      const { searchOption, pagination, currentTenant } = yield select(state => state.adminILsAnalysis);
      const { current, pageSize } = pagination;
      const { userNickName, planName, planType, status, createTime, realStartTime, endTime, courseTime } = searchOption
      const SkipCount = (current - 1) * pageSize;
      const MaxResultCount = pageSize
      const parmas = {
        UserName: userNickName,
        PlanName: planName,
        AudioType: planType,
        Status: status,
        CreateBeginTime: createTime.startTime,
        CreateTime: createTime.endTime,
        StartBeginTime: realStartTime.startTime,
        StartTime: realStartTime.endTime,
        EndBeginTime: endTime.startTime,
        EndTime: endTime.endTime,
        TenantId: currentTenant.id,
        CourseStartTime: courseTime.startTime,
        CourseEndTime: courseTime.endTime,
        SkipCount,
        MaxResultCount,
      }
      const res = yield call(GetPlanList, parmas)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            pagination: { ...pagination, total: res.data.totalCount },
            planList: res.data.items
          }
        })
      }
    },

    *getRecord({ payload: { id, type, current } }, { all, call, put, take, select }) {
      const { ilstrainerRecord, ilsparentRecord, ilsappRecord, recordPagination, userId } = yield select(state => state.adminILsAnalysis);
      const idFieldName = type == 'ilsapp' ? 'UserTrainingPlanId' : "UserOfflineTrainingPlanId";
      const parmasData = {
        SubjectId: userId,
        [idFieldName]: id,
        SkipCount: (current - 1) * (recordPagination[type].pageSize),
        MaxResultCount: recordPagination[type].pageSize
      };
      type != 'ilsapp' && (parmasData.ForGuardian = (type == 'ilsparent'));
      const res = yield call(GetSleepRecord, parmasData);
      if (res.response.ok) {
        const totalCount = res.data.totalCount;
        const newPagination = Object.assign({}, recordPagination, { [type]: { current, pageSize: recordPagination[type].pageSize } });
        switch (type) {
          case 'ilstrainer':
            yield put({
              type: 'updateState', payload: {
                ilstrainerRecord: ilstrainerRecord && totalCount ? { items: ilstrainerRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsparent':
            yield put({
              type: 'updateState', payload: {
                ilsparentRecord: ilsparentRecord && totalCount ? { items: ilsparentRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
          case 'ilsapp':
            yield put({
              type: 'updateState', payload: {
                ilsappRecord: ilsappRecord && totalCount ? { items: ilsappRecord.items.concat(res.data.items), totalCount } : res.data,
                recordPagination: newPagination,
              }
            });
            break;
        }
      }
    },

    *getBrainRecord({ payload: { id } }, { call, put, select }) {
      const res = yield call(GetBrainRecord, { UserOfflineTrainingPlanId: id });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            brainRecord: res.data,
          },
        })
      }
    },

    *downloadExcel({ payload: _ }, { call, put, select }) {
      const { searchOption, currentTenant } = yield select(state => state.adminILsAnalysis);
      const { userNickName, planName, planType, status, createTime, realStartTime, endTime, courseTime } = searchOption
      const parmas = {
        UserName: userNickName,
        PlanName: planName,
        AudioType: planType,
        Status: status,
        TenantId: currentTenant.id,
        CreateBeginTime: createTime.startTime,
        CreateTime: createTime.endTime,
        StartBeginTime: realStartTime.startTime,
        StartTime: realStartTime.endTime,
        EndBeginTime: endTime.startTime,
        EndTime: endTime.endTime,
        CourseStartTime: courseTime.startTime,
        CourseEndTime: courseTime.endTime,
      }
      const res = yield call(DownloadExcelApi, parmas);
      if (res.response.ok) {
        const { response } = res;
        response.blob().then(blob => {
          download(blob, `优脑训练分析+${moment().format('YYYY_MM_DD HH_mm')}.xlsx`)
        })
      }
    },

    *showChartRecord({ payload }, { call, put, select }) {
      const { recordPagination } = yield select(state => state.adminILsAnalysis)
      yield put({
        type: 'updateState',
        payload: {
          ilsDrawerVisible: true,
          currentTablePlan: payload,
          ilsActiveKey: 'chart',
          shouldGetData: true,
          userId: payload.userId,
          ilsparentRecord: null,
          ilstrainerRecord: null,
          ilsappRecord: null,
          brainRecord: null,
          musicRecord: null,
          recordPagination: Object.assign(
            {},
            recordPagination,
            {
              ilstrainer: {
                current: 0,
                pageSize: 10,
              },
              ilsparent: {
                current: 0,
                pageSize: 10,
              },
              ilsapp: {
                current: 0,
                pageSize: 10,
              }
            }
          )
        }
      })
    },

    *getMusicRecord({ payload }, { call, put, select }) {
      const { id } = payload
      const res = yield call(GetMusicRecord, id)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            musicRecord: res.data
          }
        })
      }
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/analysis/iLs') {
    //       dispatch({ type: 'initPage' })
    //     }
    //   })
    // },
  },
}
