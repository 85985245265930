import DocumentTitle from 'react-document-title'
import { connect, useIntl } from 'umi'
import { useEffect } from 'react'
import moment from 'moment'
import { DatePicker, Button, Row, Col } from 'antd'
import { LeftOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'

const { RangePicker } = DatePicker

const centerDetail = ({ dispatch, vratCenterDetail, loading, location }) => {
  const intl = useIntl();

  useEffect(() => {
    dispatch({ type: 'vratCenterDetail/fetchStatistics', payload: location.query })
  }, [])

  const { centerDetail: isCenterDetailLoading } = loading.models
  const { listCenterStatistics, SearchField, TestStartTime, TestEndTime, pageIndex, pageSize, total, centerInfo } = vratCenterDetail

  const listSupportCenterStatistics = {
    // centerName: {
    //   showText: intl.formatMessage({id: 'centerName'}),
    //   showType: 'Text',
    // },
    locationName: {
      showText: intl.formatMessage({ id: 'locationCount' }),
      showType: 'Text',
    },
    FirstTestTime: {
      showText: intl.formatMessage({ id: 'firstTestTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY'
      }
    },
    LastTestTime: {
      showText: intl.formatMessage({ id: 'lastTestTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY'
      }
    },
    SubjectCount: {
      showText: intl.formatMessage({ id: 'subjectCount' }),
      showType: 'Text',
    },
    ReportAcquiredCount: {
      showText: intl.formatMessage({ id: 'reportReadyCount' }),
      showType: 'Text',
    },
    ReportAcquiredCount5_13: {
      showText: intl.formatMessage({ id: 'reportReadyCount5_13' }),
      showType: 'Text',
    },
    // RealTestCount: {
    //   showText: intl.formatMessage({id: 'realTestCount'}),
    //   showType: 'Text',
    // },
    // TrailTestCount: {
    //   showText: intl.formatMessage({id: 'trailTestCount'}),
    //   showType: 'Text',
    // },
    AttentionStatistics: {
      showText: intl.formatMessage({ id: 'attentionStatistics' }),
      showType: 'Text',
    },
    MotionStatistics: {
      showText: intl.formatMessage({ id: 'motionStatistics' }),
      showType: 'Text',
    },
  }

  const space = '\xa0\xa0' + ' | ' + '\xa0\xa0'
  const matchColor = (overViewList = [], type, color) => {
    let selectedType
    switch (type) {
      case 'Attention':
        selectedType = 'AttentionPerformanceIndex'
        break
      case 'Motion':
        selectedType = 'MotionPerformanceIndex'
        break
    }
    const found = overViewList.find(item => {
      return item.Type === selectedType && item.ResultType === color
    })
    return found || { Count: intl.formatMessage({ id: 'dataError' }) }
  }


  const showData = listCenterStatistics.map(item => {
    const { IsTotal, TestingCenter, TestLocation, OverView } = item
    const locationName = IsTotal ? 'Total' : (TestLocation ? TestLocation.Name : '')
    const { OverView: overViewList } = OverView || {}
    const attentionIndexGreen = matchColor(overViewList, 'Attention', 'Green')
    const attentionIndexYellow = matchColor(overViewList, 'Attention', 'Yellow')
    const attentionIndexRed = matchColor(overViewList, 'Attention', 'Red')
    const motionIndexGreen = matchColor(overViewList, 'Motion', 'Green')
    const motionIndexYellow = matchColor(overViewList, 'Motion', 'Yellow')
    const motionIndexRed = matchColor(overViewList, 'Motion', 'Red')
    const AttentionStatistics = attentionIndexGreen.Count + space + attentionIndexYellow.Count + space + attentionIndexRed.Count
    const MotionStatistics = motionIndexGreen.Count + space + motionIndexYellow.Count + space + motionIndexRed.Count
    return {
      AttentionStatistics,
      MotionStatistics,
      locationName,
      ...item
    }
  })

  const TableProps = {
    loading: isCenterDetailLoading,
    listData: showData,
    listSupport: listSupportCenterStatistics,
    pagination: { current: pageIndex, pageSize, total },
    onTableChange(pagi, filt = {}) {
      const { current, pageSize } = pagi
      // let data = { pagination, filter }
      // if (Object.keys(pagi).length !== 0)
      //   data = { ...data, pagination: pagi }
      // if (Object.keys(filt).length !== 0)
      //   data = { ...data, filter: filt }
      dispatch({ type: 'vratCenterDetail/filterAndFetch', payload: { pageIndex: current, pageSize } })
    },
  }

  const onTimePicker = (value) => {
    let TestStartTime = null
    let TestEndTime = null
    if (value && value.length > 0) {
      TestStartTime = moment(value[0].format('YYYY-MM-DD')).format()
      TestEndTime = moment(value[1].format('YYYY-MM-DD')).add(1, 'd').add(-1, 'seconds').format()
    } else {
      TestStartTime = null
      TestEndTime = null
    }
    dispatch({ type: 'vratCenterDetail/filterAndFetch', payload: { TestStartTime, TestEndTime } })
  }

  const ShowRangeTime = [moment(TestStartTime).isValid() ? moment(TestStartTime) : null, moment(TestEndTime).isValid() ? moment(TestEndTime) : null]

  const onClear = () => {
    dispatch({ type: 'vratCenterDetail/filterAndFetch', payload: { TestStartTime: null, TestEndTime: null, SearchField: null } })
  }

  const onBack = () => {
    dispatch({ type: 'vratCenterDetail/returnBack' })
  }

  const onDownload = () => {
    dispatch({ type: 'vratCenterDetail/downloadCenterStatistic' })
  }

  const centerName = centerInfo !== null ? centerInfo.DisplayName : ''

  return (
    <DocumentTitle title={'centerDetail'}>
      <div>
        <Row type="flex" justify="start" align="middle" gutter={20}>
          <Col>
            <Button onClick={() => onBack()}>
              <LeftOutlined />
              {intl.formatMessage({ id: 'back' })}
            </Button>
          </Col>
          <Col>
            <div>
              <span>{intl.formatMessage({ id: 'cms.statistics.centerDetail.title' })}</span>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={10} style={{ marginBottom: 20 }}>
          <Col><h3>{intl.formatMessage({ id: 'centerName' })}: {centerName}</h3></Col>
          <Col>
            <RangePicker
              format="YYYY/MM/DD"
              onChange={onTimePicker}
              value={ShowRangeTime}
            />
          </Col>
          <Col>
            <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
          </Col>

          <Col>
            <Button onClick={onDownload}>{intl.formatMessage({ id: 'downloadCenterStatistic' })}</Button>
          </Col>
        </Row>
        <MagicTable {...TableProps} />
      </div>
    </DocumentTitle>
  )
}

export default connect(({ vratCenterDetail, loading }) => ({ vratCenterDetail, loading }))(centerDetail)
