import React, { useEffect } from 'react'
import { Card, Space, Divider, Input, Select, Button, Drawer, Modal, message } from 'antd'
import { connect, history, useIntl } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { resolveSurveyData, getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import ApplyModal from './ApplyModal'
import BatchApplyModal from './BatchApplyModal'
import styles from './index.less'

const { Search } = Input;
const { Option } = Select;

const listSupport = (intl) => {
    return {
        creationTime: {
            showText: intl.formatMessage({ id: 'applicationTime' }),
            showType: 'Time',
            addtional: {
                format: 'MM/DD/YYYY HH:mm',
            },
        },
        name: {
            showText: intl.formatMessage({ id: 'name' }),
            showType: 'Text',
        },
        email: {
            showText: intl.formatMessage({ id: 'email' }),
            showType: 'Text',
        },
        phone: {
            showText: intl.formatMessage({ id: 'phone' }),
            showType: 'Text'
        },
        auditResultText: {
            showText: intl.formatMessage({ id: 'reviewStatus' }),
            showType: 'Text'
        }
    }
}

const getAuditResult = (intl, auditResult) => {
    switch (auditResult) {
        case "UnReviewed":
            return intl.formatMessage({ id: 'unprocessed' })
        case "Passed":
            return intl.formatMessage({ id: 'approved' })
        case "NotPassed":
            return intl.formatMessage({ id: 'rejected' })
        default:
            return ""
    }
}

const ApplyExamine = ({ currentUser, applyExamine, dispatch, loading }) => {
    const intl = useIntl()

    useEffect(() => {
        dispatch({ type: 'applyExamine/query' })
    }, [])

    const {
        AuditResult,
        Filter,
        pagination,
        listData,
        questionData,
        drawerVisible,
        applyModalVisible,
        selectItem,
        selectedRowKeys,
        BatchApplyVisible, } = applyExamine || {}

    const { finishTime, questionnaireAndAnswer } = questionData || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { answerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    const roles = currentUser.current ? currentUser.current.roles : []

    const { passed, unReviewed, notPassed, pagedResult } = listData || {}
    const { items } = pagedResult || {}

    const getAnswerDom = () => {
        const { questionArr = [], } = getCorrentRateAndResult(jsonString, answerContent, correctRate) || {}
        const showSurveyProps = {
            contents: questionArr,
            choice: answer && answer.answerContent ? answer.answerContent : null,
            showIcon: false
        }
        return <ShowSurvey {...showSurveyProps} />
    }


    const onSearch = () => dispatch({ type: 'applyExamine/onSearch' })

    const changeValue = async (type, value) => {
        await dispatch({
            type: 'applyExamine/updateState',
            payload: {
                [type]: value
            }
        })
        if (type === "AuditResult") {
            dispatch({ type: 'applyExamine/onSearch' })
        }
    }

    const isEnterAdmin = () => {
        return JSON.stringify(roles) !== '[]' ? roles.some(r => r.name === 'EnterpriseAdmin') : false
    }

    const tableProps = {
        loading: loading.models.applyExamine,
        listData: items ? items.map(item => {
            const { trainer, auditResult, creationTime } = item || {}
            const { name, email, countryCode, phoneNumber } = trainer || {}
            return {
                ...item,
                name,
                email,
                phone: `${countryCode || ''}${phoneNumber || ''}`,
                creationTime,
                auditResultText: getAuditResult(intl, auditResult)
            }
        }) : [],
        listSupport: listSupport(intl),
        pagination,
        rowSelection: {
            type: 'checkbox',
            selectedRowKeys,
            onChange(selectedRowKeys, selectedRows) {
                dispatch({ type: 'applyExamine/updateState', payload: { selectedRowKeys } })
            },
            getCheckboxProps(record) {
                return {
                    disabled: record.auditResult !== "UnReviewed"
                }
            }
        },
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'applyExamine/changeTable', payload: data })
        },
        Actions: [
            {
                showText: intl.formatMessage({ id: 'applicationForm' }),
                itemRender(record) {
                    return <a onClick={() => dispatch({ type: 'applyExamine/getQuestion', payload: record })}>{intl.formatMessage({ id: 'applicationForm' })}</a>
                }
            },
            {
                showText: intl.formatMessage({ id: 'detail' }),
                itemRender(record) {
                    const { trainer, auditResult } = record || {}
                    const { id } = trainer || {}
                    return auditResult === "Passed" && !isEnterAdmin() ? (
                        <a onClick={() => history.push(`/system/center/detail/trainingStaffDetail?id=${id}`)}>{intl.formatMessage({ id: 'detail' })}</a>
                    ) : null
                }
            },
            {
                showText: intl.formatMessage({ id: 'review' }),
                itemRender(record) {
                    const { auditResult } = record || {}
                    return (auditResult === "UnReviewed" && !isEnterAdmin()) ? (
                        <a onClick={() => dispatch({ type: 'applyExamine/examine', payload: record })}>{intl.formatMessage({ id: 'review' })}</a>
                    ) : null
                }
            }
        ]
    }

    const ApplyModalProps = {
        visible: applyModalVisible,
        selectItem,
        onhandleOK(value) {
            Modal.confirm({
                title: <b style={{ color: 'red' }}>{intl.formatMessage({ id: 'sureSubmitReviewResults' })}</b>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk: () => {
                    dispatch({ type: 'applyExamine/submitExamine', payload: { value, intl } })
                },
                onCancel() {
                },
            });
        },
        onhandleCancel() {
            dispatch({ type: 'applyExamine/updateState', payload: { applyModalVisible: false } })
        }
    }

    const BatchApplyModalProps = {
        visible: BatchApplyVisible,
        selectedRowKeys,
        onhandleOK(role) {
            dispatch({
                type: 'applyExamine/submitBatchApply',
                payload: {
                    isPass: true,
                    roleName: role,
                    intl
                }
            })
        },
        onhandleCancel() {
            dispatch({ type: 'applyExamine/updateState', payload: { BatchApplyVisible: false } })
        }
    }

    const batchRject = () => {
        if (selectedRowKeys.length === 0) {
            message.error(intl.formatMessage({ id: 'selectRejectedBatches' }))
            return
        }
        Modal.confirm({
            title: <b style={{ color: 'red' }}>{intl.formatMessage({ id: 'confirmedBatchesRejecting' }, { name: selectedRowKeys.length })}</b>,
            okText: intl.formatMessage({ id: 'submit' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk() {
                dispatch({
                    type: 'applyExamine/submitBatchApply',
                    payload: {
                        isPass: false,
                        roleName: intl.formatMessage({ id: 'unselectedRole' }),
                        intl
                    }
                })
            }
        })
    }

    return (
        <Card title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'trainApplyReview' })}</b>} bordered={false}>
            <Space split={<Divider type="vertical" />}>
                <span>{intl.formatMessage({ id: 'unprocessed' })} {unReviewed || 0}</span>
                <span>{intl.formatMessage({ id: 'approved' })} {passed || 0}</span>
                <span>{intl.formatMessage({ id: 'rejected' })} {notPassed || 0}</span>
                <span>{intl.formatMessage({ id: 'allApplications' })} {(unReviewed + passed + notPassed) || 0}</span>
            </Space>
            <div className={styles.search}>
                <div className={styles.searchLeft}>
                    <div className={styles.searchItem}>
                        <Search
                            value={Filter}
                            placeholder={intl.formatMessage({ id: 'searchForName_email_phone' })}
                            onSearch={onSearch}
                            style={{ width: 250 }}
                            onChange={(e) => changeValue('Filter', e.target.value)}
                        />
                    </div>
                    <div className={styles.searchItem}>
                        <div className={styles.searchItemLeft}>{intl.formatMessage({ id: 'reviewStatus' })}</div>
                        <Select
                            value={AuditResult}
                            style={{ width: 200 }}
                            onChange={value => changeValue('AuditResult', value)}
                        >
                            <Option value="">{intl.formatMessage({ id: 'all' })}</Option>
                            <Option value="UnReviewed">{intl.formatMessage({ id: 'unprocessed' })}</Option>
                            <Option value="Passed">{intl.formatMessage({ id: 'approved' })}</Option>
                            <Option value="NotPassed">{intl.formatMessage({ id: 'rejected' })}</Option>
                        </Select>
                    </div>
                    <div className={styles.searchItem}>
                        <Button onClick={() => dispatch({ type: 'applyExamine/onClear' })}>{intl.formatMessage({ id: 'clear' })}</Button>
                    </div>
                </div>
                {
                   !isEnterAdmin() && (
                    <div className={styles.searchRight}>
                        <Button style={{ marginRight: '10px' }} onClick={() => dispatch({ type: 'applyExamine/batchPass', payload: { intl } })}>{intl.formatMessage({ id: 'batchApprove' })}</Button>
                        <Button onClick={() => batchRject()}>{intl.formatMessage({ id: 'batchReject' })}</Button>
                    </div>
                   )
                }
            </div>
            <div>
                <MagicTable {...tableProps} />
            </div>
            <Drawer
                open={drawerVisible}
                width={700}
                onClose={() => dispatch({ type: 'applyExamine/updateState', payload: { drawerVisible: false } })}
                title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'applicationForm' })}</b>}
            >
                {
                    drawerVisible && getAnswerDom()
                }
            </Drawer>
            {/* 审核培训申请弹框 */}
            <ApplyModal {...ApplyModalProps} />
            {/* 批量通过审核弹框 */}
            <BatchApplyModal {...BatchApplyModalProps} />
        </Card>
    )
}

export default connect(({ applyExamine, user, loading }) => ({ applyExamine, currentUser: user.currentUser, loading }))(ApplyExamine)