import React, { useEffect, useState } from 'react'
import DocumentTitle from 'react-document-title'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import PageLoading from 'cognitiveleap-core-us/components/PageLoading'
import styles from '../center.less'
import { history, useIntl, connect } from 'umi';
import { genderLocale, countAge } from 'utils/utils'
import { showStage } from 'cognitiveleap-core-us/utils/utils'
import SubjectInfoCheckInModal from './SubjectInfoCheckInModal'
import { Input, Tooltip, Col, Divider, Button, Popover, Space } from 'antd'
import CheckInModal from './CheckInModal'
import AssignCoachModal from './AssignCoachModal'
import SelectModal from 'components/SelectModal'
import myStyles from './index.less'
const { Search } = Input


const SubjectList = ({ dispatch, subjectList, currentUser, loading }) => {

  //const [isReady, setIsReady] = useState(false)

  const intl = useIntl();

  useEffect(() => {
    //setIsReady(true)
    dispatch({ type: 'subjectList/loadPage' })

    return () => {
      dispatch({ type: 'subjectList/updateState', payload: { modalVisible: false } })
    }
  }, [])

  // if (!isReady) {
  //   return <PageLoading />
  // }

  const { subjectList: isLoading } = loading.models
  const {
    list,
    pagination,
    modalType,
    content,
    modalVisible,
    searchField,
    outpatientNumber,
    coaches,
    assignModalVisible,
    checkInModalVisible,
    selectedSubjectInfo,
    filters,
    school,
    selectSubjectId,
    coachName,
    guardianName,
    popoverVisible,
    currentRecord,
    checkSubjectInfo,
    subjectInfoCheckInModalVisible
  } = subjectList

  const {
    auth,
    changedTenant: { projectStatus, institutionType },
    current
  } = currentUser || {};
  const { medicalPublicWithName = false, type } = current || {}
  const { grantedPolicies } = auth;
  const isEnterprise = type === 'Enterprise'

  const hasCheckInPermission = grantedPolicies['Business.CheckIn'] === true
  const hasSubjectDetailPermission = grantedPolicies['Business.SubjectManagement'] === true
  const hasAddVRATCasePermission = grantedPolicies['VrClassroom.TestRequest.Create'] === true
  const hasCheckInWritePermission = grantedPolicies['Business.CheckIn.InOrOut'] === true
  const hasAssignCoachPermission = grantedPolicies['Business.SubjectManagement.UserCoach.Assign'] === true
  const hasAddVratTestPermission = grantedPolicies['PFE.UserManagement.SubjectList.AddVratTest'] === true
  const hasListOtherPermission = grantedPolicies['PFE.UserManagement.SubjectList.ListOther'] === true
  const vratProjectStatus = projectStatus.find(item => item.name == 'Project.Vrat')['status'];
  const selectItem = selectSubjectId ? list.find(item => item.id === selectSubjectId) : null

  let listSupport = {
    name: {
      showText: intl.formatMessage({ id: 'name' }),
      showType: 'Text',
    },
    localedGender: {
      showText: intl.formatMessage({ id: 'gender' }),
      showType: 'Text',
    },
    showStage: {
      showText: intl.formatMessage({ id: 'age' }),
      showType: 'Text',
      render: (text, record) => {
        const { birthDay } = record || {}
        return (
          <span>
            {countAge(birthDay, intl)}
          </span>
        )
      }
    },
    // picture: {
    //   showText: intl.formatMessage({ id: 'photo' }),
    //   showType: 'Image',
    // },
    birthDay: {
      showText: intl.formatMessage({ id: 'birthday' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY',
      },
    },
    guardian: {
      showText: intl.formatMessage({ id: 'guardian' }),
      showType: 'Text',
    },
    coachName: {
      showText: intl.formatMessage({ id: 'trainer' }),
      showType: 'Text',
      render(text, record, index) {
        const { coach } = record || {}
        const coachNames = coach.map(item => item.name)
        return (
          <div style={{ cursor: 'pointer' }}>
            <Tooltip placement="top" title={coachNames && coachNames.join('；')}><span>{text}</span></Tooltip>
          </div>
        )
      }
    },
    // isCheckIn: {
    //   showText: intl.formatMessage({ id: 'checkInStatus' }),
    //   showType: 'Bool',
    //   addtional: {
    //     boolArray: [intl.formatMessage({ id: 'notCheckedIn' }), intl.formatMessage({ id: 'checkedIn' })],
    //   },
    //   filter: {
    //     hasFilter: true,
    //     filterOptions: [{ text: intl.formatMessage({ id: 'checkedIn' }), value: true }, { text: intl.formatMessage({ id: 'notCheckedIn' }), value: false }],
    //     filterMultiple: false,
    //   }
    // },
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: 'MM/DD/YYYY HH:mm',
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          { text: intl.formatMessage({ id: 'bound' }), value: 'Bind' },
          { text: intl.formatMessage({ id: 'noLongerAuthorized' }), value: 'UnBind' },
          { text: intl.formatMessage({ id: 'cancelled' }), value: 'Logout' }
        ],
        filterMultiple: false,
      }
    }
  }

  if (institutionType === 'Medical') {
    listSupport = {
      outpatientNumber: {
        showText: intl.formatMessage({ id: 'clinicNumber' }),
        showType: 'Text',
      },
      ...listSupport
    }
  }

  const modalProps = {
    loading: isLoading,
    content,
    title: intl.formatMessage({ id: 'subject.newVRATTest' }),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'subjectList/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'subjectList/submitAddTest', payload: value })
    }
  }

  const checkInModalProps = {
    loading: isLoading,
    visible: checkInModalVisible,
    content: selectedSubjectInfo,
    onCancel() {
      dispatch({ type: 'subjectList/updateState', payload: { checkInModalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'subjectList/checkIn', payload: value })
    },
  };

  const subjectInfoCheckInModalProps = {
    subjectInfo: checkSubjectInfo,
    visible: subjectInfoCheckInModalVisible,
    onCancel() {
      dispatch({
        type: 'subjectList/updateState',
        payload: { subjectInfoCheckInModalVisible: false },
      });
    },
    onOk(value) {
      dispatch({ type: 'subjectList/submitAddSubject', payload: value });
    },
    loading: isLoading,
  };

  const assginModalProps = {
    visible: assignModalVisible,
    loading: isLoading,
    coaches,
    targetKeys: selectItem ? selectItem.coach.map(item => item.id) : [],
    onCancel() {
      dispatch({ type: 'subjectList/updateState', payload: { coaches: [], assignModalVisible: false, } })
    },
    onOk(data) {
      dispatch({ type: 'subjectList/submitAssignCoach', payload: data })
    }
  }

  const searchProps = {
    placeholder: intl.formatMessage({ id: 'searchSubject' }),
    onSearch(value) {
      dispatch({ type: 'subjectList/filterFetch', payload: { searchField: value } })
    },
    onChange(value) {
      dispatch({ type: 'subjectList/updateState', payload: { searchField: value.target.value } })
    },
    value: searchField,
  }

  const searchOutpatientNumberProps = {
    placeholder: intl.formatMessage({ id: 'searchClinicNumber' }),
    onSearch(value) {
      dispatch({ type: 'subjectList/filterFetch', payload: { outpatientNumber: value } })
    },
    onChange(value) {
      dispatch({ type: 'subjectList/updateState', payload: { outpatientNumber: value.target.value } })
    },
    value: outpatientNumber,
  }

  // const schoooSearchProps = {
  //   placeholder: intl.formatMessage({ id: 'school' }),
  //   onSearch(value) {
  //     dispatch({ type: 'subjectList/filterFetch', payload: { school: value } })
  //   },
  //   onChange(value) {
  //     dispatch({ type: 'subjectList/updateState', payload: { school: value.target.value } })
  //   },
  //   value: school,
  // }

  const guardianSearchProps = {
    placeholder: intl.formatMessage({ id: 'guardian' }),
    onSearch(value) {
      dispatch({ type: 'subjectList/filterFetch', payload: { guardianName: value } })
    },
    onChange(value) {
      dispatch({ type: 'subjectList/updateState', payload: { guardianName: value.target.value } })
    },
    value: guardianName,
  }

  const onClear = () => {
    dispatch({ type: 'subjectList/clearAllFilterAndSorter' })
  }

  const searchCoachProps = {
    placeholder: intl.formatMessage({ id: 'coachName' }),
    onSearch(value) {
      dispatch({ type: 'subjectList/filterFetch', payload: { coachName: value } })
    },
    onChange(value) {
      dispatch({ type: 'subjectList/updateState', payload: { coachName: value.target.value } })
    },
    value: coachName,
  }

  const getShowText = (isLogout, isBind) => {
    const cancelShowText = intl.formatMessage({ id: 'cancelled' })
    const boundShowText = intl.formatMessage({ id: 'bound' })
    const noLongerAuthorizedShowText = intl.formatMessage({ id: 'noLongerAuthorized' })

    if (isLogout) return cancelShowText
    else {
      if (isBind) return boundShowText
      else return noLongerAuthorizedShowText
    }

  }

  const getPopoverContent = (record) => {
    const { name, id } = record
    return <Space direction="vertical" >
      <div>{intl.formatMessage({ id: 'assignVratTest' }, { name })}</div>
      <div style={{ textAlign: 'right', marginTop: '15px' }}>
        <Button size="small" type="primary" onClick={() => {
          dispatch({ type: 'subjectList/submitAddTest', payload: { id, intl } })
          dispatch({ type: 'subjectList/updateState', payload: { popoverVisible: false } })
        }}>
          {intl.formatMessage({ id: 'confirm' })}
        </Button>
      </div>
    </Space>
  }

  const tableProps = {
    loading: loading.models.subjectList,
    listData: list.map(item => {
      const { gender, coach, isBind, isLogout, stage, guardians } = item
      const coachNames = coach.map(item => item.name)
      const showCoachNames = coachNames.length > 2 ? coachNames.slice(0, 2).join('；') + '...' : coachNames.join('；')
      const showGenderText = genderLocale(gender, intl)
      const guardian = guardians.map(item => item.name).join(', ')
      return {
        ...item,
        localedGender: showGenderText,
        coachName: showCoachNames,
        status: getShowText(isLogout, isBind),
        showStage: showStage(stage),
        guardian
      }
    }),
    filters,
    listSupport,
    pagination,
    scroll: true,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination, filters }
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'subjectList/changeTable', payload: data })
    },
    Actions: hasListOtherPermission ? [
      hasSubjectDetailPermission ? {
        showText: intl.formatMessage({ id: 'lookDetail' }),
        onClick(record) {
          history.push({
            pathname: '/system/center/detail/subjectDetail',
            query: {
              id: record.id,
            },
          })
        },
      } : null,
      // {
      //   showText: '签到',
      //   itemRender(record) {
      //     const { isCheckIn, isBind, isLogout } = record
      //     const isNotLogoutAndBind = isBind && !isLogout;
      //     const checkInShowText = intl.formatMessage({ id: 'checkIn' })
      //     return hasCheckInWritePermission && !isCheckIn && isNotLogoutAndBind ? (
      //       <span key="checkin">
      //         <a
      //           onClick={() => {
      //             dispatch({
      //               type: 'subjectList/updateState',
      //               payload: { checkInModalVisible: true, selectedSubjectInfo: record },
      //             })
      //           }}
      //         >
      //           {checkInShowText}
      //         </a>
      //       </span>
      //     ):null
      //   },
      // },
      {
        showText: 'assignTrainers',
        itemRender(record) {
          const { isBind, isLogout } = record
          const assignTrainersShowText = intl.formatMessage({ id: 'assignTrainers' })
          const isNotLogoutAndBind = isBind && !isLogout;
          return hasAssignCoachPermission && isNotLogoutAndBind ? (
            <a
              onClick={() => {
                dispatch({ type: 'subjectList/assignCoach', payload: record.id })
              }}
            >
              {assignTrainersShowText}
            </a>
          ) : null
        },
      },
      hasAddVRATCasePermission && vratProjectStatus && hasAddVratTestPermission ? {
        showText: 'subject.newVRATTest',
        itemRender(record) {
          const { isBind, isLogout, id } = record
          const isNotLogoutAndBind = isBind && !isLogout;
          return isNotLogoutAndBind ? (
            <Popover
              trigger="click"
              open={id === currentRecord.id && popoverVisible}
              content={() => getPopoverContent(record)}
              title={null}
              onOpenChange={(visible) => dispatch({
                type: 'subjectList/updateState', payload: { popoverVisible: visible, currentRecord: record }
              })}>
              <a>{intl.formatMessage({ id: 'subject.newVRATTest' })}</a>
            </Popover>
          )
            : null
        },
      } : null,

    ] : null,
  }

  const onGoToCheckIn = () => {
    history.push({
      pathname: '/system/center/detail/multiCheck',
    })
  }

  const addChild = () => {
    history.push('/system/vratProcess?type=addParent')
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'subjectList' })}>
      <div>
        <h1 className={styles.pageName}>{intl.formatMessage({ id: 'subjectList' })}</h1>
        <Divider />
        <div className={styles.table}>
          <div className={myStyles.search}>
            <div>
              {institutionType === 'Medical' && <Search {...searchOutpatientNumberProps} style={{ height: 32, width: 220, marginRight: '10px' }} />}
              <Search {...searchProps} style={{ height: 32, width: 220, marginRight: '10px' }} />
              <Search {...searchCoachProps} style={{ height: 32, width: 220, marginRight: '10px' }} />
              {/* <Search {...schoooSearchProps} style={{ height: 32, width: 220, marginRight: '10px' }} /> */}
              <Search {...guardianSearchProps} style={{ height: 32, width: 220, marginRight: '10px' }} />
              <Button onClick={onClear}>{intl.formatMessage({ id: 'clear' })}</Button>
            </div>
            <div>
                <Button type="primary" onClick={addChild}>
                  {intl.formatMessage({ id: 'newSubject' })}
                </Button>
            </div>
            {/* <div>
              {
                hasCheckInPermission ? <Button type="primary" onClick={onGoToCheckIn}>
                  {intl.formatMessage({ id: 'checkInList' })}
                </Button> : null
              }
            </div> */}
          </div>
          <MagicTable {...tableProps} />
        </div>
        <MagicFormModal {...modalProps} />
        <CheckInModal {...checkInModalProps} />
        <SubjectInfoCheckInModal {...subjectInfoCheckInModalProps} />
        <AssignCoachModal {...assginModalProps} />
        {/* <SelectModal {...assginModalProps} />  */}
      </div>
    </DocumentTitle>
  )
}

export default connect(({ subjectList, user, loading }) => ({ subjectList, currentUser: user.currentUser, loading }))(SubjectList)
