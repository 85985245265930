import React, { useEffect } from 'react'
import { Card, Space, Divider, Input, Select, Button, Spin, Drawer } from 'antd'
import { connect } from 'umi'
import { resolveSurveyData, getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'
import AnalysisTenantSelect from 'components/AnalysisTenantSelect'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import { history } from 'umi';
import styles from './index.less'

const { Search } = Input;
const { Option } = Select;

const listSupport = {
    creationTime: {
        showText: '申请时间',
        showType: 'Time',
        addtional: {
            format: 'MM/DD/YYYY HH:mm',
        },
    },
    name: {
        showText: '姓名',
        showType: 'Text',
    },
    email: {
        showText: '邮箱',
        showType: 'Text',
    },
    phone: {
        showText: '手机',
        showType: 'Text'
    },
    auditResultText: {
        showText: '审核情况',
        showType: 'Text'
    }
}

const getAuditResult = (auditResult) => {
    switch (auditResult) {
        case "UnReviewed":
            return "未审核"
        case "Passed":
            return "审核通过"
        case "NotPassed":
            return "审核拒绝"
        default:
            return ""
    }
}

const ApplyReview = ({ currentUser, dispatch, applyReview, loading }) => {
    const { hostRoles = [] } = currentUser;
    const isSupervisor = hostRoles.find(item => item.name === "supervisor");

    useEffect(() => {
        dispatch({ type: 'applyReview/query' })
    }, [])

    const {
        currentTenant,
        listData,
        pagination,
        Filter,
        AuditResult,
        drawerVisible,
        questionData
    } = applyReview || {}

    const { finishTime, questionnaireAndAnswer } = questionData || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { answerContent } = answer || {}
    const { jsonString, correctRate } = questionnaire || {}

    const { passed, unReviewed, notPassed, pagedResult } = listData || {}
    const { items } = pagedResult || {}

    const getAnswerDom = () => {
        const { questionArr = [], } = getCorrentRateAndResult(jsonString, answerContent, correctRate) || {}
        const showSurveyProps = {
            contents: questionArr,
            choice: answer && answer.answerContent ? answer.answerContent : null,
            showIcon: false
        }
        return <ShowSurvey {...showSurveyProps} />
    }

    const onSearch = () => dispatch({ type: 'applyReview/onSearch' })

    const changeValue = async (type, value) => {
        await dispatch({
            type: 'applyReview/updateState',
            payload: {
                [type]: value
            }
        })
        if (type === "AuditResult") {
            dispatch({ type: 'applyReview/onSearch' })
        }
    }

    const tableProps = {
        listData: items ? items.map(item => {
            const { trainer, auditResult, creationTime } = item || {}
            const { name, email, countryCode, phoneNumber } = trainer || {}
            return {
                ...item,
                name,
                email,
                phone: `${countryCode || ''}${phoneNumber || ''}`,
                creationTime,
                auditResultText: getAuditResult(auditResult)
            }
        }) : [],
        listSupport,
        pagination,
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { pagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'applyReview/changeTable', payload: data })
        },
        Actions: [
            {
                showText: '培训申请表',
                itemRender(record) {
                    return <a onClick={() => dispatch({ type: 'applyReview/getQuestion', payload: record })}>培训申请表</a>
                }
            },
            {
                showText: '详情',
                itemRender(record) {
                    const { trainer, auditResult } = record || {}
                    const { id } = trainer || {}
                    return auditResult === "Passed" ? (
                        <a onClick={() => history.push(`/system/manage/trainingStaffDetail?id=${id}`)}>详情</a>
                    ) : null
                }
            }
        ]
    }

    return (
        <Card
            title={<b style={{ fontSize: '20px' }}>培训申请审核</b>}
            bordered={false}
            extra={
                !isSupervisor && (
                    <AnalysisTenantSelect
                        showAll={true}
                        currentTenant={currentTenant}
                        onChange={(e) => {
                            dispatch({ type: 'applyReview/query', payload: { id: e } })
                        }}
                    />
                )
            }
        >
            <Spin spinning={loading.models.applyReview}>
                <Space split={<Divider type="vertical" />}>
                    <span>未审核 {unReviewed || 0}</span>
                    <span>审核通过 {passed || 0}</span>
                    <span>审核拒绝 {notPassed || 0}</span>
                    <span>全部申请 {(unReviewed + passed + notPassed) || 0}</span>
                </Space>
                <div className={styles.search}>
                    <div className={styles.searchItem}>
                        <Search
                            value={Filter}
                            placeholder="搜索姓名/邮箱/手机"
                            onSearch={onSearch}
                            style={{ width: 250 }}
                            onChange={(e) => changeValue('Filter', e.target.value)}
                        />
                    </div>
                    <div className={styles.searchItem}>
                        <div className={styles.searchItemLeft}>审核情况</div>
                        <Select
                            value={AuditResult}
                            style={{ width: 200 }}
                            onChange={value => changeValue('AuditResult', value)}
                        >
                            <Option value="">全部</Option>
                            <Option value="UnReviewed">未审核</Option>
                            <Option value="Passed">审核通过</Option>
                            <Option value="NotPassed">审核拒绝</Option>
                        </Select>
                    </div>
                    <div className={styles.searchItem}>
                        <Button onClick={() => dispatch({ type: 'applyReview/onClear' })}>清空</Button>
                    </div>
                </div>
                <div>
                    <MagicTable {...tableProps} />
                </div>
                <Drawer
                    open={drawerVisible}
                    width={700}
                    onClose={() => dispatch({ type: 'applyReview/updateState', payload: { drawerVisible: false } })}
                    title={<b style={{ fontSize: '20px' }}>培训申请表</b>}
                >
                    {
                        drawerVisible && getAnswerDom()
                    }
                </Drawer>
            </Spin>
        </Card>
    )
}

export default connect(({ user, applyReview, loading }) => ({ currentUser: user.currentUser, applyReview, loading }))(ApplyReview)