import React from 'react';
import { Card, Divider } from 'antd';
import { useIntl } from 'umi';

const GuardianInfoCard = ({ guardianInfo }) => {
  const intl = useIntl();
  const {
    fullName,
    email,
    city,
    sourceText,
    guardianRoleRelationshipLocale,
    emergencyContactName,
    emergencyContactPhoneNumber,
    emergencyContactRoleRelationshipLocale,
  } = guardianInfo;
  const { firstName, lastName } = fullName || {};

  return (
    <Card bordered={false} hoverable={false}>
      {(firstName || lastName) && (
        <p>{`${intl.formatMessage({ id: 'name' })}: ${firstName || ''} ${
          lastName || ''
        }`}</p>
      )}
      {email && <p>{`${intl.formatMessage({ id: 'email' })}: ${email}`}</p>}
      {guardianRoleRelationshipLocale && (
        <p>{`${intl.formatMessage({
          id: 'relationship',
        })}: ${guardianRoleRelationshipLocale}`}</p>
      )}
      {city && <p>{`${intl.formatMessage({ id: 'city' })}: ${city}`}</p>}
      {sourceText && (
        <p>{`${intl.formatMessage({
          id: 'Referral Information',
        })}: ${sourceText}`}</p>
      )}

      {(emergencyContactName || emergencyContactPhoneNumber) && (
        <p>
          <Divider>
            {intl.formatMessage({ id: 'Contact Person Information' })}
          </Divider>
          {emergencyContactName && (
            <p>{`${intl.formatMessage({
              id: 'name',
            })}: ${emergencyContactName}`}</p>
          )}
          {emergencyContactPhoneNumber && (
            <p>{`${intl.formatMessage({
              id: 'phone',
            })}: ${emergencyContactPhoneNumber}`}</p>
          )}
          {emergencyContactRoleRelationshipLocale && (
            <p>{`${intl.formatMessage({
              id: 'relationship',
            })}: ${emergencyContactRoleRelationshipLocale}`}</p>
          )}
        </p>
      )}
    </Card>
  );
};

export default GuardianInfoCard;
