import { formatConfig } from '../../config';
import moment from 'moment'
import { GetILSTotalData, GetILSAnalysis } from 'services/rocketService/Analysis'

export default {
  namespace: 'iLsAnalysis',
  state: {
    currentTag: 'Month',
    datePickerIsOpen: false,
    dateMonthPickerData: moment(),
    dateYearPickerData: moment(),
    analysisData: null,
    keyIndicators: null,
    currentIlsId: null,
    currentonlinePlanId: null,
    audioType: '',
    userId: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *initPage({ payload: _ }, { call, put, select }) {
      yield put({ type: 'loadPage' })
      yield put({ type: 'loadAnalysisData' })
    },

    *loadPage({ payload: data }, { call, put, select }) {
      const res = yield call(GetILSTotalData);
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            keyIndicators: res.data,
          }
        })
      }
    },

    *loadAnalysisData(_, { call, put, select }) {
      const { currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.iLsAnalysis);
      const date = {
        "Year": dateYearPickerData,
        "Month": dateMonthPickerData
      }[currentTag];

      const res = yield call(GetILSAnalysis, {
        Type: currentTag,
        Year: parseInt(moment(date).format('YYYY')),
        Month: parseInt(moment(dateMonthPickerData).format('MM'))
      });

      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            analysisData: res.data
          }
        })
      }
    },

    *handlePanelChange({ payload: { time } }, { call, put, select }) {
      const { datePickerIsOpen, currentTag, dateMonthPickerData, dateYearPickerData } = yield select(state => state.iLsAnalysis);
      yield put({
        type: 'updateState',
        payload: {
          dateMonthPickerData: {
            "Year": dateMonthPickerData,
            "Month": moment(time)
          }[currentTag],
          dateYearPickerData: {
            "Year": moment(time),
            "Month": dateYearPickerData
          }[currentTag],
          datePickerIsOpen: !datePickerIsOpen
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },

    *changeTag({ payload: { key } }, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: {
          currentTag: key
        }
      })
      yield put({ type: 'loadAnalysisData' });
    },
  },
  subscriptions: {
    // setupHistory({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/menu/analysis/iLs') {
    //       dispatch({ type: 'initPage' })
    //     }
    //   })
    // },
  },
}
