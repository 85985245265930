import React, { useEffect, useState } from 'react';
import { connect } from 'umi';
import { Spin } from 'antd';
import PersonalFiles from 'cognitiveleap-core-us/components/ArchivesV2/PersonalFiles';
import UserDataPanel from 'cognitiveleap-core-us/components/ArchivesV2/UserDataPanel';
import Loading from 'components/Loading';
import Question from 'components/Question';
// import EditChildInfoModal from 'components/EditChildInfoModal';
import styles from './index.less';

const ArchivesV2 = ({ archivesV2, dispatch, currentUser, location }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch({ type: 'archivesV2/updateState', payload: location.query });
  }, []);

  const { subjectId, from } = archivesV2;
  const roles = currentUser.current ? currentUser.current.roles : [];
  const isEnterAdmin = () => {
    return JSON.stringify(roles) !== '[]'
      ? roles.some((r) => r.name === 'EnterpriseAdmin')
      : false;
  };

  const handleOnclick = (newVisible) => {
    setVisible(newVisible);
  };

  const archivesProps = {
    subjectId,
    Loading,
    Question,
    from,
    isEnterAdmin: isEnterAdmin(),
  };

  return (
    <div className={styles.archives}>
      <Spin spinning={subjectId === null}>
        {subjectId && <PersonalFiles {...archivesProps} />}
        {subjectId && <UserDataPanel {...archivesProps} />}
      </Spin>
    </div>
  );
};

export default connect(({ archivesV2, user }) => ({
  archivesV2,
  currentUser: user.currentUser,
}))(ArchivesV2);
