import React from 'react'
import { Modal, Row, Col } from 'antd'
import GuardianInfoCard from 'components/GuardianInfoCard'
import SubjectInfoCard from 'components/SubjectInfoCard'
import { useIntl } from 'umi'

const ConfirmModal = ({
    guardianInfo,
    subjectList,
    visible,
    onCancel,
    onOk,
}) => {
    const intl = useIntl()

    const onSubmit = () => {
        onOk()
    }

    const onQuit = () => {
        onCancel()
    }

    const showSubjectList = (subjectList) => {
        if (subjectList.length > 0)
            return (
                <div>
                    <p>{intl.formatMessage({ id: 'childrenInformation' })}</p>
                    <Row gutter={16}>
                        {
                            subjectList.map((item, index) => {
                                return <Col span={12} key={item.name + index}>
                                    <SubjectInfoCard subjectInfo={item} />
                                </Col>
                            })
                        }
                    </Row>
                </div>
            )

    }

    return (
        <Modal
            maskClosable={false}
            open={visible}
            title={intl.formatMessage({ id: 'pleaseResureInfo' })}
            okText={intl.formatMessage({ id: 'submit' })}
            cancelText={intl.formatMessage({ id: 'cancel' })}
            onCancel={onQuit}
            onOk={onSubmit}
            zIndex={102}
        >
            <div style={{ margin: '0 0 20px 0', color: '#F96B4B' }}>{intl.formatMessage({ id: 'submitTip' })}</div>
            {guardianInfo != null ?
                <div>
                    <p>{intl.formatMessage({ id: 'parentInformation' })}</p>
                    <GuardianInfoCard guardianInfo={guardianInfo} />
                </div>
                : null}
            {subjectList.length > 0 ? showSubjectList(subjectList) : null}
        </Modal>
    )
}

export default ConfirmModal
