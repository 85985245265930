import { history } from 'umi';

import {
  GetReminderTemplate,
  PostReminderTemplate,
  GetUpdateReminderTemplate,
  PutReminderTemplate,
  DeleteReminderTemplate
} from 'services/rocketService/Reminder'

import { matchDynamicForm } from 'cognitiveleap-core-us/utils/utils'

const content = {
  Properties: [
    {
      EditorType: 'DatePicker',
      ShowTitle: '时间',
      FormKey: 'dateTime',
      AdditionalData: null,
      Value: null,
      Setting: {
        DateType: 'time',
      },
      Description: null,
    },
    {
      EditorType: 'Dropdown',
      ShowTitle: '类型',
      FormKey: 'type',
      AdditionalData: null,
      Value: null,
      Setting: {
        /// Normal, Daily, Weekly, Schedule
        DropdownOptions: [
          {
            Value: 'Normal',
            Id: 'Normal',
          },
          {
            Value: 'Daily',
            Id: 'Daily',
          },
          {
            Value: 'Weekly',
            Id: 'Weekly',
          },
          {
            Value: 'Schedule',
            Id: 'Schedule',
          },
        ],
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'title',
      FormKey: 'title',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'body',
      FormKey: 'body',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'description',
      FormKey: 'description',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'payload',
      FormKey: 'payload',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'sound',
      FormKey: 'sound',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'enabledSound',
      FormKey: 'enabledSound',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'vibrate',
      FormKey: 'vibrate',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Bool',
      ShowTitle: 'enabled',
      FormKey: 'enabled',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'sourceType',
      FormKey: 'sourceType',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'sourceUrl',
      FormKey: 'sourceUrl',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'applicationType',
      FormKey: 'applicationType',
      AdditionalData: null,
      Value: null,
      Setting: {},
      Description: null,
    },
  ],
}

export default {
  namespace: 'reminder',
  state: {
    list: [],
    listSupport: {
      dateTime: {
        showText: '时间',
        showType: 'Time',
        addtional: {
          format: 'MM/DD/YYYY HH:mm:ss',
        },
      },
      type: {
        showText: '类型',
        showType: 'Text',
      },
      title: {
        showText: 'title',
        showType: 'Text',
      },
      body: {
        showText: 'body',
        showType: 'Text',
      },
      description: {
        showText: 'description',
        showType: 'Text',
      },
      payload: {
        showText: 'payload',
        showType: 'Text',
      },
      sound: {
        showText: 'sound',
        showType: 'Text',
      },
      enabledSound: {
        showText: 'enabledSound',
        showType: 'Text',
      },
      vibrate: {
        showText: 'vibrate',
        showType: 'Bool',
      },
      enabled: {
        showText: 'enabled',
        showType: 'Bool',
      },
      sourceType: {
        showText: 'sourceType',
        showType: 'Text',
      },
      sourceUrl: {
        showText: 'sourceUrl',
        showType: 'Text',
      },
      applicationType: {
        showText: 'applicationType',
        showType: 'Text',
      },
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    modalVisible: false,
    content: null,
    modalType: 'add',
    editId: null,
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },

  effects: {
    *query({ payload: queryData }, { take, put, call, select }) {
      const { pagination } = yield select(state => state.reminder)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetReminderTemplate, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items },
        })
      }
    },

    *onAddReminder(_, { take, put, call, select }) {
      yield put({
        type: 'updateState',
        payload: { modalType: 'add', content: content, modalVisible: true },
      })
    },

    *onEditReminder({ payload }, { take, put, call, select }) {
      const res = yield call(GetUpdateReminderTemplate, payload)
      if (res.response.ok) {
        const form = matchDynamicForm(content, {
          ...res.data,
        })
        yield put({
          type: 'updateState',
          payload: { modalType: 'edit', content: form, modalVisible: true, editId: payload },
        })
      }
    },

    *onDeleteReminder({ payload }, { take, put, call, select }) {
      const res = yield call(DeleteReminderTemplate, payload)
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    goToReminderDetail({ payload: id }, { take, put, call, select }) {
      history.push({
        pathname: '/system/manage/reminderDetail',
        query: { reminderId: id },
      })
    },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, editId } = yield select(state => state.reminder)
      let res
      if (modalType === 'edit') {
        res = yield call(PutReminderTemplate, data, editId)
      } else {
        res = yield call(PostReminderTemplate, data)
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      }
    },
  },

  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/reminder') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}
