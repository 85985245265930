import React from 'react';
import style from './index.less';
import { DatePicker, Tag } from 'antd'
import { useIntl } from 'umi'
import { dateConfig, tagsData } from '../config';
import moment from 'moment';

const { CheckableTag } = Tag;

const AnalysisChoiceDate = ({
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    datePickerIsOpen,
    changeTag,
    handleOpenChange,
    handlePanelChange
}) => {
    const intl = useIntl()

    const disabledDate = (current) => {
        return current && current < moment('2018-01') || current > moment().endOf('month');
    };

    const _handlePanelChange = (time) => {
        let _time = time;

        if (currentTag === 'Year') {
            if (moment(time).isAfter(moment(), 'year')) {
                _time = moment();
            } else if (moment(time).isBefore(moment('2018'), 'year')) {
                _time = moment('2018');
            }
        } else {
            if (moment(time).isAfter(moment(), 'month')) {
                _time = moment();
            } else if (moment(time).isBefore(moment('2018-01'), 'month')) {
                _time = moment('2018-01');
            }
        }

        handlePanelChange(_time)
    }

    return (
        <div className={style.trendAnalysisChoice}>
            <div className={style.trendAnalysisChoiceItem}>
                <span style={{ color: '#8C8C8C', marginRight: '3px' }}>{intl.formatMessage({ id: 'Period' })}:{' '}</span>
                {
                    tagsData(intl).map(tag => (
                        <CheckableTag
                            key={tag.key}
                            checked={[currentTag].indexOf(tag.key) > -1}
                            onChange={checked => changeTag(tag, checked)}
                        >
                            {tag.title}
                        </CheckableTag>
                    ))
                }
            </div>
            <div>
                { dateConfig(intl)[currentTag].choice }
                <DatePicker
                    value={
                        {
                            "Year": dateYearPickerData,
                            "Month": dateMonthPickerData
                        }[currentTag]
                    }
                    mode={dateConfig(intl)[currentTag].small}
                    format={dateConfig(intl)[currentTag].format}
                    placeholder={dateConfig(intl)[currentTag].placeholder}
                    open={datePickerIsOpen}
                    onOpenChange={handleOpenChange}
                    onPanelChange={_handlePanelChange}
                    allowClear={false}
                    disabledDate={disabledDate}
                />
            </div>
        </div>
    )
}

export default AnalysisChoiceDate;
