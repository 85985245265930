import React from 'react'
import { Drawer, Spin, Button, Form, TimePicker, Radio, Input, Divider, Card, Space } from 'antd'
import { showAttributeName, showAttributeDOM, formatSubmitValue, getFeedbackInitValue, getFeedbackValue } from 'utils/feedback'
import { range } from "utils/utils"
import { useIntl } from 'umi'
import moment from 'moment';

const FormItem = Form.Item;
const { TextArea } = Input;

const GuardianFeedback = ({
    id,
    loading,
    loadingKey,
    allData,
    onSubmit,
    guardianHasDone
}) => {
    const intl = useIntl()

    if (!allData) {
        return null;
    }

    const { feedbackProperties = [], num, iLs, userOfflineTrainingPlan, feedbacks, isEdit, hasDone } = allData;
    const onClickSubmit = (values) => {
        let feedbacks = Object.keys(values).map(keyItem => {
            const [type, id] = keyItem.split('~');
            const currentData = feedbackProperties.find(item => item.id === id)
            return {
                programFeedbackPropertyId: id,
                value: formatSubmitValue(type, values[keyItem], currentData)
            }
        })
        feedbacks = feedbacks.filter(item => item.value != null);
        onSubmit({
            feedbacks,
            num,
            userOfflineTrainingPlanId: userOfflineTrainingPlan.id,
            forGuardian: true
        });
    }

    const feedArr = feedbacks && feedbacks[0] ? feedbacks[0].feedbackProperties : []

    const getFeedbackItem = feedbackItem => {
        const { id, displayName, description, isRequired, type, extraProperties } = feedbackItem
        let params = {}
        if (type === 'TimePicker') {
            const maxHours = moment().format("HH")
            const maxHoursArr = range(0, parseInt(maxHours) - 1)
            params = {
                minStartTime: moment(moment(allData.minStartTime).local().format('YYYY/MM/DD')),
                format: 'YYYY-MM-DD HH:mm',
                minuteStep: 30,
                placeholder: intl.formatMessage({ id: "pleaseSelectDateAndTime"}),
                // disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23].concat(maxHoursArr),
                showNow: false,
                defaultValue: moment(`${moment().format("YYYY/MM/DD")} ${moment().format("HH")}:00`)
            }
        }
        const initialValue = feedArr.find(item => item.programFeedbackPropertyId === id)?.value
        const value = initialValue ? getFeedbackValue(type, initialValue) : null
        const valuePropName = ['VideoPicker', 'ImagePicker',].includes(type) ? "fileList" : "value"

        return (
            <FormItem
                label={(
                    <Space direction='vertical' size={1}>
                        {displayName}
                        {description && <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>{description}</div>}
                    </Space>
                )}
                key={id}
                name={`${type}~${id}`}
                initialValue={value}
                // valuePropName={valuePropName}
                valuePropName={"value"}
                rules={[
                    {
                        required: isRequired,
                        message: `${displayName}${intl.formatMessage({ id: "mandatoryField"})}`
                    }
                ]}
            >
                {showAttributeDOM(type, extraProperties, params, value)}
            </FormItem>
        )
    }

    return (
        <Card>
            <React.Fragment>
                {
                    allData.hasOwnProperty("isEdit") ?
                        (
                            isEdit ?
                                <Form
                                    onFinish={onClickSubmit}
                                    layout="vertical"
                                >
                                    {feedbackProperties.map(item => getFeedbackItem(item))}
                                    <center>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading && loadingKey.indexOf(id) > -1}
                                        >
                                            {intl.formatMessage({ id: 'submitOther'})}
                                        </Button>
                                    </center>
                                </Form>
                                :
                                <div>
                                    <p>{intl.formatMessage({ id: "feedbackTime"})}<b>{moment(feedbacks[feedbacks.length - 1].creationTime).local().format('YYYY-MM-DD HH:mm:ss')}</b></p>
                                    <Divider />
                                    {
                                        feedbacks[feedbacks.length - 1].feedbackProperties.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <b>{item.displayName}:{' '}</b>
                                                    <span>{showAttributeName(item.type, item.value, item, '; ')}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        )
                        :
                        (
                            hasDone == true ?
                                <div>
                                    <p>{intl.formatMessage({ id: "feedbackTime"})}<b>{moment(feedbacks[feedbacks.length - 1].creationTime).local().format('YYYY-MM-DD HH:mm:ss')}</b></p>
                                    <Divider />
                                    {
                                        feedbacks[feedbacks.length - 1].feedbackProperties.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <b>{item.displayName}:{' '}</b>
                                                    <span>{showAttributeName(item.type, item.value, item, '; ')}</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <Form
                                    onFinish={onClickSubmit}
                                    layout="vertical"
                                >
                                    {feedbackProperties.map(item => getFeedbackItem(item))}
                                    <center>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading && loadingKey.indexOf(id) > -1}
                                        >
                                            {intl.formatMessage({ id: "submitOther"})}
                                        </Button>
                                    </center>
                                </Form>
                        )
                }
            </React.Fragment>
            {
                // <Form
                //     onFinish={onClickSubmit}
                //     layout="vertical"
                // >
                //     {feedbackProperties.map(item => getFeedbackItem(item))}
                //     <center>
                //         <Button
                //             type="primary"
                //             htmlType="submit"
                //             loading={loading && loadingKey.indexOf(id) > -1}
                //         >
                //             提交
                //         </Button>
                //     </center>
                // </Form>
            }
            {/* {
                feedbacks && Array.isArray(feedbacks) && feedbacks.length > 0 ?
                    <div>
                        <p>反馈时间：<b>{moment(feedbacks[feedbacks.length - 1].creationTime).local().format('YYYY-MM-DD HH:mm:ss')}</b></p>
                        <Divider />
                        {
                            feedbacks[feedbacks.length - 1].feedbackProperties.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <b>{item.displayName}:{' '}</b>
                                        <span>{showAttributeName(item.type, item.value, item)}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <Form
                        onFinish={onClickSubmit}
                        layout="vertical"
                    >
                        {feedbackProperties.map(item => getFeedbackItem(item))}
                        <center>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading && loadingKey.indexOf(id) > -1}
                            >
                                提交
                            </Button>
                        </center>
                    </Form>
            } */}
        </Card>
    )
}

export default GuardianFeedback;
